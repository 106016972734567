import {DATE_DISPLAY_FORMAT} from "../../constants";
import {asArray} from "../../general/helpers";
import COLORS_MAP from './colors.json'
import {isValidInternationalPhoneNumber, isValidIsraeliPhoneNumber} from "@tomeravni/easybizy-js-common/phone-numbers";
import {isNonEmptyObject, isNonEmptyString} from "@tomeravni/easybizy-js-common/common";
import {extractFullName} from "@tomeravni/easybizy-js-common/odata-entities";


const commonServicesModule = angular.module('easybizy.common.common-services');

commonServicesModule.factory('validator', function (localize) {
  var kEmailValidationRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;


  var methods = {
    email: new function () {
      this.validate = function (value) {
        if (!value) {
          return true;
        } else {
          return kEmailValidationRegex.test(value);
        }
      };
      this.error = function () {
        return localize.getLocalizedString("_InvalidEmailAddress_");
      };
    },
    url: new function () {
      this.validate = function (value) {
        if (!value) {
          return true;
        } else {
          return /^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(value);
        }
      };

      this.error = function () {
        return localize.getLocalizedString("_InvalidUrl_");
      };
    },
    date: new function () {
      this.validate = function (value) {
        if (!value) {
          return true;
        } else {
          var valueAsDate = moment(value, DATE_DISPLAY_FORMAT);
          return valueAsDate.isValid();
        }
        //                else return !/Invalid|NaN/.test(new Date(value).toString()) && confirmDateRange(value);
      };

      this.error = function () {
        return localize.getLocalizedString("_InvalidDate_");
      };
    },
    birthday: new function () {
      this.validate = function (value) {
        if (!value) {
          return true;
        }
        //                else return !/Invalid|NaN/.test(new Date(value).toString()) && new Date(value) < new Date();
        else {
          var valueAsDate = moment(value, DATE_DISPLAY_FORMAT);
          return valueAsDate.isValid() && valueAsDate.isBefore(moment());
        }
      };

      this.error = function () {
        return localize.getLocalizedString("_InvalidBirthday_");
      };
    },
    minLength: new function () {
      this.validate = function (value, ruleValue) {
        this.currentLength = $.trim(value).length;
        this.ruleValue = ruleValue;
        return this.currentLength == 0 || this.currentLength >= ruleValue;
      };

      this.error = function () {
        return localize.getLocalizedString("_LengthMustBeAtLeast_") + this.ruleValue + localize.getLocalizedString("_charactersCurrently_") + this.currentLength + ")";
      };
    },
    maxLength: new function () {
      this.validate = function (value, ruleValue) {
        this.currentLength = $.trim(value).length;
        this.ruleValue = ruleValue;
        return this.currentLength <= ruleValue;
      };

      this.error = function () {
        return localize.getLocalizedString("_LengthMustNotExceed_") + this.ruleValue + localize.getLocalizedString("_charactersCurrently_") + this.currentLength + ")";
      };
    },
    required: new function () {
      this.validate = function (value, ruleValue) {
        return !ruleValue || !!value;
      };

      this.error = function () {
        return localize.getLocalizedString("_ThisFieldIsRequired_");
      };
    },
    dateISO: new function () {
      this.validate = function (value) {
        return /^\d{4}[\/\-]\d{1,2}[\/\-]\d{1,2}$/.test(value);
      };

      this.error = function () {
        return localize.getLocalizedString("_InvalidDateFormat_");
      };
    },
    number: new function () {
      this.validate = function (value) {
        return /^-?(?:\d+|\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/.test(value);
      };

      this.error = function () {
        return localize.getLocalizedString("_NotANumber_");
      };
    },
    decimal: new function () {
      this.validate = function (value) {
        return /^[-+]?[0-9]+(\.\d{0,2}){0,1}$/.test(value);
      };

      this.error = function () {
        return localize.getLocalizedString("_NotADecimalNumber_");
      };
    },
    digits: new function () {
      this.validate = function (value) {
        if (!value) {
          return true;
        } else {
          return /^\d+$/.test(value);
        }
      };

      this.error = function () {
        return localize.getLocalizedString("_OnlyDigitsAreAllowed_");
      };
    },
    phone: new function () {
      this.validate = function (value) {
        if (!value) {
          return true;
        } else {
          // return /^[+]{0,1}[0-9]{1,4}[0-9]*$/g.test(value);
          return isValidIsraeliPhoneNumber(value) || isValidInternationalPhoneNumber(value);
        }
      }

      this.error = function () {
        return localize.getLocalizedString("_WrongPhoneNumber_");
      };
    },
    creditCard: new function () {
      this.validate = function (value) {
        if (/[^0-9 \-]+/.test(value)) {
          return false;
        }

        var nCheck = 0,
          nDigit = 0,
          bEven = false;
        value = value.replace(/\D/g, "");
        for (var n = value.length - 1; n >= 0; n--) {
          var cDigit = value.charAt(n);
          nDigit = parseInt(cDigit, 10);
          if (bEven) {
            if ((nDigit *= 2) > 9) {
              nDigit -= 9;
            }
          }
          nCheck += nDigit;
          bEven = !bEven;
        }

        return (nCheck % 10) === 0;
      };

      this.error = function () {
        return localize.getLocalizedString("_InvalidCreditCardNumber_");
      };
    }
  };

  return methods;
});

commonServicesModule.factory('printerMediator', function ($http, configuration) {
  const WRAP_IN_HTML = (content, attachToHead) => `
   <!DOCTYPE html>
<html>
<head>
  <style>
  @media print {
    body {
      -webkit-print-color-adjust: exact !important;
      min-width: 300px;
    }
  }

  </style>
  ${attachToHead || ''}
</head>
<body>
<div class="content">
  ${content}
</div>
</body>
</html>
  `;

  var printingStack = [];
  var iframe;
  var useQuickPrint;

  window.addEventListener('message', iframeDone, false);


  return {
    print: printHtmlReceipt,
    printDirectHtml: printDirectHtml

  };

  function iframeDone(messageEvent) {
    if (messageEvent.data === 'finished-loading-iframe') {
      iframe.remove();
      iframe = undefined;
      donePrinting();
    }
  }

  function donePrinting() {
    printingStack.shift();
    if (printingStack.length > 0) {
      setTimeout(printWithLocalServer.bind(this, printingStack[0]));
    }
  }

  function printHtmlReceipt(iToPrint, wrap = false, attachToHead = null) {
    let toPrint = iToPrint;
    if (wrap) {
      toPrint = WRAP_IN_HTML(iToPrint, attachToHead);
    }

    printingStack.push(toPrint);
    if (printingStack.length === 1) {
      printWithLocalServer(toPrint);
    }
  }

  function decodeReceipt(str) {
    return str.replace(/&#(\d+);/g, function (match, dec) {
      return String.fromCharCode(dec);
    });
  }

  function concretePrint(value) {
    return printDirectHtml(value);
  }

  function printWithLocalServer(value) {
    if (!angular.isDefined(useQuickPrint)) {
      useQuickPrint = configuration.get().CashRegisterSettings.UseAutoPrinting;
    }

    if (!useQuickPrint) {
      return concretePrint(value);
    }

    try {
      var extensionId = $('span#print-extension-id').attr('value');
      if (!extensionId) {
        return concretePrint(value);
      }

      // Make a simple request:
      chrome.runtime.sendMessage(extensionId, {content: value},
        function (response) {
          if (response.code == 200) {
            console.log('yes printed outside');
            donePrinting();
          } else {
            concretePrint(value);
          }
        });
    } catch (e) {
      concretePrint(value);
    }
  }

  function printDirectHtml(html) {
    try {
      const iframe = document.createElement('iframe');
      document.body.appendChild(iframe);

      console.log(iframe.contentWindow.document)
      iframe.contentWindow.document.open('text/htmlreplace');
      iframe.contentWindow.document.write(html);
      iframe.contentWindow.document.close();


      iframe.contentWindow.document.body.onload = function () {
        iframe.focus();
        iframe.contentWindow.print();
        setTimeout(() => {
          iframe.remove();
          donePrinting();
        });
      };
    } catch (e) {
      donePrinting();
    }
  }
});

commonServicesModule.factory('sensorMediator', function ($http, configuration) {
  return {
    openCustomer: openCustomer
  };

  function openCustomer(identification, fullName, dob, gender) {
    return $http({
      url: 'http://localhost:8099/api/sensors/open-customer',
      method: "POST",
      dataType: 'json',
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify({
        identification, fullName, dob, gender
      })
    }).then(function ({data}) {
      return data;
    })
  }
});

commonServicesModule.factory('profileImageResolver', function (Repository, configuration) {
  return {
    resolve
  };

  function resolve(customer) {
    if (configuration.get().CustomersSettings.PreventProfileImageResolution) {
      return Promise.resolve({success: false});
    }

    if (isNonEmptyObject(customer) && customer.CustomerId && !isNonEmptyString(customer.DefaultImagePath)) {
      return Repository.Custom('General').resolveProfilePicture(customer.CustomerId, customer.MobileFirst, extractFullName(customer));
    }

    return Promise.resolve({success: false});
  }
});


//Uncaught TypeError: Cannot read properties of undefined (reading 'contentWindow')

commonServicesModule.factory('iconsResolver', function () {
  var icons = {
    "Blank": "glyphicon glyphicon-book",
    "Birthday": "glyphicon glyphicon-glass",
    "BestCustomer": "glyphicon glyphicon-user",
    "ProductOfTheMonth": "glyphicon glyphicon-shopping-cart",
    "LongTimeNoSee": "glyphicon glyphicon-phone-alt",
    "MeetingRemainder": "glyphicon glyphicon-bullhorn",
    "WeeklyOffers": "glyphicon glyphicon-calendar",
    "ImageFromApp": "glyphicon glyphicon-camera"
  };


  return {
    getIcon: getIcon
  };

  function getIcon(name) {
    var toReturn = null;
    if (icons.hasOwnProperty(name)) {
      toReturn = icons[name];
    }

    return toReturn;
  }
});


commonServicesModule.factory('promotionsMediumResolver', function (localize, configuration, $q) {
  const adaptTemplateName = (name) => localize.getLocalizedString(`_${name}_`);

  const presets = {
    _Facebook_: {EntityType: 'PromotionTemplate', TemplateName: 'Facebook', Icon: 'icon icon-facebook', Order: 0},
    _Email_: {
      EntityType: 'PromotionTemplate',
      TemplateName: 'Mail',
      Icon: 'icon icon-email-mail-streamline',
      Order: 2
    },
    _Sms_: {EntityType: 'PromotionTemplate', TemplateName: 'Sms', Icon: 'icon icon-sms', Order: 3}
  };

  angular.forEach(presets, function (preset) {
    preset.TemplateLabel = adaptTemplateName(preset.TemplateName);
  });

  let deferred = $q.defer();
  configuration.isValidWhatsappAccount().then((isValid) => {
    if (isValid) {
      presets['_Whatsapp_'] = {
        EntityType: 'PromotionTemplate',
        TemplateName: 'Whatsapp',
        TemplateLabel: adaptTemplateName('Whatsapp'),
        Icon: 'icon icon-whatsapp',
        Order: 1
      };
    }

    deferred.resolve(isValid)
  })

  return {
    getPresets: (...args) => deferred.promise.then(() => {
      return getPresets(presets, ...args)
    })
  };
});


commonServicesModule.factory('promotionsPresetResolver', function (localize) {
  const MEETING_REMINDER_PRESET = 'MeetingRemainder';

  var presets = {
    _Blank_: {
      EntityType: 'PromotionTemplate', TemplateName: 'Blank',
      Icon: 'glyphicon glyphicon-book', Filters: 'All', IsServiceEngagementType: false,
      Order: 3
    },
    _Birthday_: {
      EntityType: 'PromotionTemplate', TemplateName: 'Birthday',
      Icon: 'icon icon-birthday2', Filters: 'CelebratingBirthday', IsServiceEngagementType: false, Order: 1
    },
    _BestCustomer_: {
      EntityType: 'PromotionTemplate',
      TemplateName: 'BestCustomer',
      Icon: 'icon icon-crown',
      Filters: 'LastVisit, LastCampaign, VisitsInPeriod, AverageInvoice',
      IsServiceEngagementType: false
    },
    //        _ProductOfTheMonth_: { EntityType: 'PromotionTemplate', TemplateName: 'ProductOfTheMonth',
    //            Icon: 'icon icon-trophy', Filters: 'Product,Service,LastCampaign', IsServiceEngagementType: false },
    _LongTimeNoSee_: {
      EntityType: 'PromotionTemplate',
      TemplateName: 'LongTimeNoSee',
      Icon: 'glyphicon glyphicon-phone-alt',
      Filters: 'LastVisit, LastCampaign',
      IsServiceEngagementType: false
    },
    _MeetingRemainder_: {
      EntityType: 'PromotionTemplate', TemplateName: MEETING_REMINDER_PRESET,
      Icon: 'icon icon-calendar', Filters: 'Meeting', IsServiceEngagementType: true, Order: 2,
      CustomFields: ['MeetingDate', 'MeetingDateOnly', 'MeetingTime', 'StartTime', 'TodayOrTomorrow', 'EmployeeName', 'Location', 'ServiceName', 'MeetingApprovalLink', 'NextMeetings', 'NextFirstMeetingsPerDay', 'NextMeetingsAndServices', 'FormIfNeeded']
    }
    //        _WeeklyOffers_: { EntityType: 'PromotionTemplate', TemplateName: 'WeeklyOffers',
    //            Icon: 'glyphicon glyphicon-flag', Filters: 'Product, Service, LastCampaign', IsServiceEngagementType:
    // false }, _ImageFromApp_: { EntityType: 'PromotionTemplate', TemplateName: 'ImageFromApp', Icon: 'glyphicon
    // glyphicon-camera', Filters: '', IsServiceEngagementType: false }
  };

  angular.forEach(presets, function (preset) {
    preset.TemplateLabel = localize.getLocalizedString('_' + preset.TemplateName + '_');
  });

  return {
    getPresets: getPresets.bind(null, presets),
    getPreset: getPreset,
    getPresetByTemplateName: getPresetByTemplateName,
    meetingReminderPresetName: MEETING_REMINDER_PRESET,
    adaptMediumType: (mediumType) => {
      if (mediumType?.toLowerCase() === 'whatsapp') {
        return 'Sms';
      }

      return mediumType;

    }
  };

  // function getPresets(take, skip) {
  //   var numOfPresets = Object.keys(presets).length;
  //   console.log(presets)
  //   return Enumerable.From(presets)
  //     .OrderBy(function (x) {
  //       return angular.isDefined(x.Value.Order) ? x.Value.Order : numOfPresets;
  //     })
  //     .Take(angular.isDefined(take) ? take : Infinity)
  //     .Skip(angular.isDefined(skip) ? skip : 0)
  //     .Select(function (x) {
  //       return $.extend({}, x.Value);
  //     })
  //     .ToArray();
  // }

  function getPreset(presetName) {
    return presets[presetName.indexOf('_') === 0 ? presetName : `_${presetName}_`];
  }

  function getPresetByTemplateName(templateName) {
    return asArray(presets).filter(x => x.TemplateName === templateName)[0];
    //
    // return Enumerable.From(presets)
    //   .Where(function (x) {
    //     return x.Value.TemplateName === templateName;
    //   })
    //   .Select(function (x) {
    //     return x.Value;
    //   }).First();

  }
});
commonServicesModule.factory('entityImageResolver', function () {
  var thumbnailPermutation = '?size=thumbnail';

  return {
    resolve: resolve,
    toThumbnail: toThumbnail
  };

  function resolve(entity, thumbnail) {
    var result = null;
    if (entity.DefaultImagePath) {
      result = (entity.DefaultImagePath && entity.DefaultImagePath.indexOf('http') !== 0)
        ? `${window.filesURI}${entity.DefaultImagePath}` : entity.DefaultImagePath;
    } else if (entity.Images && entity.Images.length > 0) {
      var index = entity.DefaultImage ? entity.Images.indexOfById({ImageId: entity.DefaultImage}, "ImageId") : 0;
      index = index >= 0 ? index : 0;
      result = window.filesURI + entity.Images[index].FilePath;
    } else if (entity.Image && entity.Image.FilePath) {
      result = entity.Image.ImageId ? window.filesURI + entity.Image.FilePath : window.filesURI + entity.Image;
    }

    if (thumbnail && result) {
      result += thumbnailPermutation;
    }

    if (result) {
      // TOMER REMOVED> 03/01/2023
      // result = result.replace(/ /gi, '');
    }

    return result;
  }

  function toThumbnail(url) {
    return url + thumbnailPermutation;
  }
});

commonServicesModule.factory('priceQuoteContextResolver', [
  'localize', function (localize) {
    return {
      getItems: getItems,
      get: get,
      getIntermediateCartId: getIntermediateCartId,
      set: set,
      hasPriceQuoteInSession: hasPriceQuoteInSession,
      duplicate: duplicate,
      clear: clear,
      getCustomer: getCustomer,
      getTotal: getTotal
    };

    function getItems() {
      var itemsToReturn = null;
      if ($.sessionStorage.isSet('priceQuote')) {
        itemsToReturn = $.sessionStorage.get('priceQuote').PriceQuote;
        itemsToReturn = itemsToReturn.ClientPriceQuoteItems;
      }

      return itemsToReturn;
    }

    function get() {
      var metadataToReturn = undefined;
      if ($.sessionStorage.isSet('priceQuote')) {
        metadataToReturn = $.sessionStorage.get('priceQuote').PriceQuote;
        metadataToReturn = $.extend({}, metadataToReturn);
        delete metadataToReturn.ClientPriceQuoteItems;
      }

      return metadataToReturn;
    }

    function getIntermediateCartId() {
      var id = undefined;
      if ($.sessionStorage.isSet('priceQuote')) {
        id = $.sessionStorage.get('priceQuote').PriceQuote.IntermediateCartId;
      }

      return id;
    }

    function set(priceQuote) {
      // angular.forEach(priceQuote.PriceQuote.ClientPriceQuoteItems, function (productOrService) {
      //   productOrService.ConcreteType = productOrService.ConcreteType == 0 ? "ProductMetadata" : "ServiceMetadata";
      // });

      $.sessionStorage.set('priceQuote', priceQuote);
      $.sessionStorage.remove('productsAndServices');

      return priceQuote;
    }

    function hasPriceQuoteInSession() {
      return $.sessionStorage.isSet('priceQuote');
    }

    function getCustomer() {
      var customerToReturn = null;
      if ($.sessionStorage.isSet('priceQuote')) {
        customerToReturn = $.sessionStorage.get('priceQuote').PriceQuote;
        customerToReturn = customerToReturn.Customer;
      }

      return customerToReturn;
    }

    function getTotal() {
      var totalToReturn = null;
      if ($.sessionStorage.isSet('priceQuote')) {
        var priceQuote = $.sessionStorage.get('priceQuote').PriceQuote;
        totalToReturn = {};
        totalToReturn.TotalAmount = priceQuote.TotalAmount;
        totalToReturn.TotalAmountBeforeDiscount = priceQuote.TotalAmountBeforeDiscount;
      }

      return totalToReturn;
    }

    function duplicate(priceQuote) {
      var copiedPriceQuote = $.extend({}, priceQuote);
      delete copiedPriceQuote.PriceQuote.HtmlContent;
      delete copiedPriceQuote.PriceQuote.HtmlFilePath;
      delete copiedPriceQuote.PriceQuote.MailCc;
      delete copiedPriceQuote.PriceQuote.MailTo;
      delete copiedPriceQuote.PriceQuote.OriginalPriceQuotePath;
      delete copiedPriceQuote.PriceQuote.PriceQuoteId;
      delete copiedPriceQuote.PriceQuote.IntermediateCartId;
      delete copiedPriceQuote.PriceQuote.ExpirationDate;
      delete copiedPriceQuote.PriceQuote.For;
      delete copiedPriceQuote.PriceQuote.Customer;
      delete copiedPriceQuote.PriceQuote.CustomerId;
      delete copiedPriceQuote.PriceQuote.For;

      for (var item in copiedPriceQuote.PriceQuote.ClientPriceQuoteItems) {
        delete item.IntermediateItemId;
      }
      $.sessionStorage.set('priceQuote', copiedPriceQuote);
      $.sessionStorage.remove('productsAndServices');

      return copiedPriceQuote;
    }

    function clear() {
      $.sessionStorage.remove('priceQuote');
    }
  }]);

commonServicesModule.factory('modalCreator', function () {
  return {
    embedInModel: embedInModel
  };

  function embedInModel(title, content, topLevelClass) {
    return '<div class="modal-dialog ' + (topLevelClass ? topLevelClass : "") + '">\
                    <div class="modal-content relative-container">\
                        <div class="modal-view-header">\
                            <div class="relative-container">\
                                <div class="absolute-container">\
                                    <div class="centered-div-container">\
                                        <div class="centered-div-content">\
                                            <div class="header-text">' + title + '</div>\
                                        </div>\
                                    </div>\
                                </div>\
                                <div class="absolute-container">\
                                    <div class="centered-div-container">\
                                    <div class="centered-div-content right-text-aligned">\
                                        <div class="modal-close-dialog" ng-click="cancel()">\
                                            <span class="modal-close-dialog-span icon icon-flat-close"></span>\
                                        </div>\
                                    </div>\
                                    </div>\
                                </div>\
                            </div>\
                        </div>\
                    <div class="relative-container modal-body">' + content + '</div>\
                    </div>\
                </div>';
  }
});

commonServicesModule.factory('inlineForms', function ($rootScope, $compile, $timeout, localize, $q) {
  var defaultFieldsForNewCustomer = [
    '_FirstName_', '_LastName_', '_Phone_',
    '_SecondPhone_', '_Email_', '_DateOfBirth_'];

  var templateForNewCustomer = '\
    <div class="main" style="float: left;">\
      <div class="header">\
        <div class="bold"><span data-i18n="_AddNewCustomer_"></span><span>:</span></div>\
      </div>\
      <table>\
        <tr ng-repeat="field in fields">\
          <td>{{field}}</td>\
          <td class="form-input-field"><input type="text" /></td>\
        </tr>\
      </table>\
    </div>\
  ';

  return {
    newCustomerForm: newCustomerForm
  };

  function newCustomerForm() {
    var deferred = $q.defer();
    var tempScope = $rootScope.$new();
    tempScope.fields = defaultFieldsForNewCustomer.map(function (name) {
      return localize.getLocalizedString(name);
    });
    var template =
      '<div style="position: absolute; visibility: hidden;">' + templateForNewCustomer + '</div>';
    var compiledContent = $compile(template)(tempScope);
    $('body').append(compiledContent);
    $timeout(function () {
      var resolvedTemplate = '<html><head>' + getTemplateStyle() + '</head><body>' +
        compiledContent.first().html() + '</body></html>';
      compiledContent.remove();
      deferred.resolve(resolvedTemplate);
    });

    return deferred.promise;
  }

  function getTemplateStyle() {
    return '\
    <style> \
      * { direction: rtl; text-align: right; } \
      .bold { font-weight: bold; } \
      .header div { display: inline-block; text-decoration: underline; } \
      .timeline-item-top-purchases { margin: 0.5em 0; border-bottom: 1px dashed black; } \
      .timeline-visit-item-detail-header { font-size: 0.9em; margin-top: 0.6em; text-decoration: underline; } \
      .timeline-ingredient { font-size: 0.8em; } \
      .form-input-field input { width: 150px; font-size: 1.4em; border: 1x solid black; } \
      tr td:first-of-type { padding-left: 10px; } \
    </style> \
    '
  }
});

commonServicesModule.factory('colorsService', function (localize) {
  /****** SEE COLORS AT colors.json ******/
  var colors = {};

  var colorsArray = colors.defaultColor = [
    'gun-powder',
    'dove-gray',
    'pale-oyster',
    'antique-brass',
    'goblin',
    'rio-grande',
    'olive-drab',
    'persian-green',
    'torea-bay',
    'east-side',
    'razzmatazz',
    'cinnabar',
    'tangerine',
    'abbey',
    'acadia',
    'acapulco',
    'affair',
    'akaroa',
    'alizarin-crimson',
    'allports',
    'almond-frost',
    'alpine',
    'aluminium',
    'amaranth',
    'amazon',
    'amber',
    'americano',
    'amethyst',
    'amethyst-smoke',
    'amulet',
    'antique-brass',
    'antique-bronze',
    'anzac',
    'apache',
    'apple',
    'apple-blossom',
    'apricot',
    'aqua-deep',
    'aqua-forest',
    'aquamarine-blue',
    'arapawa',
    'armadillo',
    'arrowtown',
    'ash',
    'asparagus',
    'asphalt',
    'astral',
    'astronaut-blue',
    'atlantis',
    'atoll',
    'atomic-tangerine',
    'au-chico',
    'aubergine',
    'avocado',
    'axolotl',
    'aztec',
    'azure-radiance',
    'bahama-blue',
    'bahia',
    'bali-hai',
    'baltic-sea',
    'bamboo',
    'bandicoot',
    'barberry',
    'barley-corn',
    'pacific-blue', 'aero-blue', 'alabaster', 'albescent-white', 'algae-green', 'alice-blue', 'almond', 'alto', 'amour', 'anakiwa', 'apple-green', 'apricot-peach', 'apricot-white', 'aqua-haze', 'aqua-island', 'aqua-spring', 'aqua-squeeze', 'astra', 'astronaut', 'athens-gray', 'aths-special', 'australian-mint', 'azalea', 'baby-blue', 'baja-white', 'banana-mania'
  ];

  colors.valuesAsKeyValue = [];
  colors.valuesByKey = {};
  colors.valuesAsKeyValue.push({name: null, value: ""});
  //colors.valuesAsKeyValue.push({ name: localize.getLocalizedString("_NoColor_"), value: "" }); we must use value and not name..

  angular.forEach(colors.defaultColor, function (colorClass) {
    colors.valuesAsKeyValue.push({name: toTitleCase(colorClass), value: 'back-color-' + colorClass});
    colors.valuesByKey[toTitleCase(colorClass)] = 'back-color-' + colorClass;
  });

  delete colors.defaultColor;

  function toTitleCase(str) {
    return str.replace('-', ' ').replace(/(?:^|\s)\w/g, function (match) {
      return match.toUpperCase();
    });
  }

  return {
    getDefaultColors: getDefaultColors,
    getColorByName: getColorByName,
    getColorByIndex: getColorByIndex,
    getHexColorsByName: getHexColorsByName
  };

  function getDefaultColors() {
    return colors.valuesAsKeyValue;
  }

  function getColorByName(name) {
    return colors.valuesByKey[name];
  }

  function getColorByIndex(index) {
    if (index >= colorsArray.length) {
      index = Math.floor(index / colorsArray.length - 1);
    }

    return getColorByName(colorsArray[index].replace('-', ' ').toTitleCase());
  }

  function getHexColorsByName(name) {
    return COLORS_MAP[name];
  }
});

commonServicesModule.factory('iCalService', function (localize) {
  return {
    rRuleToString: rRuleToString
  };

  function rRuleToString(rrule) {
    var splitted = rrule.replace('RRULE:', '').split(';');

    var rulesKeyValue = {};
    splitted.forEach(function (subRule) {
      var splittedRule = subRule.split('=');
      rulesKeyValue[splittedRule[0]] = splittedRule[1];
    });

    //                    var finalString = "";
    var finalString = localize.getLocalizedString("_Repeats_");
    if (!rulesKeyValue.hasOwnProperty('INTERVAL')) {
      var repeatType = rulesKeyValue['FREQ'].toLowerCase();
      repeatType = repeatType[0].toUpperCase() + repeatType.substring(1);
      finalString += localize.getLocalizedString("_" + repeatType + "_");
    } else {
      var interval = parseInt(rulesKeyValue['INTERVAL']);
      finalString += localize.getLocalizedString("_Every_") + " " + interval + " " +
        localize.getLocalizedString(translateComplexTimeToSimple(rulesKeyValue['FREQ']));
    }

    // WEEKLY
    if (rulesKeyValue['FREQ'] == 'WEEKLY' && rulesKeyValue.hasOwnProperty('BYDAY')) {
      finalString += " " + localize.getLocalizedString("_OnDays_") + " ";
      var splittedDays = rulesKeyValue['BYDAY'].split(",");
      for (var dayIndex = 0; dayIndex < splittedDays.length; dayIndex++) {
        finalString += translate2LettersToDate(splittedDays[dayIndex]).format("dddd");
        if (dayIndex < splittedDays.length - 1) {
          finalString += ", ";
        }
      }
    } else

      // MONTHLY
    if (rulesKeyValue['FREQ'] == 'MONTHLY') {
      if (rulesKeyValue.hasOwnProperty('BYMONTHDAY')) {
        finalString += " " + localize.getLocalizedString("_DateOn_") +
          moment().set('date', rulesKeyValue['BYMONTHDAY']).format("Do") + " " +
          localize.getLocalizedString("_OfTheMonth_");
      } else if (rulesKeyValue.hasOwnProperty('BYDAY')) {
        var indexOfDayOfMonth = parseInt(rulesKeyValue['BYDAY']);
        var dayInMonth = rulesKeyValue['BYDAY'].replace(indexOfDayOfMonth + '', '');
        if (!localize.isRightToLeft()) {
          finalString += " " + localize.getLocalizedString("_DateOn_") +
            moment().set('date', indexOfDayOfMonth).format("Do") + " " +
            translate2LettersToDate(dayInMonth).format("dddd");
        } else {
          var stringDayIndexHebrew = moment().day("Sunday").day(indexOfDayOfMonth - 1).format("dddd");
          finalString += " " + localize.getLocalizedString("_OnDayOfTheMonth_",
            translate2LettersToDate(dayInMonth).format("dddd"), stringDayIndexHebrew);

        }

      }
    }

    if (rulesKeyValue.hasOwnProperty('COUNT')) {
      finalString += " " + localize.getLocalizedString("_ForXTimes_", rulesKeyValue['COUNT']);
    } else if (rulesKeyValue.hasOwnProperty('UNTIL')) {
      finalString += ", " + localize.getLocalizedString("_Until_",
        moment(rulesKeyValue['UNTIL'], 'YYYYMMDD').format(DATE_DISPLAY_FORMAT));
    }

    return finalString;
  }

  function translateComplexTimeToSimple(time) {
    var toReturn = "_Days_";
    if (time == "WEEKLY") {
      toReturn = "_Weeks_";
    } else if (time == "MONTHLY") {
      toReturn = "_Months_";
    } else if (time == "YEARLY") {
      toReturn = "_Years_";
    }

    return toReturn;
  }

  function translate2LettersToDate(dayIn2Letters) {
    return moment(moment().lang("en").day(dayIn2Letters).toString());
  }

});


commonServicesModule.factory('rightToLeftSwitcher', [
  'localize', function (localize) {
    return {
      switchRightToLeft: switchRightToLeft
    };

    function switchRightToLeft(value) {
      var toReturn = value;
      if (localize.isRightToLeft()) {
        if (value.indexOf("left") !== -1) {
          toReturn = value.replace('left', 'right');
        } else if (value.indexOf("right") !== -1) {
          toReturn = value.replace('right', 'left');
        }
      }

      return toReturn;
    }
  }]);

commonServicesModule.service('measurements', function () {
  this.emToPx = function (value, context) {
    return value * getElementFontSize(context);
  };

  function getElementFontSize(context) {
    // Returns a number
    return parseFloat(
      // of the computed font-size, so in px
      getComputedStyle(
        // for the given context
        context
        // or the root <html> element
        || document.documentElement
      )
        .fontSize
    );
  }
});


commonServicesModule.service('escapeKeyService', function () {
  this.addEscListener = (delegate) => {
    const eventListener = function (evt) {
      let isEscape;
      if ('key' in evt) {
        isEscape = (evt.key === "Escape" || evt.key === "Esc");
      } else {
        isEscape = (evt.keyCode === 27);
      }

      if (isEscape) {
        delegate(evt);
      }
    };

    window.addEventListener('keydown', eventListener, true);
    return () => window.removeEventListener('keydown', eventListener, true);
  }
});

commonServicesModule.service('whatsapp', function () {
  this.open = (number, message) => {
    const link = `https://api.whatsapp.com/send?phone=972${number.indexOf('0') === 0 ? number.substring(1) : number}&text=${message}`;
    let openedWindow = window.open(link, '_blank');
    if (openedWindow) {
      openedWindow.focus();
    }
  }
});


commonServicesModule.factory('analytics', function (configuration, $transitions) {
  ga('create', 'UA-46358046-1', 'auto');  // Replace with your property ID.
  ga('set', 'user-type', '1');
  const configurations = configuration.get();
  if (configurations) {
    var dimensionValue = configurations.BusinessDetails.LicensedDealerNumber.Value + ' - ' + configurations.BusinessDetails.BusinessName.Value;
    ga('set', 'dimension1', dimensionValue);
  }
  ga('send', 'pageview');


  $transitions.onEnter({}, trackFakePageView);

  return {
    trackEvent: trackEvent

  };

  function trackFakePageView(trans) {
    ga('send', 'pageview', window.location.href, {location: window.location.href});
    ga('set', 'Event-Page', trans.targetState().name());

  }

  function trackEvent(category, action, label, value) {
    setTimeout(function () {
      ga('send', 'event', category, action, label, value);
    });
  }
});


function getPresets(presets, take, skip) {
  return asArray(presets).slice(skip).sort((a, b) => (a.Order || Number.MAX_SAFE_INTEGER) - (b.Order || Number.MAX_SAFE_INTEGER)).slice(0, take);
}
