import './cash.register.checkout.less';
import {CREDIT_CARD_EXPIRATION, DATE_DISPLAY_FORMAT, ODATA_DATE_ONLY_FORMAT, UNIQUE_DATE_TIME} from "../../constants";
import './large-invoice-generator/large.invoice.generator.directive'
import './payments-helper/cashout.payments.helper.directive'
import './digital-invoice/digital.invoice.short.directive'
import moment from "moment";
import {
  cleanObj,
  duplicate,
  getSafely,
  isNonEmptyArray,
  isNonEmptyString, isNumber,
  setIfHasValue, valueOrDefault
} from "@tomeravni/easybizy-js-common/common";
import {EasybizyPaymentsTypeMap, InvoiceItemType} from "@tomeravni/easybizy-js-common/constants";

angular.module('easybizy.easybizyModalViewsModel').controller('CashRegisterCheckoutModalController',
  function (
    $scope, $modalInstance, parameters, $timeout, $http, localize, Repository, saveAction,
    configuration, printerMediator, creditCardReaderFactory,
    employees, $state, confirmService, contextManager, $q, reviewsService,
    pci, manualCreditChargeService, focusManager, $rootScope
  ) {

    const customer = parameters.invoice.Customer;
    const unrelatedMeetings = getSafely(['invoice', 'unrelatedMeetings'], parameters);
    const customCreditCardToken = getSafely(['customCustomerCreditToken', 0], parameters);
    if (isNonEmptyArray(unrelatedMeetings)) {
      $timeout(() => {
        confirmService.confirm(localize.getLocalizedString('_UnrelatedMeetingsTitle_'),
          localize.getLocalizedString('_UnrelatedMeetingsDescription_', unrelatedMeetings.length), () => {
            parameters.invoice.MeetingsToSetAsPaid = unrelatedMeetings;
          }, () => {
          }, localize.getLocalizedString('_MarkAsDone_'), localize.getLocalizedString('_DontChangeStatus_'));
      }, 500);
    }

    const {invoiceToCreateViaPci} = parameters;

    delete (parameters.invoice || {}).unrelatedMeetings;
    $(document).destroyPos();
    var configurations = configuration.get();
    $scope.isUsingCreditCard = !configurations.CashRegisterSettings.PreventCreditCard;
    $scope.EnableExternalPayments = configurations.CashRegisterSettings.EnableExternalPayments;
    $scope.DefaultExternalPaymentName = configurations.CashRegisterSettings.DefaultExternalPaymentName;
    $scope.GoBackToCalendarAfterCheckout = configurations.CashRegisterSettings.GoBackToCalendarAfterCheckout;
    $scope.largeInvoiceMode = configurations.InvoiceSettings.TypeOfInvoice.Value === 'mail';
    $scope.isNewModeCashRegister = configurations.CashRegisterSettings.UseNewPosModel;
    var remindAboutPaymentsAboveAmount = configurations.CashRegisterSettings.RemindAboutPaymentsAboveAmount;
    $scope.remindAboutPayments = parseInt((remindAboutPaymentsAboveAmount && remindAboutPaymentsAboveAmount.Value) || 5000);
    const invoiceHasComposedTaxInvoices = parameters.invoice.TaxInvoiceIdAndAmounts && Object.keys(parameters.invoice.TaxInvoiceIdAndAmounts).length > 0;
    $scope.allowDebt = !configurations.InvoiceSettings.PreventDebts && !invoiceHasComposedTaxInvoices;
    $scope.allowTaxInvoice = !invoiceHasComposedTaxInvoices && !configurations.GeneralSettings.ExemptDealer;

    var templateForRemarks = configurations.CashRegisterSettings.InvoiceRemarksTemplate;

    $scope.paymentTotal = {};
    $scope.paymentTotal.remarks = templateForRemarks ? templateForRemarks.Value : "";
    $scope.paymentMethods = [
      {name: localize.getLocalizedString("_Cash_"), value: 'cash'},
      {name: localize.getLocalizedString("_Cheque_"), value: 'cheque'}
    ];
    if ($scope.EnableExternalPayments) {
      $scope.paymentMethods.push({name: localize.getLocalizedString("_External_"), value: 'external'});
    }
    $scope.selectedPaymentMethodWrapper = {};

    /***************************************** Cheque Quantity *****************************************/
    $scope.chequeQuantityEditorWrapper = {quantity: 1};
    /***************************************** Employees and State Employee! *****************************************/
    $scope.employees = [];
    $scope.selectedEmployee = {};
    employees.resolve($scope.employees, $scope.selectedEmployee);

    $scope.currentCurrency = configurations.CashRegisterSettings.Currency.Value;
    $scope.allowCreditToken = configurations.CashRegisterSettings.AllowCreditToken;
    $scope.includeAddress = configurations.InvoiceSettings.IncludeCustomerAddressOnInvoice;
    $scope.forceIdentifyNumber = parameters.invoice.TotalAmount > 5000;
    $scope.includeIdentifyNumber = configurations.InvoiceSettings.IncludeCustomerIdNumberOnInvoice || $scope.forceIdentifyNumber;
    $scope.useInternalNotes = configurations.InvoiceSettings.UseInternalNotesField;

    $scope.paymentTotal.balance = parameters.invoice.TotalAmount;
    $scope.invoice = parameters.invoice;
    $scope.clearItemsFromCashRegister = parameters.clearItems;
    $scope.paymentTotal.currentPayment = "" + $scope.paymentTotal.balance;
    $scope.autoOpenLostPayments = parameters.forceLostPayments;

    $scope.paymentTotal.invoiceName = $scope.invoice.InvoiceRefersTo;
    $scope.paymentTotal.invoiceAddress = $scope.invoice.Address;
    $scope.paymentTotal.invoiceIdentifyNumber = $scope.invoice.IdentifyNumber;
    $scope.paymentTotal.payments = [];

    $scope.cashPaymentQuantity = [200, 100, 50, 20, 5, 1];
    $scope.currency = "".toCurrencySymbol(null, $scope.currentCurrency);
    $scope.recievedCash = 0;
    $scope.finishedSearchForMissingPayments = false;

    var isFirstPaymentButtonClick = true;
    /* Keyboard part */
    var currencySymbol = "".toCurrencySymbol(window.k_Currency);
    $scope.numberKeyboardButtons = [
      '7', '8', '9', '10'.toMoney(), '4', '5', '6', '20'.toMoney(),
      '1', '2', '3', '50'.toMoney(), 'C', '0', '.'
    ];

    $scope.addRecievedCash = function (quantity) {
      if ($scope.paymentTotal.balance > 0) {
        $scope.recievedCash += quantity;
      }
    };

    $scope.addAmount = function (amount) {
      var parsedAmount = parseInt(amount);
      if (isNaN(parsedAmount) || parsedAmount > 9) {
        if (amount == 'C') {
          $scope.paymentTotal.currentPayment = 0;
        } else if (amount == '<') {
          var paymentAsString = "" + $scope.paymentTotal.currentPayment;
          $scope.paymentTotal.currentPayment = paymentAsString.length > 1
            ? paymentAsString.substring(0, paymentAsString.length - 1)
            : 0;
        } else if (amount == '.') {
          var paymentAsString = "" + $scope.paymentTotal.currentPayment;
          if (paymentAsString.indexOf('.') == -1) {
            $scope.paymentTotal.currentPayment = paymentAsString + ".";
          }
        } else {
          // a custom number
          amount = parseInt(amount.replace(currencySymbol, ""));
          var currentAsNumber = 0;
          if (isFirstPaymentButtonClick) {
            isFirstPaymentButtonClick = false;
          } else {
            currentAsNumber = parseFloat($scope.paymentTotal.currentPayment);
          }

          $scope.paymentTotal.currentPayment = (currentAsNumber + amount);
          if ($scope.paymentTotal.currentPayment.countDecimals() >= 2) {
            $scope.paymentTotal.currentPayment = $scope.paymentTotal.currentPayment.toFixed(2);
          } else {
            $scope.paymentTotal.currentPayment += "";
          }

        }
      } else {
        var paymentAsString = "0";
        if (isFirstPaymentButtonClick) {
          isFirstPaymentButtonClick = false;
        } else {
          paymentAsString = "" + $scope.paymentTotal.currentPayment;
        }

        var tempResult = parseFloat(paymentAsString + amount);
        if (tempResult.countDecimals() > 2) {
          tempResult = parseFloat(paymentAsString);
        }

        if ($scope.paymentTotal.balance * 1000 < tempResult) {
          tempResult = parseFloat(paymentAsString);
        }

        $scope.paymentTotal.currentPayment = tempResult;
      }
    };

    $scope.clearRecievedCash = function () {
      $scope.recievedCash = 0;
    };

    $scope.change = 0;
    $scope.removePayment = function (payment) {
      $scope.paymentTotal.payments.remove(payment);
      var paymentValue = parseFloat(payment.amount);
      var balanceValue = parseFloat($scope.paymentTotal.balance);
      var currentPayment = parseFloat($scope.paymentTotal.currentPayment);
      $scope.paymentTotal.balance = (balanceValue + paymentValue).toFixed(2);
      $scope.paymentTotal.currentPayment = (currentPayment + paymentValue).toFixed(2);
      if (payment.originalPayment) { // missing payment.
        $scope.missingPayments.unshift(payment.originalPayment);
      }

      calculateChange();
      if ($scope.paymentTotal.payments.length > 0) {
        preventRefresh(true);
      }
    };


    $scope.addChequeDelegateWrapper = {};

    $scope.acceptPayment = function (customPayment) {
      if ($scope.paymentTotal.currentPayment === 0) {
        return;
      }

      var cashIndex = $scope.paymentTotal.payments.indexOfById({type: 'cash'}, 'type');
      var requestedPayment = parseFloat($scope.paymentTotal.currentPayment);
      const paymentAmount = $scope.paymentTotal.balance >= 0 ? Math.min($scope.paymentTotal.balance, requestedPayment) :
        Math.max($scope.paymentTotal.balance, requestedPayment);
      if (!customPayment && $scope.selectedPaymentMethodWrapper.value.value === 'cash' && cashIndex !== -1) {
        // if already contains cash add to its amount.
        $scope.paymentTotal.payments[cashIndex].amount = parseFloat($scope.paymentTotal.payments[cashIndex].amount) + paymentAmount;
      } else {
        if (!customPayment && $scope.selectedPaymentMethodWrapper.value.value === 'cheque') {
          $scope.addChequeDelegateWrapper.acceptPayment({amount: paymentAmount});
        } else if (!customPayment && $scope.selectedPaymentMethodWrapper.value.value === 'external') {
          var validationFields = $scope.validateExternalPaymentWrapper.validate();
          if (validationFields.length !== 0) { // is valid form?
            var wrongFieldName = externalPaymentFields.filter(x => validationFields[0].hasOwnProperty(x.fieldName))[0];
            var localizedError = localize.getLocalizedString("_FieldIsIncorrect_", wrongFieldName.placeholder, validationFields[0][wrongFieldName.fieldName]);
            toastr.error(localizedError);
            return;
          }

          $scope.paymentTotal.payments.push({
            type: $scope.selectedPaymentMethodWrapper.value.value,
            amount: paymentAmount,
            change: requestedPayment - paymentAmount,
            AuthNumber: $scope.externalPaymentModel.AuthNumber,
            ExternalName: $scope.externalPaymentModel.ExternalName
          });

        } else {
          var newPayment = {
            type: (customPayment && customPayment.type) || $scope.selectedPaymentMethodWrapper.value.value,
            amount: paymentAmount,
            change: requestedPayment - paymentAmount
          };

          if (customPayment) {
            newPayment.originalPayment = customPayment;
          }

          $scope.paymentTotal.payments.push(newPayment);
        }
      }

      $scope.paymentTotal.balance = parseFloat($scope.paymentTotal.balance - paymentAmount).toFixed(2);
      $scope.paymentTotal.currentPayment = $scope.paymentTotal.balance;
      isFirstPaymentButtonClick = true;
      calculateChange();
      preventRefresh();
    };

    function calculateChange() {
      $scope.change = $scope.paymentTotal.payments.reduce((acc, curr) => acc + curr.change, 0).toFixed(2);
    }

    $scope.isCreatingInvoiceWrapper = {inProgress: false};
    $scope.isSendingInvoice = {inProgress: false};

    $scope.save = function () {
      if ($scope.isCreatingInvoiceWrapper.inProgress) {
        return;
      }


      if (parseFloat($scope.paymentTotal.balance) === 0) {
        $scope.isCreatingInvoiceWrapper.inProgress = true;
        $scope.invoice.Payments = $scope.paymentTotal.payments;
        $scope.invoice.creditPaymentsIds = $scope.isUsingCreditCard ? $scope.paymentTotal.credit_paymentIds : [];
        createInvoice();
      } else {
        toastr.error(localize.getLocalizedString("_UnfinishedPayments_"));
      }
    };

    $scope.cancel = function () {
      closeDialog(getCustomerFromInvoice());
      pci.saveManualCardDetailsInMemory();
    };

    focusManager.preventEscape($scope);
    $scope.invoiceByMailMode = false;
    $scope.doneSendingInvoice = closeDialog;
    $scope.$on('$destroy', function () {
      if ($scope.isNewModeCashRegister) {
        $(document).pos();
      }

      $(document).off('keyup.numbersKeyPad keydown.numbersKeyPad');
      $(window).off('beforeunload.cashRegisterCheckout');
    });

    const calculateFirstPayment = (numOfPayments) => parseFloat($scope.paymentTotal.currentPayment) - ($scope.paymentTotal.credit_otherPayments * (numOfPayments - 1));


    $scope.numOfPaymentsChanged = (newVal) => {
      delete $scope.paymentTotal.credit_firstPayment;
      delete $scope.paymentTotal.credit_otherPayments;
      if (newVal > 1) {
        const currentPayment = parseFloat($scope.paymentTotal.currentPayment);
        $scope.paymentTotal.credit_otherPayments = Math.floor(currentPayment / newVal);
        $scope.paymentTotal.credit_firstPayment = calculateFirstPayment(newVal);
        $scope.paymentTotal.credit_firstPaymentMax = currentPayment - newVal + 1;
      }
    };

    $scope.firstPaymentChanged = (newVal) => {
      const currentPayment = parseFloat($scope.paymentTotal.currentPayment);
      const numOfPayments = parseInt($scope.paymentTotal.credit_numOfPayments);
      const previousFirstPayment = currentPayment - ($scope.paymentTotal.credit_otherPayments * (numOfPayments - 1));
      const calculateOtherPayments = (ceil) => Math[!ceil ? 'floor' : 'ceil']((currentPayment - newVal) / (numOfPayments - 1));
      $scope.paymentTotal.credit_otherPayments = calculateOtherPayments();
      $scope.paymentTotal.credit_firstPayment = calculateFirstPayment(numOfPayments);
      if ($scope.paymentTotal.credit_firstPayment === previousFirstPayment) {
        $scope.paymentTotal.credit_otherPayments = calculateOtherPayments(true);
        $scope.paymentTotal.credit_firstPayment = calculateFirstPayment(numOfPayments);
      }

      $scope.paymentTotal.credit_firstPaymentMax = currentPayment - numOfPayments + 1;
    };

    $scope.$watch('paymentTotal.currentPayment', (newVal, oldVal) => {
      if (newVal !== oldVal) {
        $scope.numOfPaymentsChanged(parseInt($scope.paymentTotal.credit_numOfPayments));
      }

    });


    /***************************************** External Payment *****************************************/
    $scope.validateExternalPaymentWrapper = {};
    $scope.externalPaymentToValidate = {};
    $scope.externalPaymentModel = {ExternalName: $scope.DefaultExternalPaymentName ? $scope.DefaultExternalPaymentName.Value : ""};
    $scope.externalPaymentType = {
      fieldName: 'ExternalName',
      icon: "glyphicon glyphicon-info-sign",
      placeholder: localize.getLocalizedString("_PaymentType_"),
      validation: "{'min-length': 3, 'required': true}"
    };

    $scope.externalPaymentReference = {
      fieldName: 'AuthNumber',
      icon: "icon icon-edit-inventory",
      placeholder: localize.getLocalizedString("_ExternalPaymentNumber_"),
      validation: "{'max-length': 30}"
    };

    var externalPaymentFields = [$scope.externalPaymentType, $scope.externalPaymentReference];


    /***************************************** END External Payment *****************************************/

    $scope.showChangeMessage = false;
    // $scope.manuallyCharge = chargeCard;

    $scope.isCancellingModeWrapper = {paymentToCancel: null};

    var isCreatingInvoice = false;

    function createInvoice() {
      if (isCreatingInvoice) {
        return;
      }

      isCreatingInvoice = true;
      $scope.isCreatingInvoiceWrapper.inProgress = true;

      $scope.invoice.PaymentsToCreate = generatePaymentsArray();
      Object.assign($scope.invoice, createInvoiceGeneralFields());

      if (isNonEmptyArray($scope.invoice.Tips)) {
        let tipsAmount = $scope.invoice.Tips.reduce((acc, curr) => acc + parseFloat(curr.Amount), 0);
        $scope.invoice.PaymentsToCreate.push({Type: 'cash', Amount: -tipsAmount});
        $scope.change = $scope.invoice.CashBack = tipsAmount;
        $scope.invoice.TotalAmount = ($scope.invoice.TotalAmount - tipsAmount).toFixed(2);
        $scope.invoice.TotalAmountBeforeDiscount = ($scope.invoice.TotalAmountBeforeDiscount - tipsAmount).toFixed(2);
      }

      const invoiceCustomer = $scope.invoice.Customer ? {...$scope.invoice.Customer} : undefined;
      delete $scope.invoice.Customer;
      /// Finish session upon success.

      Repository.Custom("CashRegister").submitInvoice($scope.invoice)
        .then(function (iResults) {
          handleSuccessfulInvoiceCreation(iResults, invoiceCustomer);
        })
        .catch(function (errorMessage) {
          toastr.error(localize.getLocalizedString("_ErrorSubmittingInvoice_", angular.isObject(errorMessage) ? errorMessage.data : errorMessage));
          $scope.isCreatingInvoiceWrapper.inProgress = false;
          isCreatingInvoice = false;
        });
    }

    function handleSuccessfulInvoiceCreation(iResults, invoiceCustomer) {
      $rootScope.$emit('clear-cash-register');
      const results = Array.isArray(iResults) ? iResults : [iResults];
      $scope.invoiceId = results[0].InvoiceId;
      wasInvoiceCreated = true;
      if ($scope.largeInvoiceMode) {
        $scope.invoiceResult = {items: results};
        $scope.invoiceResult.Customer = invoiceCustomer;
        $scope.invoiceByMailMode = true;
        $scope.isCreatingInvoiceWrapper.inProgress = false;
        isCreatingInvoice = false;
        return;
      }

      closeAndPrintReceipts(results);
    }


    $scope.createTaxInvoice = function () {
      if (isCreatingInvoice) {
        return;
      }
      if (!$scope.invoice.Customer) {
        toastr.error(localize.getLocalizedString('_DebtMustBeAssignedToCustomer_'));
        return;
      }
      isCreatingInvoice = true;
      $scope.isCreatingInvoiceWrapper.inProgress = true;
      $scope.invoice.PaymentsToCreate = generatePaymentsArray();
      Object.assign($scope.invoice, createInvoiceGeneralFields());

      $scope.invoice.EmployeeId = $scope.selectedEmployee.selected.id;
      $scope.invoice.CashBack = $scope.change;
      var customer = $scope.invoice.Customer;
      delete $scope.invoice.Customer;

      Repository.Custom("CashRegister").submitTaxInvoice($scope.invoice)
        .then(function (data) {
          handleSuccessfulInvoiceCreation(data, customer);
          // $rootScope.$emit('clear-cash-register');
          // const results = Array.isArray(data) ? data : [data];
          // wasInvoiceCreated = true;
          // if ($scope.largeInvoiceMode) {
          //   $scope.invoiceResult = {items: results};
          //   $scope.invoiceResult.Customer = customer;
          //   $scope.invoiceByMailMode = true;
          //   $scope.isCreatingInvoiceWrapper.inProgress = false;
          //   isCreatingInvoice = false;
          //   return;
          // }
          //
          // closeAndPrintReceipts(results);
        })
        .catch(function (result) {
          toastr.error(localize.getLocalizedString("_ErrorSubmittingInvoice_", result.data));
          $scope.isCreatingInvoiceWrapper.inProgress = false;
          isCreatingInvoice = false;
        });
    }

    function generatePaymentsArray() {
      return $scope.paymentTotal.payments.map((payment) => {
        if (payment.type !== 'credit') {
          const paymentToSave = {};
          paymentToSave.Type = payment.type === 'other' ? 'credit' : payment.type;
          paymentToSave.Amount = payment.amount;
          paymentToSave.AuthNumber = payment.AuthNumber;
          paymentToSave.ExternalName = payment.ExternalName;
          paymentToSave.PaymentDueDate = (payment.type === 'cheque') ? moment(payment.PaymentDueDate, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT) : payment.PaymentDueDate;
          return paymentToSave;
        }
      }).filter((x) => !!x);
    }

    function createInvoiceGeneralFields() {
      const toReturn = {};
      toReturn.InvoiceRefersTo = $scope.paymentTotal.invoiceName;
      toReturn.Address = $scope.paymentTotal.invoiceAddress;
      toReturn.IdentifyNumber = $scope.paymentTotal.invoiceIdentifyNumber;
      toReturn.InternalNotes = $scope.paymentTotal.internalNotes;
      toReturn.Remarks = $scope.paymentTotal.remarks ? $scope.paymentTotal.remarks : null;
      toReturn.creditPaymentsIds = $scope.isUsingCreditCard ? $scope.paymentTotal.credit_paymentIds : [];
      toReturn.EmployeeId = $scope.selectedEmployee.selected.id;
      toReturn.CashBack = $scope.change;
      return toReturn;
    }

    function closeAndPrintReceipts(results) {
      closeDialog(getCustomerFromInvoice());
      $timeout(function () {
        try {
          results.forEach((invoiceResult, idx) => setTimeout(() => {
            printerMediator.print(invoiceResult.Receipt);
            setTimeout(() => {
              printVouchers(invoiceResult.EmployeeVouchers);
              printVouchers(invoiceResult.GiftCardVouchers);
            })
          }, idx * 500));

        } catch (e) {
          toastr.error(localize.getLocalizedString("_CannotPrintRecipt_"));
        }
      });
    }

    function printVouchers(toPrint) {
      if (toPrint) {
        angular.forEach(toPrint, function (voucher) {
          $timeout(function () {
            try {
              printerMediator.print(voucher);
            } catch (e) {
              toastr.error(localize.getLocalizedString("_CannotPrintRecipt_"));
            }
          });
        });
      }
    }

    function preventRefresh(cancel) {
      $(window).off('beforeunload.cashRegisterCheckout');
      if (!cancel) {
        $(window).on('beforeunload.cashRegisterCheckout', function (e) {
          return localize.getLocalizedString("_CannotRefreshThisPage_");
        });
      }
    }


    $scope.$on('$destroy', function () {
      $(window).off('beforeunload.cashRegisterCheckout');
    });

    var delayedDialog = null;
    var wasInvoiceCreated = false;

    function lastAction(customer) {
      delayedDialog = null;
      saveAction();
      $modalInstance.close();
      const shouldShowFeedback = customer && (!configuration.get().FeedbackSettings || !configuration.get().FeedbackSettings.HideFeedbackDialog);
      if ($scope.GoBackToCalendarAfterCheckout) {
        $timeout(function () {
          if (customer && customer.CustomerId) {
            contextManager.set('customer', customer.CustomerId);
          }

          if (shouldShowFeedback) {
            reviewsService.showFeedback(customer, () => {
              $state.go("Calendar");
            });
          } else {
            $state.go("Calendar");
          }

        }, 500);
      } else {
        if (shouldShowFeedback) {
          reviewsService.showFeedback(customer);
        }
      }
    }

    function closeDialog(customer) {
      if ($scope.isChargingCard) {
        return;
      }

      if (wasInvoiceCreated) {
        if ($scope.change > 0 && !$scope.showChangeMessage) {
          $scope.invoiceByMailMode = false;
          $scope.showChangeMessage = true;
          delayedDialog = $timeout(function () {
            lastAction(customer);
          }, 10000);
        } else {
          lastAction(customer);
        }

      } else if ($scope.paymentTotal.payments.length == 0) {
        if (!wasInvoiceCreated) {   // was any credit card used?
          $modalInstance.close();
        } else {
          if (delayedDialog) {
            $timeout.cancel(delayedDialog);
            delayedDialog = null;
          }
          lastAction(customer);
        }
      } else if (parseFloat($scope.paymentTotal.balance) === 0) {
        toastr.warning(localize.getLocalizedString("_PleaseSaveInvoiceFirst_"));
      } else {
        toastr.warning(localize.getLocalizedString("_PleaseFinishPaymentsOrCancelThem_"));
      }

    }

    $scope.coreActions = [];
    $scope.secondActionTooltip = localize.getLocalizedString('_DebtOrTaxInvoice_');

    /***************************************** DEBT *****************************************/
    if ($scope.allowDebt && $scope.paymentTotal.balance > 0) {
      $scope.setDebt = function () {
        if (!$scope.invoice.Customer) {
          return toastr.error(localize.getLocalizedString('_DebtMustBeAssignedToCustomer_'));
        }

        confirmService.confirm(localize.getLocalizedString('_SetDebt_'),
          null,
          function () {
            $scope.invoice.Debt = angular.isDefined($scope.invoice.Debt) ? parseFloat($scope.invoice.Debt) + parseFloat($scope.paymentTotal.balance) : $scope.paymentTotal.balance;
            $scope.invoice.TotalAmount = ($scope.invoice.TotalAmount - $scope.paymentTotal.balance).toFixed(2);
            $scope.invoice.TotalAmountBeforeDiscount = ($scope.invoice.TotalAmountBeforeDiscount - $scope.paymentTotal.balance).toFixed(2);
            createInvoice();
          },
          function () {
            // DOES NOTHING.
          }
        );

      };

      $scope.coreActions.push({
        name: localize.getLocalizedString('_CreateDebt_'),
        action: () => {
          $scope.setDebt();
        }
      });
    }

    if ($scope.allowTaxInvoice && $scope.paymentTotal.balance > 0) {
      $scope.coreActions.push(
        {
          name: localize.getLocalizedString('_CreateTaxInvoice_'), action: () => {
            $scope.createTaxInvoice();
          }
        }
      );
    }


    /***************************************** END OF DEBT *****************************************/

    /***************************************** Enable credit card! *****************************************/

    if ($scope.isUsingCreditCard) { //using credit) {
      $scope.paymentMethods.splice(1, 0, {name: localize.getLocalizedString("_Credit_"), value: 'credit'});
      // $scope.paymentMethods.push({ name: localize.getLocalizedString("_Other_"), value: 'other' });
      $scope.creditPaymentTypes = [
        {name: localize.getLocalizedString("_RegularCreditPayments_"), value: 0},
        {name: localize.getLocalizedString("_CreditCreditPaymentMode_"), value: 1}
      ];
      $scope.selectedCreditPaymentType = {};
      var creditPaymentsPreferredType = configuration.get().CashRegisterSettings.DefaultCreditPaymentsType &&
        configuration.get().CashRegisterSettings.DefaultCreditPaymentsType.Value || 0;
      $scope.maxNumberOfPayments = configuration.get().CashRegisterSettings.MaximumNumberOfPayments.Value || 36;
      $scope.selectedCreditPaymentType.value = $scope.creditPaymentTypes[creditPaymentsPreferredType];
      $scope.paymentTotal.credit_numOfPayments = 1;
      $scope.paymentTotal.credit_paymentIds = [];
      $scope.creditCardNumberWrapper = {};
      $scope.creditCardNumberWrapper.value = null;
      $scope.selectedPaymentMethodWrapper.value =
        $scope.paymentMethods.filter(x => x.value.toUpperCase() === configuration.get().CashRegisterSettings.DefaultPaymentMethod.Value.toUpperCase())[0] || $scope.paymentMethods[0];

      $scope.otherTerminals = [];
      $scope.selectedTerminal = {};
      $scope.multiplePinPads = [];


      if (pci.terminals.length > 1) {
        pci.terminals.forEach((terminal) => {
          $scope.otherTerminals.push({
            name: terminal.name,
            value: terminal.name,
            pinPads: terminal.pinPads
          });
        })

        $scope.selectedTerminal.selected = $scope.otherTerminals[0];
        $scope.$watch('selectedTerminal.selected', (newVal) => {
          if (newVal && newVal.pinPads) {
            $scope.emvConnect = newVal.pinPads[0].name;
            if (newVal.pinPads.length > 1) {
              $scope.multiplePinPads = newVal.pinPads.map((pinPad) => ({
                name: pinPad.name,
                value: pinPad.name,
              }));
            } else {
              $scope.multiplePinPads = null;
            }
          }
        });
      } else {
        /// Handle single terminal by showing all pinpads.
        if (pci.pinPads && pci.pinPads.length > 0) {
          $scope.emvConnect = pci.pinPads[0].name;
          if (pci.pinPads.length > 1) {
            $scope.multiplePinPads = pci.pinPads.map((pinPad) => ({
              name: pinPad.name,
              value: pinPad.name,

            }));
          }
        }
      }


      $scope.$watch('selectedCreditPaymentType.value', (newVal, oldVal) => {
        if (newVal !== oldVal) {
          if (newVal.value === 1) {//if credit type of payments
            $scope.maxNumberOfPayments = 36;
          } else {
            $scope.maxNumberOfPayments = configuration.get().CashRegisterSettings.MaximumNumberOfPayments.Value || 36;
          }
        }

      });

      ////////// SAVED CARD.
      if ($scope.allowCreditToken && $scope.invoice.Customer) {
        pci.getCustomerToken($scope.invoice.Customer.CustomerId, customCreditCardToken).then((result) => {
          if (result && result.cardExpirationDate) {
            if (!moment(result.cardExpirationDate, CREDIT_CARD_EXPIRATION).add(1, 'month').isAfter(moment())) {
              toastr.warning(localize.getLocalizedString('_SavedCardHasBeenExpired_'))
            } else {
              $scope.isSavedCardExist = true;
              $scope.bindInternalToken = result.bindInternalToken;
            }
          }
        }).catch((e) => {
          if (e.status !== 404) {
            console.log(e);
            toastr.error(e.message);
          }
        });
      }

      /***************************************** Charging Credit Card. *****************************************/

      $scope.isChargingCard = false;

      /// Currently not in use since the promise doesn't really cancel credit card charing.
      let chargePromise;
      const chargeCredit = function (pinpad, useCardToken, track2) {
        validateBeforeCreditCharge().then(() => {
          const transaction = getTransactionMetadata();

          if (pinpad) {
            transaction.pinpad = pinpad;
            $scope.isChargingEMV = true;
            $scope.cardType = localize.getLocalizedString("_PinpadTransaction_");
          } else if (useCardToken) {
            transaction.useCreditCardToken = true;
            setIfHasValue(transaction, 'bindInternalToken', $scope.bindInternalToken);
            $scope.cardType = localize.getLocalizedString("_ChargingCardNow_");
          } else if (track2) {
            transaction.track2 = track2;
            $scope.cardType = localize.getLocalizedString("_ChargingCardNow_");
          }

          const toSend = pci.generateTransaction(transaction);
          decorateInvoiceToCreate(toSend);

          $scope.cancelEmvCharge = () => {
            chargePromise.cancelPromise();
          };

          internalCharge(toSend);
        });
      };

      const internalCharge = (toCharge, preventAuth) => {
        $scope.isChargingCard = true;
        chargePromise = pci.chargeCreditCard(toCharge).then(handleSuccessfulCreditTransaction)
          .catch((e) => {
            toastr.error(pci.extractError(e));
            $scope.isChargingCard = false;
            $scope.isChargingEMV = false;
            $scope.cardType = '';
            $rootScope.$broadcast('cancel-charging-card');
            if (!preventAuth && e.status === pci.PHONE_AUTH_REQUIRED_ERROR_CODE) {
              pci.confirmPromptingCreditCardAndResendIfAuthorized((authNum) => internalCharge({
                ...toCharge,
                authNum
              }, true));
            }

          })
      }

      $scope.chargeEmvCard = (iPinpad) => {
        let pinPad = iPinpad;
        /// This means either there is only one terminal or the following terminal has only one pinpad attached to it. grab it.
        if (!pinPad) {
          pinPad = $scope.selectedTerminal.selected && $scope.selectedTerminal.selected.pinPads && $scope.selectedTerminal.selected.pinPads[0].name;
        }

        if (!pinPad) {
          pinPad = pci.pinPads[0].name;
        }

        chargeCredit(pinPad);
      };

      $scope.manuallyChargeCard = () => {
        validateBeforeCreditCharge().then(() => {
          const transactionMetadata = getTransactionMetadata();
          decorateInvoiceToCreate(transactionMetadata);
          manualCreditChargeService.show(transactionMetadata, handleSuccessfulCreditTransaction);
        })
      };

      //// TOKEN - SAVED CARD.
      $scope.chargeSavedCard = () => chargeCredit(null, true);

      //// TRACK2
      creditCardReaderFactory.register((track2) => {
        chargeCredit(null, null, track2)
      });

      $scope.isCreditInputFocusedWrapper = {isFocused: false};


      function getTransactionMetadata() {
        const options = {};

        if ($scope.selectedTerminal && $scope.selectedTerminal.selected && $scope.selectedTerminal.selected.name !== 'default') {
          options.terminal = $scope.selectedTerminal.selected.name;
        }

        options.customerId = $scope.invoice.Customer ? $scope.invoice.Customer.CustomerId : null;
        options.amount = parseFloat($scope.paymentTotal.currentPayment);
        options.currency = 1;
        if ($scope.paymentTotal.credit_numOfPayments > 1) {
          options.numOfPayments = $scope.paymentTotal.credit_numOfPayments;
          options.creditPayments = $scope.selectedCreditPaymentType.value.value === 1;
          if (!options.creditPayments) {
            options.firstPaymentAmount = parseFloat($scope.paymentTotal.credit_firstPayment);
            options.otherPaymentsAmount = $scope.paymentTotal.credit_otherPayments;
          }
        }

        try {
          options.isRefund = options.amount < 0 && $scope.invoice.CustomItems.some((item) => parseFloat(item.RetailPrice) < 0);
        } catch (e) {

        }

        return options;
      }

      function handleSuccessfulCreditTransaction(transactionResult) {
        printCreditVoucherIfNeeded(transactionResult);
        $scope.isChargingCard = false;
        if (transactionResult.invoiceResult) {
          return handleSuccessfulInvoiceCreation(transactionResult.invoiceResult, $scope.invoice.Customer);
        }

        toastr.success(localize.getLocalizedString("_CreditTransactionSuccesseded_"));
        $scope.acceptPayment();
        $scope.paymentTotal.credit_paymentIds.push(transactionResult.paymentId);
        if (parseFloat($scope.paymentTotal.balance) === 0) {
          $scope.save();
        }

        $scope.cardType = "";
      }

      function printCreditVoucherIfNeeded(transactionResult) {
        if (!pci.printCreditVoucher) {
          return;
        }

        Repository.Custom('CashRegister').creditVoucher(transactionResult.paymentId)
          .then((res) => {
            if (res && res.results) {
              printVouchers(res.results.map(x => x.Origin));
            }
          }).catch((e) => {
          toastr.error(e);
        })
      }

      function decorateInvoiceToCreate(transaction) {
        if (transaction.amount === invoiceToCreateViaPci?.totalAmount) {
          const invoiceToCreate = duplicate(invoiceToCreateViaPci);
          const customInvoiceDetails = createInvoiceGeneralFields()
          invoiceToCreate.employeeId = customInvoiceDetails.EmployeeId;
          if (isNonEmptyString(customInvoiceDetails.Address) || isNonEmptyString(customInvoiceDetails.IdentifyNumber)) {
            invoiceToCreate.invoiceDetails = cleanObj({
              address: customInvoiceDetails.Address,
              identifyNumber: customInvoiceDetails.IdentifyNumber
            })
          }

          if (isNonEmptyArray(parameters.invoice.MeetingsToSetAsPaid)) {
            invoiceToCreate.meetingsToSetAsPaid = parameters.invoice
              .MeetingsToSetAsPaid.map(({MeetingId, MeetingStartTime}) => ({
                meetingId: MeetingId,
                startTime: MeetingStartTime
              }))

          }

          invoiceToCreate.remarks = customInvoiceDetails.Remarks;
          invoiceToCreate.showNonPaidItems = true;
          transaction.invoiceToCreate = invoiceToCreate;
          /**
           * This is here to handle tips. Before sending the credit, the invoice total amount is not correct.
           * So we need to add the tips to the invoice total amount.
           *
           */

          if (isNonEmptyArray(getSafely(['invoiceToCreate', 'items'], transaction))) {

            /**
             * Sum all tips and add them to the invoice total amount.
             */
            const tipsSummary = transaction.invoiceToCreate.items.reduce((acc, item) =>
              acc + (item.itemType === InvoiceItemType.TIP ? valueOrDefault(item.actualPrice, item.retailPrice) : 0), 0);

            if (isNumber(tipsSummary) && tipsSummary > 0) {
              transaction.invoiceToCreate.totalAmount -= tipsSummary;
              transaction.invoiceToCreate.totalAmountBeforeDiscount -= tipsSummary;
              transaction.invoiceToCreate.cashBack = tipsSummary;
              transaction.invoiceToCreate.paymentsToCreate = valueOrDefault(transaction.invoiceToCreate.paymentsToCreate, []);
              transaction.invoiceToCreate.paymentsToCreate.push(
                {Type: EasybizyPaymentsTypeMap.Cash, Amount: tipsSummary * -1});
            }
          }

        }

        return transaction;
      }


      function ensurePayments() {
        const deferred = $q.defer();
        if ($scope.remindAboutPayments < 1 ||
          $scope.paymentTotal.credit_numOfPayments > 1 ||
          $scope.paymentTotal.currentPayment < $scope.remindAboutPayments) {
          deferred.resolve(true);
        } else {
          confirmService.confirm(localize.getLocalizedString('_LargeAmountWithoutPayments_'),
            null,
            () => {
              deferred.resolve(true);
            }
          );
        }

        return deferred.promise;
      }

      function validateBeforeCreditCharge() {
        const deferred = $q.defer();
        if (!isValidPaymentAmount()) {
          toastr.error(localize.getLocalizedString('_PaymentCantExceedTotalAmount_'));
          deferred.reject(false);
          return deferred.promise;
        }

        return ensurePayments();
      }

      /***************************************** END-OF Charging Credit Card! *****************************************/

      $scope.useMissingPayment = function (payment) {
        if (Math.abs($scope.paymentTotal.balance) >= Math.abs(payment.amount)) {
          $scope.paymentTotal.currentPayment = payment.amount;
          $scope.paymentTotal.credit_paymentIds.push(payment.id);
          $scope.acceptPayment(Object.assign({}, payment, {type: 'credit'}));
          if (parseFloat($scope.paymentTotal.balance) === 0) {
            $scope.save();
          }

          return true;
        } else {
          toastr.error(localize.getLocalizedString('_PaymentAmountLargerThenInvoice_'));
          return false;
        }
      };
    }

    /***************************************** Charge card! *****************************************/


    $scope.isValidPayment = isValidPaymentAmount;

    function isValidPaymentAmount() {
      return parseFloat($scope.paymentTotal.currentPayment) <= parseFloat($scope.paymentTotal.balance);
    }

    function getCustomerFromInvoice() {
      return (customer && $scope.invoice && $scope.invoice.CustomerId && {
        ...customer,
        invoiceId: $scope.invoiceId
      }) || null;
    }

    /***************************************** ENDOF Missing payments section! *****************************************/
  });

angular.module('easybizy.easybizyModalViewsModel').directive("alwaysFocused", [
  '$timeout', 'focusManager', function ($timeout, focusManager) {
    return {
      restrict: 'A',
      replace: true,
      scope: {
        alwaysFocused: '=',
        isFocusedWrapper: '='
      },
      link: function (scope, element) {
        scope.$watch('alwaysFocused', function (value) {
          if (value === true) {
            turnAlwaysFocusOn();
          } else {
            //element.off('blur');
          }
        });

        focusManager.register(outsideAllowFocusChanged);
        scope.$on('$destroy', function () {
          element.off('blur focus');
          focusManager.unregister(outsideAllowFocusChanged);
        });

        function outsideAllowFocusChanged(value) {
          scope.isOutsideFocused = value;
          if (!value) {
            element.focus();
          }
        }

        function turnAlwaysFocusOn() {
          element.on('blur', function () {
            if (!scope.alwaysFocused) {
              return;
            }

            $timeout(function () {
              if (!scope.isOutsideFocused) {
                forceFocusElement();
              }
            });
          });

          forceFocusElement();
        }

        function forceFocusElement() {
          $timeout(function () {
            element.focus();

            $timeout(function () {
              if (!element.is(":focus")) {
                forceFocusElement();
              }
            }, 200);

          });
        }
      }
    };
  }]);


angular.module('easybizy.easybizyModalViewsModel').directive('allowFocused', [
  '$timeout', 'focusManager', function ($timeout, focusManager) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        var elementToBind = null;
        if ($(element).is("textarea") || $(element).is("input")) {
          elementToBind = element;
        } else {
          elementToBind = element.find('input');
        }

        elementToBind.on('focus', function () {
          focusManager.toggleFocus(true);
          this.focus();
        }).on('blur', function (e) {
          focusManager.toggleFocus(false);
        });

        scope.$on('$destroy', function () {
          elementToBind.off('blur focus');
        });
      }
    };
  }]);

angular.module('easybizy.easybizyModalViewsModel').factory('focusManager', function () {
  var isOutsideFocused = false;
  var listeners = [];

  const toggleManager = {
    toggleFocus: toggleFocus,
    register: function (listener) {
      listeners.push(listener);
    },
    unregister: function (listener) {
      listeners.remove(listener);
    },
    preventEscape: (scope) => {
      assert(!!scope && scope.$on, 'scope must be supplied to prevent memory leaks.')
      const randNum = 'keydown.escape' + moment().format(UNIQUE_DATE_TIME);
      $(document).on(randNum, function (e) {
        if (e.which === 27) {
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();
        }
      });

      scope.$on('$destroy', () => {
        $(document).off(randNum);
      })
    }
  };

  function toggleFocus(isFocused, scope) {
    isOutsideFocused = isFocused;
    focusToggled();
    if (scope && scope.$on) {
      scope.$on('$destroy', () => {
        toggleFocus(false);
      });
    }
  }

  function focusToggled() {
    angular.forEach(listeners, function (listener) {
      listener(isOutsideFocused);
    });
  }

  return toggleManager;

});

angular.module('easybizy.easybizyModalViewsModel').factory('creditCardReaderFactory', function () {
  var registered = null;
  var registeredKey = null;
  return {
    register: function (func) {
      registered = func;
    },
    registerKey: function (func) {
      registeredKey = func;
    },
    invoke: function (number) {
      registered(number);
    },
    invokeChar: function (key) {
      if (registeredKey) {
        registeredKey(key);
      }
    },
    unregister: function () {
      registered = null;
      registeredKey = null;
    }
  };
});

angular.module('easybizy.easybizyModalViewsModel').directive('onlyAllowCreditCardInput', [
  'creditCardReaderFactory', function (creditCardReaderFactory) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        element.bind('copy paste cut', onPasteCopyCut);

        function onPasteCopyCut(e) {
          e.preventDefault(); //disable cut,copy,paste
        }

        scope.$on('$destroy', function () {
          element.unbind('copy paste cut keydown', onPasteCopyCut);
        });

        var lastTimeSpan = 0;
        var timeOutSet = null;
        var clearValue = function () {
          element.val('');
        };

        element.on('keydown', function (e) {
          if (lastTimeSpan == 0 || (lastTimeSpan > 0 && (e.timeStamp - lastTimeSpan < 100))) {
            lastTimeSpan = e.timeStamp;
          } else {
            lastTimeSpan = 0;
            clearValue();
            creditCardReaderFactory.invokeChar(e.key);
          }

          if (timeOutSet) {
            clearTimeout(timeOutSet);
          }

          setTimeout(clearValue, 3000);
        });

        element.change(function () {
          var newVal = element.val();
          if (newVal.length > 6) {
            creditCardReaderFactory.invoke(newVal);
          } else {
            console.log("not a credit card!");
          }

          element.val('');
        });
      }
    };
  }]);
