import './promotions.widget.less'
import './promotion-filter-editor/promotion.filter.editor'
import './promotion-send-summary-dialog/promotion.send.summary.dialog'
import '../../modal-views/promotion-test-flight/promotion.test.flight.modal'
import { DATE_DISPLAY_FORMAT, HOURS_AND_MINUTES_FORMAT, ODATA_DATE_TIME_FORMAT } from "../../constants";
import { isFunction, isNonEmptyArray, isNonEmptyString } from "@tomeravni/easybizy-js-common/common";
import { val } from '@uirouter/angularjs';

angular.module('easybizy.promotions.widget').controller('PromotionWidgetsController', function (
  $scope, Repository, toolbar, $timeout, localize,
  $state, iconsResolver,
  $modal, promotionsPresetResolver, promotionTemplateResolver, promotionsMediumResolver, configuration, confirmService, $q
) {
  toolbar.set([]);
  $scope.isRightToLeft = localize.isRightToLeft();
  $scope.selectedTemplateWrapper = {};
  $scope.Math = window.Math;
  $scope.currentFilters = [];
  $scope.advancedFilters = [];

  $scope.minRepeatLength = {};
  $scope.repeatCampaignObj = {};
  $scope.repeatCampaignFlag = {};
  $scope.repeatCampaignFlag.value = false;
  $scope.concretePromotionContentResolverWrapper = {};
  $scope.currentPresetType = null;
  $scope.concreteCustomers = [];

  $scope.customersForCurrentFilterWrapper = {};

  // PROMOTION NAME FIELD
  $scope.promotionNameWrapper = {};
  $scope.promotionNameValidator = {};
  $scope.validateActionWrapper = {};
  $scope.promotionNameWrapper.PromotionName = "";
  $scope.promotionNameFieldMetadata = {
    fieldName: 'PromotionName', icon: "icon icon-promote", placeholder: localize.getLocalizedString("_PromotionName_"),
    validation: "{'required': true, 'max-length': 40}"
  };

  $scope.isRepeatDefined = function () {
    return $scope.minRepeatLength.length && !isNonEmptyArray($scope.concreteCustomers);
  };

  $scope.$watch('selectedTemplateWrapper', function (newVal) {
    if (newVal.entity) {
      $scope.currentPresetType = newVal.entity;
      $scope.IsServiceEngagementType = newVal.entity.IsServiceEngagementType;
      requestAnimationFrame(requestAnim);

      $scope.promotionNameWrapper.PromotionName = newVal.entity.TemplateLabel + " " + moment().format(DATE_DISPLAY_FORMAT + " " + HOURS_AND_MINUTES_FORMAT);
    }

    setSendButtonState(false);
  }, true);

  $scope.selectedMediumWrapper = {};

  $scope.mediumSelected = function (newVal) {
    if (newVal) {
      $timeout(function () {
        $scope.selectStep(1);
      });
    }
  };

  function requestAnim() {
    $('.promotion-first-step.prevent-selection-children').addClass('splitted');
  }

  // THE ACTUAL PROMOTION CREATED!
  $scope.concretePromotion = {};
  $scope.concretePromotion.rawFilters = [];
  $scope.concretePromotion.filterByCalendar = {value: false};
  $scope.concretePromotion.Repeat = null;
  // END

  $scope.selectStep = function (item) {
    var desiredStep = $scope.actions[item];
    $scope.rightToLeftStep = desiredStep.step > $scope.selection.step;
    $scope.selection = desiredStep;
  };

  $scope.$watch("selection.step", function (newVal, oldVal) {
    $scope.$broadcast('stepChanged', newVal);
    if (newVal < oldVal) {
      setSendButtonState(false);
    }
  });

  $scope.$watch("concreteCustomers.length", function () {
    $scope.repeatCampaignFlag.value = $scope.repeatCampaignFlag.value && $scope.concreteCustomers.length == 0;
  });

  $scope.editModeWrapper = {};
  $scope.editModeWrapper.isEditMode = false;
  $scope.$watch('editModeWrapper.isEditMode', function (newVal) {
    if (angular.isDefined(newVal) && sendAction.wasApproved) {
      setSendButtonState(false);
    }
  });

  $scope.sendActionName = localize.getLocalizedString('_Send_');
  var approvePromotionTimeoutKey = null;
  var sendAction = new function () {
    this.name = localize.getLocalizedString('_Send_');
    this.icon = 'glyphicon glyphicon-ok';
    this.step = 2;
    this.class = "";
    this.wasApproved = false;
    this.action = function () {
      const promotionFromConcrete = $.extend(true, { PresetName: $scope.currentPresetType.TemplateName }, $scope.concretePromotion);

      if (isFunction($scope.concretePromotionContentResolverWrapper.resolver.validate)) {
        let errorMessage = $scope.concretePromotionContentResolverWrapper.resolver.validate();
        if (isNonEmptyString(errorMessage)) {
          return toastr.error(errorMessage);
        }
      }


      $scope.concretePromotionContentResolverWrapper.resolver.resolve(promotionFromConcrete);

      addUnsubscribeIfNeeded(promotionFromConcrete).then(function (promotion) {
        var promotionLength = promotion.ClientPromotionContent.Message.length;
        var smss = parseInt((promotionLength / 220) + (promotionLength % 220 > 0 ? 1 : 0));
        Repository.Custom("PromotionsRepository").calculate($scope.customersForCurrentFilterWrapper.value.countAfterDisabled * smss)
          .then(function (cost) {
            if (cost > 10) {
              confirmService.confirm(localize.getLocalizedString('_SendPromotionWillCost_', cost),
                null, function () {
                  openSendSummaryDialog();
                });
            } else {
              openSendSummaryDialog();
            }
          })
          .catch(function (e) {
          });
      });
    };
  };

  $scope.actions = [
    {
      name: localize.getLocalizedString('_Next_'),
      icon: 'glyphicon glyphicon-arrow-' + ($scope.isRightToLeft ? 'left' : 'right'),
      step: 0,
      action: function () {
        $scope.selectStep(1);
      }
    },
    {
      name: localize.getLocalizedString('_Next_'),
      icon: 'glyphicon glyphicon-arrow-' + ($scope.isRightToLeft ? 'left' : 'right'),
      step: 1,
      action: function () {
        if ($scope.concretePromotion.rawFilters.length == 0 && !$scope.concreteCustomers.length) {
          confirmService.confirm(localize.getLocalizedString('_SendPromotionToAllCustomers_', $scope.customersForCurrentFilterWrapper.value.countAfterDisabled),
            null, function () {
              $scope.$evalAsync(function () {
                $scope.selectStep(2);
                $scope.IsForAllCustomers = true;
              });
            });
        } else {
          $scope.selectStep(2);
        }
      }
    },
    sendAction
  ];

  $scope.isReadyToSend = false;

  function openSendSummaryDialog() {
    var opts = {
      backdrop: 'static',
      keyboard: true,
      backdropClick: true,
      template: require('../../partial-views/promotions-widget/promotion-send-summary-dialog/promotion.send.summary.dialog.html'),
      controller: 'PromotionSendSummaryDialogController',
      resolve: {
        parameters: function () {
          return { repeating: $scope.concretePromotion.Repeat, name: $scope.promotionNameWrapper.PromotionName };
        }, saveAction: function () {
          return function (promotionName, sendTime) {
            return createNewPromotion(promotionName, sendTime);
          };
        }
      },
      closeFn: function () {
        console.log("closing");
      }
    };

    $modal.open(opts);
  }


  function setSendButtonState(isWarningMode) {
    if (isWarningMode) {
      sendAction.wasApproved = true;
      sendAction.name = localize.getLocalizedString('_Sure_');
      sendAction.class = "warning-mode";
      $timeout(function () {
        $scope.isReadyToSend = true;
      }, 330);
    } else {
      $timeout.cancel(approvePromotionTimeoutKey);
      $scope.isReadyToSend = false;
      sendAction.wasApproved = false;
      sendAction.class = "";
      sendAction.name = localize.getLocalizedString('_Send_');
    }
  }

  $scope.selection = $scope.actions[0];

  var constantPresetsNumber = 3;
  $scope.presets = promotionsPresetResolver.getPresets(constantPresetsNumber);
  promotionsMediumResolver.getPresets(3, configuration.isValidFacebook() ? 0 : 1).then((res) => {
    $scope.mediums = res;
  })

  $scope.allPresetsVisible = false;
  $scope.doneShowingAllPresets = false;

  $scope.showMore = function () {
    if ($scope.presets.length === constantPresetsNumber) {
      $scope.presets.pushAll(promotionsPresetResolver.getPresets(undefined, 3));
    }
    $timeout(function () {
      $scope.allPresetsVisible = true;
    }, 300);

    $timeout(function () {
      $scope.doneShowingAllPresets = true;
    }, 500);
  };

  $scope.$watch('minRepeatLength', function (newVal) {
    if (newVal.length) {
      $scope.repeatCampaignObj.value = newVal.length;
      $scope.repeatCampaignObj.name = newVal.length ? localize.getLocalizedString('_RepeatCampaignEvery_', localize.getLocalizedString('_' + newVal.length + '_')) : "";
    }
  }, true);

  $scope.$watch('repeatCampaignFlag.value', function (newVal) {
    $scope.concretePromotion.Repeat = newVal ? $scope.repeatCampaignObj.value : null;
  });

  $scope.getTilesWidth = function (count) {
    var numberOfTiles = count ? count : $scope.presets.length;

    var width = ((210 + 55) * (numberOfTiles % 2 > 0 ? ((numberOfTiles + 1) / 2 + 1) : numberOfTiles / 2));
    if (count == 2) {
      width *= 2;
    }

    return width + 'px';
  };

  $scope.getTilesLeft = function (force, count) {
    if (!force && localize.language === 'he-IL') {
      return 0;
    }

    var numberOfTiles = count ? count : $scope.presets.length;
    var width = ((210 + 55) * (numberOfTiles % 2 > 0 ? ((numberOfTiles + 1) / 2 + 1) : numberOfTiles / 2));
    if (count == 2) {
      width *= 2;
    }

    width = ($(document).width() - width) / 2;
    return width + 'px';
  };

  $scope.testFlight = testFlight;

  /*
   Creates a new promotion from the current data + adding the current promotion content data.
   */
  function createNewPromotion(promotionName, sendTime) {
    var promotion = $.extend(true, {}, $scope.concretePromotion);
    delete promotion.filterByCalendar;
    promotion.PromotionName = promotionName;
    promotion.PresetName = $scope.currentPresetType.TemplateName;

    promotion.IsServiceEngagementType = $scope.IsServiceEngagementType;
    promotion.IsForAllCustomers = !!$scope.IsForAllCustomers;
    if ($scope.concreteCustomers.length > 0) { // customers were manually selected.
      promotion.CustomersId = [];
      $scope.concreteCustomers.forEach(function (customer) {
        if (customer.isMarked) {
          promotion.CustomersId.push(customer.CustomerId);
        }
      });

    } else {
      promotion.Filters = [];
      angular.forEach(promotion.rawFilters, function (filter) {
        let jsonFilter = filter.toJSON();
        jsonFilter.ByCalendar = $scope.concretePromotion.filterByCalendar.value;
        promotion.Filters.push(jsonFilter);
        
      });
    }
    $scope.concretePromotionContentResolverWrapper.resolver.resolve(promotion);
    if ($scope.addUnsubscribeToContent) {
      const unsubscribe = promotionTemplateResolver.getDynamicFields($scope.currentPresetType.TemplateName)
        .find(({ fieldName }) => fieldName === 'UnsubscribeLink');
      promotion.ClientPromotionContent.Message += `\n${localize.getLocalizedString('_UnsubscribeTitleOnPromotion_')}: ${unsubscribe.value}`;
    }
    promotion.StartTime = sendTime.format(ODATA_DATE_TIME_FORMAT);

    return Repository.Custom("PromotionsRepository").create(promotion);
  }


  function addUnsubscribeIfNeeded(promotion) {
    /// We're trying to make sure the unsubscribe
    const deferred = $q.defer();
    if (promotion.ClientPromotionContent.PromotionSenderType !== 'whatsapp' && promotion.PresetName !== promotionsPresetResolver.meetingReminderPresetName) {
      try {
        const unsubscribe = promotionTemplateResolver.getDynamicFields($scope.currentPresetType.TemplateName)
          .find(({ fieldName }) => fieldName === 'UnsubscribeLink');
        if (!promotion.ClientPromotionContent.Message.includes(unsubscribe.value)) {
          confirmService.confirm(/*title*/
            localize.getLocalizedString('_PromotionWithoutUnsubscribe_'),
            null,
            /*onOk*/
            function () {
              $scope.addUnsubscribeToContent = true;
              promotion.ClientPromotionContent.Message += `\n${localize.getLocalizedString('_UnsubscribeTitleOnPromotion_')}: ${unsubscribe.value}`;
              deferred.resolve(promotion);
            },
            function () {
              $scope.addUnsubscribeToContent = false;
              deferred.resolve(promotion);
            },
            /*Ok text*/
            localize.getLocalizedString('_YesAdd_'),
            localize.getLocalizedString('_NoThanks_')
          );

        } else {
          $scope.addUnsubscribeToContent = false;
          deferred.resolve(promotion);
        }
      } catch (e) {
        deferred.resolve(promotion);
        console.log(e);
      }
    } else {
      deferred.resolve(promotion);
    }
    return deferred.promise;
  }

  function testPromotion(to) {
    var deferred = $q.defer();
    var promotion = $.extend(true, {}, $scope.concretePromotion);
    promotion.PresetName = $scope.currentPresetType.TemplateName;
    promotion.IsServiceEngagementType = $scope.IsServiceEngagementType;

    $scope.concretePromotionContentResolverWrapper.resolver.resolve(promotion);
    Repository.Custom("PromotionsRepository").test(promotion, to)
      .then(function (data) {
        toastr.success(localize.getLocalizedString('_TestSent_'));
        deferred.resolve();
      })
      .catch(function (e) {
        toastr.error(localize.getLocalizedString('_TestCreateError_'));
        deferred.reject(e);
      });

    return deferred.promise;
  }

  function testFlight() {
    var opts = {
      backdrop: 'static',
      keyboard: true,
      backdropClick: false,
      template: require('../../modal-views/promotion-test-flight/promotion.test.flight.modal.tpl.html'),
      controller: "PromotionTestFlightModalController",
      resolve: {
        promotionData: function () {
          var defaultTo = $scope.concretePromotionContentResolverWrapper.currentType === "Mail" ?
            configuration.get().PromotionsSettings.PromotionTestDefaultEmail.Value :
            configuration.get().PromotionsSettings.PromotionTestDefaultMobile.Value;
          return defaultTo;
        },
        sendAction: function () {
          return function (to) {
            return testPromotion(to);
          };
        }
      },
      closeFn: function () {
        console.log("closing");
      }
    };

    var modalInstance = $modal.open(opts);
  }
});

angular.module('easybizy.promotions.widget').directive('dynamicCssSelector', [
  '$timeout', '$parse', function ($timeout, $parse) {
    return {
      restrict: 'A',
      scope: {
        dynamicCssBind: '='
      },
      link: function (scope, element, attrs) {
        scope.Math = window.Math;
        var lastAppendedStyle = undefined;
        scope.$watch('dynamicCssBind', function (newLength) {
          var shouldEvaluate = true;
          if (angular.isDefined(attrs["dynamicCssFlag"])) {
            var expression = attrs.dynamicCssFlag.replace("value", newLength);
            var shouldEvaluate = scope.$eval(expression);
          }

          if (shouldEvaluate && newLength > 0) {
            var string = attrs.dynamicCssSelector;
            var evaluatedSelector = string.replace(/\[.*\]/g, function (match) {
              var matchToEval = match.substring(1, match.length - 1);
              return scope.$eval(matchToEval);
            });

            //console.log(evaluatedSelector);
            if (angular.isDefined(lastAppendedStyle)) {
              lastAppendedStyle.remove();
            }

            evaluatedSelector = "<style>" + evaluatedSelector + "</style>";
            lastAppendedStyle = $(evaluatedSelector);
            lastAppendedStyle.appendTo("head");
          }
        });
      }
    };
  }]);

