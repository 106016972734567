import './timeline.directive.less';
import { DATE_DISPLAY_FORMAT, SHORT_DATE_FORMAT, HOURS_AND_MINUTES_FORMAT, SERVER_DATA_DATE_FORMAT } from '../../constants'
import html2pdf from 'html2pdf.js';

(function () {

  var timelineModule = angular.module('easybizy.common.timeline', []);

  timelineModule.directive('timeline', [
    'localize', 'embedScrollLogic', function (localize, embedScrollLogic) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          items: '=',
          proxy: '=',
          mainEntity: '=',
          isLoadingWrapper: '=',
          readonly: '='
        },
        controller: function ($scope) {
          this.replaceModel = function (toRemove, timelineItemToInsert) {
            $scope.items.remove(toRemove);
            if (timelineItemToInsert) {
              $scope.items.unshift(timelineItemToInsert);
            }

            embedScrollLogic.scrollTop();
            $scope.$digestIfNeeded();
          };
        },
        link: function (scope, element, attrs) {
          var lazyLoadingWrapper = {};
          embedScrollLogic.embed(scope, element.parent(), { whenScrolled: loadTimelineItems });

          var lastResultedFilter = null;

          scope.$watchOnce('proxy', function (newVal) {
            lazyLoadingWrapper.maxItemsToLoad = 7;
            lazyLoadingWrapper.skip = 0;
            lazyLoadingWrapper.top = 7;
            newVal.reload = cleanLoad;

            newVal.orderBy('EngagementDate', true);
            loadTimelineItems();
            scope.$watch('proxy.params.filter', function (newVal) {
              if (angular.isDefined(newVal) && newVal != lastResultedFilter) {
                cleanLoad()
              }
            });

          });

          var lastLoadedPaging = null;

          function cleanLoad() {
            lastResultedFilter = scope.proxy.params.filter;
            lazyLoadingWrapper.skip = 0;
            lazyLoadingWrapper.top = 7;
            lazyLoadingWrapper.finishedLoading = false;
            lastLoadedPaging = null;
            scope.items.length = 0;
            loadTimelineItems();
          }

          function loadTimelineItems() {
            if (lazyLoadingWrapper.finishedLoading) {
              return;
            }

            if (lastLoadedPaging && (lastLoadedPaging.top == lazyLoadingWrapper.top
              && lastLoadedPaging.skip == lazyLoadingWrapper.skip)) {
              return;
            } else {
              lastLoadedPaging = angular.copy(lazyLoadingWrapper);
            }

            scope.proxy.top(lazyLoadingWrapper.top);
            scope.proxy.skip(lazyLoadingWrapper.skip);
            scope.isLoadingWrapper.isLoading = true;
            scope.proxy.get().then(function (data) {
              data.forEach(item => item.Type = (item.Type || item.Category));
              scope.items.pushAll(data);
              if (data.length < lazyLoadingWrapper.maxItemsToLoad) {
                lazyLoadingWrapper.finishedLoading = true;
              } else {
                lazyLoadingWrapper.skip += lazyLoadingWrapper.maxItemsToLoad;
              }

            })
              .catch(function (err) {
                toastr.error(localize.getLocalizedString("_ErrorLoadingHistory_"), err);
              })
              .finally(function () {
                //scope.$apply(function () {
                scope.isLoadingWrapper.isLoading = false;
                //});
              });
          }
        },
        template: '<div class="timeline-content-container relative-container">\
                        <div class="timeline-no-items-wrapper absolute-container" ng-if="!isLoadingWrapper.isLoading && items.length === 0">\
                            <div class="timeline-no-items-icon glyphicon glyphicon-book"></div>\
                            <div class="timeline-no-items-label" data-i18n="_NoHistory_"></div>\
                        </div>\
                        <div class="time-line-right-part">\
                            <timeline-item ng-repeat="item in items" model="item" bindonce="item">\
                                <div bo-switch="item.Type">\
                                    <meeting-timeline-item bo-switch-when="Meeting" model="item"></meeting-timeline-item>\
                                    <promotion-timeline-item bo-switch-when="Promotion" model="item"></promotion-timeline-item>\
                                    <visit-timeline-item bo-switch-when="Visit" customer="mainEntity" model="item"></visit-timeline-item>\
                                    <product-sold-timeline-item bo-switch-when="ProductSold" model="item"></product-sold-timeline-item>\
                                    <product-sold-timeline-item bo-switch-when="SubscriptionPurchased" model="item"></product-sold-timeline-item>\
                                    <product-purchased-timeline-item bo-switch-when="ProductPurchased" model="item"></product-purchased-timeline-item>\
                                    <service-performed-timeline-item bo-switch-when="ServicePerformed" model="item"></service-performed-timeline-item>\
                                    <voucher-used-timeline-item bo-switch-when="VoucherUsed" model="item"></voucher-used-timeline-item>\
                                    <lead-appeal-timeline-item bo-switch-when="LeadAppeal" model="item"></lead-appeal-timeline-item>\
                                    <price-quote-timeline-item bo-switch-when="PriceQuote" model="item"></price-quote-timeline-item>\
                                    <entity-note-reminder bo-switch-when="EntityNoteReminder" readonly="readonly" model="item" customer="mainEntity"></entity-note-reminder>\
                                </div>\
                            </timeline-item>\
                        </div>\
                    </div>'

      };
    }]);

  timelineModule.directive('timelineItem', [
    '$state', function ($state) {
      return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: {
          model: '='
        },
        template: require('./timeline.item.directive.html'),
        compile: function (telement, tattrs) {
          return function (scope) {
            scope.$watchOnce('model', function (newVal) {
              switch (newVal.Type) {
                case "Meeting":
                  scope.icon = "icon-calendar";
                  scope.meetingType = getMeetingType(newVal);
                  scope.goTo = function (model) {
                    $state.go('Calendar', {
                      date: moment(model.EngagementDate, SERVER_DATA_DATE_FORMAT).format('YY-MM-DD'),
                      meeting: model.Id
                    });
                  };

                  break;
                case "Promotion":
                  scope.icon = "icon-promote";
                  scope.lazyContent = true;
                  break;
                case "Visit":
                  scope.icon = "icon-tag-price";
                  if (newVal.Debt > 0) {
                    newVal.Type = 'Visit-Debt';
                    scope.icon = "icon-debt";
                  } else if (newVal.Debt < 0) {
                    scope.icon = "icon-debt-unlock";
                    newVal.Type = 'Visit-DebtUnlocked';
                  }

                  break;
                case "PriceQuote":
                case "LeadAppeal":
                  scope.icon = newVal.Status === "Approved" ? "icon-price-quote-approved" : "icon-price-quote";
                  break;
                //                    case "PriceQuoteApproved":
                //                        scope.icon = "icon-price-quote-approved";
                //                        break;
                case "ProductSold":
                  scope.icon = "icon-basket";
                  break;
                case "ProductPurchased":
                  scope.icon = "icon-basket";
                  break;
                case "ServicePerformed":
                  scope.icon = "icon-job";
                  break;
                case "VoucherUsed":
                  scope.icon = "icon-job";
                  break;
                case "EntityNoteReminder":
                  scope.icon = "icon-reminder";
                  break;
              }
            });
          };
        }
      };
    }]);


  function getMeetingType(model) {
    //0 = future, 1 = history, 2 = deleted
    return (model.IsDeactiveted) ? 2 : moment(model.EngagementDate, SERVER_DATA_DATE_FORMAT).isAfter(moment()) ? 0 : 1;
  }


  function isMeetingChanged(model) {
    return model.LocalizedCreatedOn != model.LocalizedLastUpdated;
  }


  timelineModule.directive('meetingTimelineItem', ['iCalService', function (iCalService) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        model: '='
      },
      link: function (scope, element, attrs) {

        scope.model.LocalizedDate = moment(scope.model.EngagementDate, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT + " " + HOURS_AND_MINUTES_FORMAT);
        scope.model.LocalizedCreatedOn = moment(scope.model.CreatedOn, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT + " " + HOURS_AND_MINUTES_FORMAT);
        scope.model.LocalizedLastUpdated = moment(scope.model.LastUpdated, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT + " " + HOURS_AND_MINUTES_FORMAT);
        scope.meetingType = function () {
          //0 = future, 1 = history, 2 = deleted
          return getMeetingType(scope.model);
        };
        scope.meetingChanged = function () {
          return isMeetingChanged(scope.model);
        };
        scope.imageUrl = function (path) {
          return baseUrl + path;
        };

        if (scope.model.Recurrence) {
          scope.recurrenceString = iCalService.rRuleToString(scope.model.Recurrence);
        }
      },
      template: '<div class="timeline-meeting-item">\
                        <div class="timeline-meeting-item-top-part">\
                            <span bo-if="meetingType() == 0" data-i18n="_FutureMeeting_"></span>\
                            <span bo-if="meetingType() == 1" data-i18n="_ScheduledAMeeting_"></span>\
                            <span bo-if="meetingType() == 2" data-i18n="_CanceledAMeeting_"></span>\
                            <span bo-if="meetingType() == 0" data-i18n="_ForDate_"></span>\
                            <span bo-if="meetingType() > 0" data-i18n="_OnDate_"></span>\
                            &nbsp;<span bo-bind="model.LocalizedDate"></span>&nbsp;<span ng-if="::recurrenceString">({{ ::recurrenceString }})</span>\
                            <span data-i18n="_WithEmployee_"></span>&nbsp;<span bo-bind="model.EmployeeName"></span>\
                        </div>\
                        <div class="timeline-meeting-item-scheduled-services-wrapper">\
                            <div bo-show="model.Services.length === 0" class="timeline-meeting-item-no-services">\
                                <span>* </span><span bo-if="true" data-i18n="_NoServicesDefined_"></span>\
                            </div>\
                            <div bo-show="model.Services.length !== 0">\
                                <div class="timeline-meeting-item-scheduled-service-wrapper" ng-repeat="service in model.Services" bindonce>\
                                    <div class="timeline-meeting-item-scheduled-service-text" bo-bind="service.ServiceName"></div>\
                                </div>\
                            </div>\
                            <div bo-show="model.Remarks" class="timeline-remarks">\
                                <div class="timeline-remarks-header"><span data-i18n="_Remarks_"></span><span>:</span></div>\
                                <div class="timeline-meeting-item-scheduled-service-text" bo-bind="model.Remarks"></div>\
                            </div>\
                            <div bo-show="model.CancelationReason">\
                                <div class="timeline-remarks-header"><span data-i18n="_CancellationReason_"></span><span>: </span><span bo-bind="model.CancelationReason"></span></div>\
                            </div>\
                        </div>\
                        <div class="timeline-item-bottom-part">\
                            <span> <span data-i18n="_Created_"></span> <span timeago="model.CreatedOn"></span><span data-i18n="_OnTime_"></span><span>{{::model.LocalizedCreatedOn}}</span></span>\
                            <span bo-if="meetingChanged()"> &nbsp;&nbsp;&nbsp;(<span data-i18n="_Updated_"></span> <span timeago="model.LastUpdated"></span><span data-i18n="_OnTime_"></span><span>{{::model.LocalizedLastUpdated}}</span>)</span>\
                      </div>'
    };
  }]);


  timelineModule.directive('promotionTimelineItem', function (Repository, $stateParams) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        model: '='
      },
      link: function (scope) {
        scope.baseUrl = baseUrl;
        scope.model.LocalizedDate = moment(scope.model.EngagementDate, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT + " " + HOURS_AND_MINUTES_FORMAT);
        scope.expand = function () {
          scope.model.loading = true;
          Repository.Custom("PromotionsRepository").expandPromotionPreview(scope.model.PromotionSendingReportId, $stateParams.customerId)
            .then(function (result) {
              scope.model.expanded = true;
              scope.model.PromotionContentPreview = scope.model.PromotionContentPreview || {};

              Object.assign(scope.model.PromotionContentPreview, result);
              delete scope.model.loading;
            }).catch(function (err) {
              toastr.error(err.Message);
            });
        };

      },
      template: '<div class="timeline-promotion-item">\
                        <div class="timeline-promotion-item-top-part">\
                            <span data-i18n="_PromotionSent_"></span>\
                            </span>&nbsp;<span ng-bind="model.PromotionContentPreview.PromotionSenderType"></span>&nbsp;<span ng-bind="model.PromotionContentPreview.PromotionName"></span>\
                        </div>\
                        <div class="timeline-promotion-preview">\
                           <img bo-src="model.PromotionContentPreview"/>\
                        </div>\
                        <div ng-if="!model.expanded && !model.loading" class="animate-opacity-appearance timeline-expand-wrapper">\
                          <span data-i18n="_ShowMessage_" ng-click="expand()" />&nbsp;<span class="icon icon-angle-double-down"></span>\
                        </div>\
                        <div class="timeline-promotion-preview">\
                            <div ng-bind="model.PromotionContentPreview.Message"></div>\
                        </div>\
                        <div class="timeline-item-bottom-part">\
                            <span timeago="model.EngagementDate"></span><span data-i18n="_OnTime_"></span><span>{{::model.LocalizedDate}}</span>\
                        </div>\
                   </div>'
    };
  });

  timelineModule.directive('visitTimelineItem', [
    'Repository', 'printerMediator', 'configuration', 'localize', '$state', function (Repository, printerMediator, configuration, localize, $state) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          model: '=',
          customer: '='
        },
        link: function (scope, element, attrs) {
          scope.imageUrl = function (path) {
            return baseUrl + path;
          };

          scope.model.LocalizedDate = moment(scope.model.EngagementDate, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT + " " + HOURS_AND_MINUTES_FORMAT);

          scope.imageToSourceAdapter = function (treatmentImage) {
            return baseUrl + treatmentImage.FilePath;
          };

          var configurations = configuration.get();
          scope.invoiceByMail = configurations.InvoiceSettings.TypeOfInvoice.Value === 'mail';

          scope.toggleSendByMail = function () {
            scope.sendByEmailMode = !scope.sendByEmailMode;
          };
        },
        template: '<div class="timeline-visit-item" bindonce="model">\
                        <div class="timeline-meeting-item-top-part">\
                            <div bo-if="model.TotalAmount > 0"><span data-i18n="_TheCustomerVisit_"></span><span bo-bind="model.TotalAmount"></span><span currency></span></div>\
                            <div bo-if="model.TotalAmount == 0"><span data-i18n="_TheCustomerJustVisit_"></div>\
                            <div bo-if="model.TotalAmount < 0"><span data-i18n="_TheCustomerGotARefundOf_"></span><span bo-bind="model.TotalAmount * (-1)"></span><span currency></span></div>\
                            <div bo-if="model.Debt < 0"><span data-i18n="_TheCustomerPaydOffDebt_"></span><span bo-bind="model.Debt * (-1)"></span><span currency></span></div>\
                            <div bo-if="model.Debt > 0"><span data-i18n="_TheCustomerAccumulatedDebt_"></span><span bo-bind="model.Debt"></span><span currency></span></div>\
                            <div class="timeline-item-top-purchases" bo-if="model.Subscriptions"><span data-i18n="_Subscriptions_"></span><span>:</span></div>\
                             <div class="timeline-purchased-products-wrapper" bo-if="model.Subscriptions">\
                                 <span ng-repeat="subscription in model.Subscriptions" class="timeline-visit-item-detail" bindonce>\
                                    <span data-i18n="_Purchased_"></span>&nbsp;<span bo-bind="subscription.Title"></span>\
                                </span>\
                            </div>\
                            <div class="timeline-purchased-products-wrapper" bo-if="model.PrePayment">\
                            <div bo-if="model.PrePayment < 0"></span><span bo-bind="model.PrePayment * (-1)"></span><span currency></span><span data-i18n="_TheCustomerPrePayed_"></span>&nbsp;<span bo-bind="model.PrePaymentTitle"> </span> </div>\
                            <div bo-if="model.PrePayment > 0"><span data-i18n="_TheCustomerUsedPrePayment_"> </span><span bo-bind="model.PrePayment"></span><span currency></span></div>\
                           </div>\
                             <div class="timeline-item-top-purchases" bo-if="model.SubscriptionUsages"><span data-i18n="_SubscriptionUsages_"></span><span>:</span></div>\
                             <div class="timeline-purchased-products-wrapper" bo-if="model.SubscriptionUsages">\
                                <span ng-repeat="usage in model.SubscriptionUsages" class="timeline-visit-item-detail" bindonce>\
                                    <span data-i18n="_UsedSubscription_"></span><span bo-bind="usage.Title"></span>\
                                </span>\
                            </div>\
                            <div class="timeline-item-top-purchases" bo-if="model.Products.length > 0 || model.CustomItems.length > 0"><span data-i18n="_Purchases_"></span><span>:</span></div>\
                            <div class="timeline-purchased-products-wrapper" bo-if="model.Products">\
                                <span ng-repeat="product in model.Products" class="timeline-visit-item-detail" bindonce>\
                                    <span bo-bind="product.Quantity"></span>\
                                    <span bo-bind="product.ProductLabel"></span>&nbsp;\
                                </span>\
                            </div>\
                            <div bo-if="model.CustomItems.length > 0 && model.Products.length > 0"></div>\
                            <div class="timeline-purchased-products-wrapper" bo-if="model.CustomItems">\
                                <span ng-repeat="customItem in model.CustomItems" class="timeline-visit-item-detail" bindonce>\
                                    <span bo-bind="customItem.Quantity"></span>\
                                    <span bo-bind="customItem.Label"></span>&nbsp;\
                                </span>\
                            </div>\
                            <div class="general-timeline-item-with-actions-actions">\
                                  <digital-invoice-short invoice-id="model.Id" entity-type="model.EntityType" expandable="true"/>\
                            </div>\
                            <div class="timeline-visit-item-treatment" bo-if="model.Treatment">\
                                <div class="timeline-item-top-purchases"><span data-i18n="_Services_"></span><span>:</span></div>\
                                <div class="timeline-visit-item-detail" ng-repeat="service in model.Treatment.Services" bindonce>\
                                    <div class="timeline-visit-item-detail-header">\
                                        <span bo-bind="service.ServiceName"></span>\
                                        <span bo-if="service.ProductsUsedInService.length > 0" data-i18n="_WithIngredients_"></span><span bo-show="service.ProductsUsedInService.length > 0" >:</span>\
                                        <span bo-if="service.ComposedServices.length == 0"><span data-i18n="_ByEmployee_"></span>&nbsp;<span bo-bind="service.EmployeeName"></span></span>\
                                        <div bo-if="service.ComposedServices">\
                                          <div ng-repeat="composedService in service.ComposedServices">\
                                            <span class="icon icon-arrow-down-left"></span>\
                                            <span bo-text="composedService.ServiceName"></span>\
                                            <span data-i18n="_ByEmployee_"></span>\
                                            <span bo-bind="composedService.EmployeeName"></span>\
                                          </div>\
                                        </div>\
                                    </div>\
                                    <div class="timeline-ingredient" ng-repeat="ingredient in service.ProductsUsedInService" bindonce>\
                                        <span class="glyphicon" right-to-left="glyphicon-chevron-right"></span>\
                                        <span bo-bind="ingredient.Quantity"></span> - <span bo-bind="ingredient.ProductLabel"></span>\
                                        <span bo-if="ingredient.CustomModelValue"><span data-i18n="_SubModel_"></span><span bo-bind="ingredient.CustomModelValue"></span></span>\
                                    </div>\
                                </div>\
                            </div>\
                             <div class="timeline-visit-item-treatment" bo-if="model.Remarks">\
                                <div class="timeline-item-top-purchases"><span data-i18n="_RemarksHeader_"></span><span>:</span></div>\
                                <div class="timeline-visit-item-detail" bo-text="model.Remarks">\
                                </div>\
                            </div>\
                            <div class="timeline-item-image-gallery" bo-if="model.Treatment.TreatmentImages">\
                                <image-gallery-view images="model.Treatment.TreatmentImages"\
                                to-image-adapter="imageToSourceAdapter"></image-gallery-view>\
                            </div>\
                        </div>\
                        <div class="timeline-item-bottom-part">\
                            <span timeago="model.EngagementDate"></span><span data-i18n="_OnTime_"></span><span>{{::model.LocalizedDate}}</span>\
                        </div>\
                        <div class="send-by-email-over absolute-container animate-opacity-appearance" ng-if="sendByEmailMode"><send-by-email-content send="sendInvoiceByMail" is-loading-wrapper="sendingInvoice" mask="true" \
                        close="toggleSendByMail" email-address="customer.EmailAddress"></send-by-email-content></div>\
                   </div>'
      };
    }]);


  timelineModule.directive('productSoldTimelineItem', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        model: '='
      },
      link: function (scope) {
        scope.imageUrl = function (path) {
          return baseUrl + path;
        };
        scope.model.LocalizedDate = moment(scope.model.EngagementDate, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT + " " + HOURS_AND_MINUTES_FORMAT);

        scope.toTitleCase = function (str) {
          return str ? str.toTitleCase() : "";
        };
      },
      template: '<div class="timeline-product-sold-item">\
                        <div class="timeline-product-sold-item-top-part">\
                            <span bo-text="toTitleCase(model.Customer.Name)"></span>\
                            <span data-i18n="_Purchased_"></span>&nbsp;\
                            <span bo-if="model.Type == \'SubscriptionPurchased\'" data-i18n="_SubscriptionFor_"></span>\
                            <span bo-text="model.Quantity"></span>&nbsp;\
                            <span data-i18n="_Units_"></span>&nbsp;\
                            <span bo-if="model.Type == \'SubscriptionPurchased\'" data-i18n="_ForPriceOf_"></span>&nbsp;\
                            <span bo-if="model.Type == \'SubscriptionPurchased\'" bo-text="model.Price"></span>\
                            <span bo-if="model.Type == \'SubscriptionPurchased\'" currency></span>\
                        </div>\
                        <div class="timeline-item-bottom-part">\
                            <span timeago="model.EngagementDate"></span><span data-i18n="_OnTime_"></span><span>{{::model.LocalizedDate}}</span>\
                        </div>\
                   </div>'
    };
  });


  timelineModule.directive('productPurchasedTimelineItem', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        model: '='
      },
      link: function (scope) {
        scope.imageUrl = function (path) {
          return baseUrl + path;
        };
        scope.model.LocalizedDate = moment(scope.model.EngagementDate, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT + " " + HOURS_AND_MINUTES_FORMAT);

        scope.toTitleCase = function (str) {
          return str ? str.toTitleCase() : "";
        };
      },
      template: '<div class="timeline-product-sold-item">\
                        <div class="timeline-product-sold-item-top-part">\
                            <span bo-text="model.Quantity"></span>&nbsp;\
                            <span data-i18n="_Units_"></span>&nbsp;\
                            <span data-i18n="_AddedToInventory_" ng-if="model.Quantity > 0"></span>\
                            <span data-i18n="_RemovedFromInventory_" ng-if="model.Quantity < 0"></span>&nbsp;\
                        </div>\
                        <div class="timeline-item-bottom-part" ng-if="model.RelatedInvoice">\
                            <div class="timeline-item-inventory-remarks"><span data-i18n="_Remarks_"></span><span>:</span></div>\
                            <span bo-text="model.RelatedInvoice"></span>&nbsp;\
                        </div>\
                        <div class="timeline-item-bottom-part">\
                            <span timeago="model.EngagementDate"></span><span data-i18n="_OnTime_"></span><span>{{::model.LocalizedDate}}</span>\
                        </div>\
                   </div>'
    };
  });


  timelineModule.directive('servicePerformedTimelineItem', [
    'printerMediator', function (printerMediator) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          model: '='
        },
        link: function (scope) {
          scope.imageUrl = function (path) {
            return baseUrl + path;
          };
          scope.model.LocalizedDate = moment(scope.model.EngagementDate, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT + " " + HOURS_AND_MINUTES_FORMAT);

          scope.toTitleCase = function (str) {
            return str ? str.toTitleCase() : "";
          };

          scope.printCopy = function () {

          };
        },
        template: '<div class="timeline-service-performed-sold-item">\
                        <div class="timeline-service-performed-sold-item-top-part">\
                            <div>\
                                <span bo-text="toTitleCase(model.Customer.Name)"></span>\
                                <span data-i18n="_UsedThisService_"></span>\
                                <span bo-show="model.Ingredients.length == 0">.</span>\
                                <span bo-show="model.Ingredients.length > 0" data-i18n="_WithIngredients_"></span><span bo-show="model.Ingredients.length > 0" >:</span>\
                            </div>\
                            <div class="timeline-visit-item-detail-header">\
                                <div class="timeline-ingredient" ng-repeat="ingredient in model.Ingredients" bindonce>\
                                    <span class="glyphicon" right-to-left="glyphicon-chevron-right"></span><span bo-bind="ingredient.Quantity"></span>&nbsp;<span bo-bind="ingredient.ProductLabel"></span>\
                                </div>\
                            </div>\
                        </div>\
                        <div class="timeline-item-bottom-part">\
                            <span timeago="model.EngagementDate"></span><span data-i18n="_OnTime_"></span><span>{{::model.LocalizedDate}}</span>\
                        </div>\
                   </div>'
      };
    }]);

  timelineModule.directive('voucherUsedTimelineItem', [
    'printerMediator', function (printerMediator) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          model: '='
        },
        link: function (scope) {
          scope.imageUrl = function (path) {
            return baseUrl + path;
          };
          scope.model.LocalizedDate = moment(scope.model.EngagementDate, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT + " " + HOURS_AND_MINUTES_FORMAT);

          scope.toTitleCase = function (str) {
            return str ? str.toTitleCase() : "";
          };

          scope.printCopy = function () {

          };
        },
        template: '<div class="timeline-service-performed-sold-item">\
                        <div class="timeline-service-performed-sold-item-top-part">\
                            <div>\
                                <span bo-text="toTitleCase(model.Customer.Name)"></span>\
                                <span data-i18n="_UsedThisVoucher_"></span>\
                                <span data-i18n="_RefNumber_"></span><span>:</span><span bo-text="model.RelatedInvoice"></span>\
                            </div>\
                            </div>\
                        <div class="timeline-item-bottom-part">\
                            <span timeago="model.EngagementDate"></span><span data-i18n="_OnTime_"></span><span>{{::model.LocalizedDate}}</span>\
                        </div>\
                   </div>'
      };
    }]);

  timelineModule.directive('leadAppealTimelineItem', [
    'printerMediator', function (printerMediator) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          model: '='
        },
        link: function (scope) {
          scope.imageUrl = function (path) {
            return baseUrl + path;
          };
          scope.model.LocalizedDate = moment(scope.model.EngagementDate, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT + " " + HOURS_AND_MINUTES_FORMAT);

          scope.toTitleCase = function (str) {
            return str ? str.toTitleCase() : "";
          };

          scope.printCopy = function () {

          };
        },
        template: '<div class="timeline-service-performed-sold-item">\
                          <div class="timeline-service-performed-sold-item-top-part">\
                              <div>\
                                <span data-i18n="_LeadRegistered_"></span>\
                                <span bo-show="model.ArrivalSource.length > 0"> - </span>\
                                <span bo-text="toTitleCase(model.ArrivalSource)"></span>\
                              </div>\
                              </div>\
                              <div class="timeline-item-bottom-part">\
                                <span ng-repeat="tag in model.Tags" bindonce>\
                                <span bo-text="tag"></span>\
                                </span>\
                            </div>\
                          <div class="timeline-item-bottom-part">\
                              <span timeago="model.EngagementDate"></span><span data-i18n="_OnTime_"></span><span>{{::model.LocalizedDate}}</span>\
                          </div>\
                     </div>'
      };
    }]);

  timelineModule.directive('priceQuoteTimelineItem', [
    '$modal', 'Repository', 'localize', 'stateManager', '$stateParams',
    '$state', '$window', 'priceQuoteContextResolver', 'contextManager',
    function ($modal, Repository, localize, stateManager, $stateParams, $state, $window, priceQuoteContextResolver, contextManager) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          model: '='
        },
        require: '^timeline',
        link: function (scope, element, attrs, timelineCtrl) {
          scope.edit = loadPriceQuote;
          scope.duplicate = function () {
            loadPriceQuote(true);
          };
          scope.model.LocalizedDate = moment(scope.model.EngagementDate, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT + " " + HOURS_AND_MINUTES_FORMAT);

          scope.cancel = function () {
            scope.isCancelling = true;
            Repository.Custom("PriceQuote").cancel(scope.model.Id).then(function (clientPriceQuote) {
              toastr.success(localize.getLocalizedString("_PriceQuoteCancelled_"));
              timelineCtrl.replaceModel(scope.model, clientPriceQuote);
              $.sessionStorage.remove('priceQuote');
              scope.isCancelling = false;
              scope.$digestIfNeeded();
            }).catch(function (err) {
              toastr.error(localize.getLocalizedString("_ErrorCancelling_"), err);

              scope.isCancelling = false;
              scope.$digestIfNeeded();
            });

          };

          scope.approve = approvePriceQuote;
          scope.currentAndHistory = [];
          scope.$watchOnce('model', function (priceQuote) {
            if (priceQuote.Status === 'Approved') {
              scope.hideHistory = true;
              scope.toggleHistory = function () {
                scope.hideHistory = !scope.hideHistory;
              };
            }

            scope.currentAndHistory.push(priceQuote);
            if (priceQuote.History) {
              angular.forEach(priceQuote.History, function (historyPriceQuote) {
                scope.currentAndHistory.push(historyPriceQuote);
              });
            }
          });

          function loadPriceQuote(duplicate) {
            stateManager.setState('CashRegister', {
              meetingId: undefined,
              relativeDate: undefined,
              sessionId: undefined,
              customerId: undefined,
              priceQuoteId: scope.model.Id,
              duplicate: duplicate,
              defaultEmployeeId: undefined
            }, false, false);
          }

          function priceQuoteToHistoryItem(priceQuote) {
            var historyItem = {};
            historyItem.imagePath = baseUrl + priceQuote.Image;
            historyItem.title = ("" + priceQuote.TotalAmount).toMoney();
            historyItem.subTitle = moment(priceQuote.EngagementDate, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT + " " + HOURS_AND_MINUTES_FORMAT);
            historyItem.pdfPath = baseUrl + priceQuote.PdfPath;
            return historyItem;
          }

          function approvePriceQuote() {
            var opts = {
              backdrop: 'static',
              keyboard: true,
              backdropClick: false,
              template: require('../../modal-views/price-quote-approval/price.quote.approval.tpl.html'),
              controller: 'PriceQuoteApprovalController',
              resolve: {
                savedDelegate: function () {
                  return function ($modalInstance, timelineItem) {
                    $modalInstance.dismiss('cancel');
                    timelineCtrl.replaceModel(scope.model, timelineItem);
                  };
                },
                params: function () {
                  return {
                    PriceQuoteId: scope.model.Id
                  }
                }
              }
            };

            $modal.open(opts);
          }
        },
        template: '<div class="general-timeline-item-with-actions" bindonce="model">\
                        <div class="general-timeline-item-with-actions-top-part">\
                            <div class="inline-block">\
                                <span data-i18n="_SavedPriceQuote_" bo-if="model.Status == \'Saved\'"></span>\
                                <span data-i18n="_SentPriceQuote_" bo-if="model.Status == \'Sent\'"></span>\
                                <span data-i18n="_ApprovedPriceQuote_" bo-if="model.Status == \'Approved\'"></span>\
                                <span data-i18n="_CanceledPriceQuote_" bo-if="model.Status == \'Canceled\'"></span>\
                                <span data-i18n="_ClosedPriceQuote_" bo-if="model.Status == \'Closed\'"></span>\
                                <span data-i18n="_WithFinalPrice_"></span>\
                                <span bo-text="model.TotalAmount"></span><span currency></span>\
                                <span data-i18n="_WithInvoiceNumber_" bo-if="model.Status == \'Closed\'"></span>\
                                <span bo-if="model.Status == \'Closed\'" bo-bind="model.InvoiceNumber"></span>\
                                <span bo-if="model.Status == \'Closed\'" data-i18n="_WithFinalPrice_"></span>\
                                <span bo-if="model.Status == \'Closed\'" bo-bind="model.InvoiceAmount"></span>\
                                <span data-i18n="_By_" bo-if="model.Employee"></span>\
                                <span bo-text="model.Employee" bo-if="model.Employee"></span>\
                            </div>\
                        </div>\
                        <div class="timeline-price-quote-preview relative-container timeline-price-quote-history">\
                            <horizontal-history approve="approve" cancel="cancel" duplicate="duplicate" edit="edit" items="currentAndHistory"></horizontal-history>\
                        </div>\
                        <div class="timeline-item-bottom-part">\
                            <span timeago="model.EngagementDate"></span><span data-i18n="_OnTime_"></span><span>{{::model.LocalizedDate}}</span>\
                        </div>\
                   </div>'
      };
    }]);


  timelineModule.directive('entityNoteReminder',

    function ($modal, Repository, printerMediator, configuration, localize, confirmService, validator) {
      const ATTACH_TO_HEAD_ON_PRINT = `
<style>
    .logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
    }
    .logo {
        margin-top: 15px;
        height: 100px;
        width: auto;
        object-fit: contain;
    }
    .customer-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .top-flex-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
    .top-left-part {
        flex: 1;
        text-align: left;
    }
    .top-center-part {
        flex: 1;
        text-align: center;
    }
    .top-right-part {
        flex: 1;
        text-align: right;
        direction: rtl;
    }
    .header-field {
        font-size: 26px;
        font-weight: bold;
        color: rgb(75,75,75);
    }
    .subheader {
        font-size: 16px;
        color: rgb(97,105,116);
        font-weight: 300;
    }
</style>


<link rel="stylesheet" href="https://cdn.quilljs.com/1.3.6/quill.core.css">
<link rel="stylesheet" href="https://cdn.quilljs.com/1.3.6/quill.snow.css">
`;

      return {
        restrict: 'E',
        replace: true,
        scope: {
          model: '=',
          customer: '=',
          readonly: '='
        },
        require: '^timeline',
        link: function (scope, element, attrs, timelineCtrl) {
          scope.hide = function () {
            confirmService.confirm(localize.getLocalizedString('_ApproveDeleteMessage_'), null,
              function () {
                Repository.Custom("CustomTimelineItem").hide(scope.model.Id).then(function () {
                  timelineCtrl.replaceModel(scope.model, null);
                  toastr.success(localize.getLocalizedString("_CustomItemSuccesfulyHiden_"));
                }).catch(function (err) {
                  toastr.error(localize.getLocalizedString("_ErrorHidingCutomItem_"), err);
                });
              },
              function () {

              }
            );
          };
          scope.lock = function () {
            Repository.Custom("CustomTimelineItem").lock(scope.model.Id).then(function (result) {
              timelineCtrl.replaceModel(scope.model, result);
              toastr.success(localize.getLocalizedString("_CustomTimelineItemSuccesfulyLocked_"));
            }).catch(function (err) {
              toastr.error(localize.getLocalizedString("_ErrorLockingCutomItem_"), err);
            });
          }
          scope.model.LocalizedDate = moment(scope.model.EngagementDate, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT + " " + HOURS_AND_MINUTES_FORMAT);

          scope.$evalAsync(function () {
            element.find('.ui-resizable-handle').remove();
          });

          scope.edit = function () {
            scope.$emit('fire-toolbar-command', {
              name: "add",
              params: {
                noteToEdit: scope.model
              }
            });
          };

          scope.print = () => {
            getAdaptedContent().then(adaptedContent => {
              printerMediator.print(adaptedContent, true, ATTACH_TO_HEAD_ON_PRINT);
            });
          };

          const getAdaptedContent = () => {
            return new Promise((resolve) => {
              const configurations = configuration.get();
              const logoUrl = window.filesURI + configurations.BusinessDetails.BusinessImagePath;
              const businessName = configurations.BusinessDetails.BusinessName.Value;
              const businessPhone = configurations.BusinessDetails.BusinessPhoneNumber.Value;
              const businessEmail = configurations.BusinessDetails.BusinessEmailAddress.Value;
              const licensedDealerNumber = configurations.BusinessDetails.LicensedDealerNumber.Value;
              const category = scope.model.Category != null && scope.model.Category != '' && scope.model.Category != 'קטגוריה כללית' ? scope.model.Category : '';

              const img = new Image();
              img.onload = function () {
                const adaptedContent = `
                <div class="logo-container"> 
                    <img src="${logoUrl}" alt="Logo" class="logo" style="height: 100px; width: auto; margin-top: 15px;">
                </div>
            
                <div class="customer-title">
                    <div class="top-flex-container">
                        <div class="top-left-part">
                            <table class="no-border-table">
                                <tr>
                                    <td>
                                        <div class="subheader">${businessName}</div>
                                        <div class="subheader">${businessPhone}</div>
                                        <div class="subheader">${businessEmail}</div>
                                        <div class="subheader">${licensedDealerNumber}</div>
                                    </td>
                                </tr>
                            </table>
                        </div>
            
                        <div class="top-center-part">
                            <div class="header-field">${category}</div>
                            <div class="subheader">${scope.model.LocalizedDate}</div>
                        </div>
            
                        <div class="top-right-part">
                            <table class="no-border-table">
                                <tr>
                                    <td>
                                        <div class="subheader">${scope.customer.FirstName + ' ' + scope.customer.LastName}</div>
                                        <div class="subheader">${scope.customer.MobileFirst}</div>
                                        <div class="subheader">${scope.customer.EmailAddress != null ? scope.customer.EmailAddress : ''}</div>
                                        <div class="subheader">${scope.customer.MembershipId != null ? localize.getLocalizedString("_MembershipNum_") + ': ' + scope.customer.MembershipId : ''}</div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            
                <div style="height: fit-content; background-repeat: no-repeat;">
                    ${scope.model.Content}
                </div>`;


                resolve(adaptedContent);
              };

              img.onerror = function () {
                console.error("Failed to load logo image.");
                resolve(""); // Provide fallback HTML or handle error as needed
              };

              img.src = logoUrl;
            });
          }

          scope.sendByEmail = () => {
            confirmService.prompt(
                /*title*/localize.getLocalizedString('_SendByMail_'),
                /*subtitle*/'',
                /*placeholder*/localize.getLocalizedString('_Email_'),
                /*onOk*/function (emailValue) {
                const options = {
                  margin: [0.5, 0.5, 0.5, 0.5], // [top, left, bottom, right] margins in inches
                  filename: 'document.pdf',
                  image: { type: 'jpeg', quality: 1 },
                  html2canvas: { scale: 3, useCORS: true },
                  jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
                };

                // Generate the PDF with the defined options
                // html2pdf().from(adaptedContent).set(options).save();
                const fileName = scope.customer.FirstName + ' ' + scope.customer.LastName + "_" + moment(scope.model.EngagementDate).format(SHORT_DATE_FORMAT) + ".pdf";
                getAdaptedContent().then(adaptedContent => {
                  let finalContent = `<html><head>${ATTACH_TO_HEAD_ON_PRINT}</head><body>${adaptedContent}</body></html>`;
                  html2pdf().from(finalContent).set(options).toPdf().outputPdf('blob')
                    .then(function (pdfBlob) {
                      Repository.Custom("CustomTimelineItem").emailPdf(pdfBlob, emailValue, fileName)
                        .then(function () {
                          toastr.success(localize.getLocalizedString("_MessageSent_"));
                        }).catch(function (err) {
                          toastr.error(localize.getLocalizedString("_NotSent_"), err);
                        });
                    });
                });
              },
              function () {
              },
              function (value) {
                return validateEmail(value);
              }, /*DEFAULT VALUE*/scope.customer.EmailAddress,
                /*Ok text*/localize.getLocalizedString('_Send_'));


            const validateEmail = (value) => {
              let validationResult = value.length > 0;
              if (validationResult) {
                for (let email of value.split(',')) {
                  validationResult = validator.email.validate(email);
                  if (!validationResult) {
                    break;
                  }
                }
              }


              return !validationResult ? localize.getLocalizedString('_WrongEmail_') : null;
            };
          };
        },
        template: '<div class="custom-entity-note" bindonce="model">\
                        <div class="general-timeline-item-with-actions-top-part">\
                            <div class="inline-block">\
                                <span data-i18n="_AddedGeneralInformation_"></span>\
                            </div>\
                            <div class="general-timeline-item-with-actions-actions" ng-if="!readonly">\
                                  <div class="timeline-action-icon icon icon-print print-background-color" ng-click="print()" tippy="_Print_"></div>\
                                  <div class="timeline-action-icon icon icon-email-mail-streamline submit-background-color" ng-click="sendByEmail()" tippy="_SendByMail_"></div>\
                                  <div class="timeline-action-icon icon icon-stamp-streamline2 lock-action" ng-if="!model.Locked" ng-click="lock()" tippy="_Lock_"></div>\
                                  <div class="timeline-action-icon icon icon-delete-garbage-streamline warning-mode"  ng-if="!model.Locked" ng-click="hide()" tippy="_Delete_"></div>\
                                  <div class="timeline-action-icon icon icon-edit"  ng-if="!model.Locked" ng-click="edit()" tippy="_Edit_"></div>\
                            </div>\
                        </div>\
                        <div class="entity-note-reminder-content" bo-html="model.Content">\
                        </div>\
                        <div class="timeline-item-bottom-part">\
                            <span timeago="model.EngagementDate"></span><span data-i18n="_OnTime_"></span><span>{{::model.LocalizedDate}}</span>\
                            <span bo-if="model.EmployeeName"> <span data-i18n="_By_"></span>&nbsp;<span bo-bind="model.EmployeeName"></span></span>\
                        </div>\
                   </div>'
      };
    });
}());
