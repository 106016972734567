import './user.settings.modal.less'
import {extractError} from "@tomeravni/easybizy-js-common/errors";
import {getSafely, isNonEmptyString} from "@tomeravni/easybizy-js-common/common";

angular.module('easybizy.easybizyModalViewsModel').controller('UserSettingsModalViewController',
  function ($scope, $modalInstance, localize, Repository, confirmService, configuration, employees, loggedInUser) {

    $scope.presetFieldsToValidate = {};
    $scope.validateActionWrapper = {};

    $scope.languages = [
      {name: localize.getLocalizedString('_BusinessDefault_'), value: null},
      {name: localize.getLocalizedString('_Hebrew_'), value: 'hebrew'},
      {name: localize.getLocalizedString('_English_'), value: 'english'}
    ];

    $scope.employees = [];
    $scope.selectedEmployee = {};
    employees.resolve($scope.employees, $scope.selectedEmployee);
    $scope.originalEmployeeId = $scope.selectedEmployee.selected?.id;

    let personalLanguage = getSafely(['personalConfigurations', 'language'], loggedInUser);
    let selectedLang = $scope.languages[0];
    if (isNonEmptyString(personalLanguage)) {
      selectedLang = $scope.languages.find(lang => lang.value === personalLanguage) || selectedLang;
    }

    $scope.selectedLanguage = {selected: selectedLang};

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.save = () => {
      //update default employee if changed
      if($scope.selectedEmployee.selected?.id > 0 && $scope.originalEmployeeId !== $scope.selectedEmployee.selected?.id) {
        Repository.Custom('Configurations').updateDefaultEmployee($scope.selectedEmployee.selected?.id).then(() => {
        }).catch((error) => {
          toastr.error(extractError(error));
        })
      }

      //update language
      Repository.Custom('Configurations').updatePersonalConfigurations({
        language: $scope.selectedLanguage.selected.value
      }).then(() => {
        toastr.success(localize.getLocalizedString('_EntitySavedSuccessfully_'));
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        $scope.cancel();
      }).catch((error) => {
        toastr.error(extractError(error));
        return error;
      })
    }
  });

