import {isNonEmptyArray} from '@tomeravni/easybizy-js-common/common';
import * as _ from 'underscore';

(function () {
  angular.module('easybizy.calendar').directive('calendarColumnMeetingsHolder', [
    'calendarMetadata', 'calendarStore', '$timeout', '$rootScope', 'calendarColumns', 'meetingDrawerHelper', 'localize', 'confirmService',
    function (calendarMetadata, calendarStore, $timeout, $rootScope, calendarColumns, meetingDrawerHelper, localize, confirmService) {
      var MINIMUM_PX_TO_DRAG = 30;
      return {
        restrict: 'E',
        replace: true,
        scope: {
          column: '='
        },
        link: function (scope, element) {
          var meetingScroller = $('.calendar-days-content-scroller');
          let cancelDelegate = calendarStore.getMeetingsByDate(scope.column).then(render);
          $timeout(function () {
            setLoading(true);
          }, 700);

          var cellHeight, eachMinuteHeight;
          var cellInMinutes = calendarMetadata.minutesBetweenMeetings;
          var beginningOfDay = scope.column.date.clone().hour(calendarMetadata.startTime.hour()).minute(calendarMetadata.startTime.minute()).seconds(0);

          calendarStore.on('meeting-changed', meetingChanged);

          var cancelSearchMeeting = null;

          scope.$on('$destroy', onDestroy);

          const scrollContainer = document.querySelector('.calendar-content-sticky');
          let relativeScrollOffset = 0;

          function meetingChanged(event) {
            var employeeId = scope.column.employee && scope.column.employee.id;
            var isEventRelatedToColumn = false;
            if (event.dateTime === scope.column.formattedDate && (!employeeId ||
                (!event.meeting.originalMeeting.CompositeAdapters && event.meeting.originalMeeting.EmployeeId === employeeId))
              && (!scope.column.room || event.meeting.originalMeeting.RoomId === scope.column.room.id)
            ) {
              isEventRelatedToColumn = true;
            }

            if (employeeId && !isEventRelatedToColumn) {
              // Handle composite meeting event for current column state.
              if (doesMeetingHasMatchedComposedEmployee(event.meeting.originalMeeting.CompositeAdapters,
                employeeId)) {
                isEventRelatedToColumn = true;
              }

              // Handle composite for previous meeting.
              if (isEventRelatedToColumn || (event.type === 'meeting-removed' && event.meeting.fromColumn &&
                event.meeting.fromColumn.employee.id === scope.column.employee.id)) {
                isEventRelatedToColumn = true;
              }
            }

            if (!isEventRelatedToColumn) {
              return;
            }

            scope.$evalAsync(() => {
              clearCancelDelegate();
              cancelDelegate = calendarStore.getMeetingsByDate(scope.column).then((res) => render(calendarStore.forceMeetingsUniqueness(res)));
            });
          }

          function doesMeetingHasMatchedComposedEmployee(compositeAdapters, employeeId) {
            for (var composedServiceMetadataId in compositeAdapters) {
              for (var composedServiceIdx in compositeAdapters[composedServiceMetadataId]) {
                if (compositeAdapters[composedServiceMetadataId][composedServiceIdx].EmployeeId === employeeId) {
                  return true;
                }
              }
            }

            return false;
          }

          function setLoading(isLoading) {
            if (isLoading) {
              if (!scope.meetings) {
                return $rootScope.$emit('calendar-column-drawing', isLoading);
              }
            } else {
              return $rootScope.$emit('calendar-column-drawing', isLoading);
            }

          }

          function render(meetings) {
            clearCancelDelegate();
            scope.meetings = meetings.slice();
            scope.meetings.sort(sortMeetings);
            reRender();

            // Handle selected meeting in case exists.
            if (calendarMetadata.stateMeeting) {
              var k_timeoutLength = 500;
              cancelSearchMeeting = $timeout(function () {
                cancelSearchMeeting = null;
                for (var meetingIdx in scope.meetings) {
                  var meeting = scope.meetings[meetingIdx];
                  if (meeting.MeetingId == calendarMetadata.stateMeeting) {
                    const meetingElement = element.find('.calendar-meeting:nth-child(' + (parseInt(meetingIdx) + 1) + ')');
                    meetingScroller.scrollTo(meetingElement, k_timeoutLength, {offset: {top: -20}});
                    setTimeout(function () {
                      meetingElement.addClass('animated rubberBand');
                    }, k_timeoutLength);

                  }
                }

              }, k_timeoutLength);
            }

          }

          function reRender(awaitedIdx) {
            cellHeight = calendarMetadata.actualCellHeight;
            // Wait Up to a second
            if (!cellHeight && (!awaitedIdx || awaitedIdx < 5)) {
              console.log('Waiting for cell height index: ', awaitedIdx);
              return $timeout(() => reRender(awaitedIdx ? ++awaitedIdx : 1), 200);
            }

            eachMinuteHeight = cellHeight / (cellInMinutes * 1.0);
            setLoading(false);
            var meetingsNotInRange = [];
            scope.meetings.forEach(function (meeting) {
              var diffInMins = meeting.StartTime.diff(beginningOfDay, 'm');
              if (diffInMins < 0) {
                return meetingsNotInRange.push(meeting);
              }

              meeting.top = diffInMins / calendarMetadata.minutesBetweenMeetings * cellHeight;
              meeting.height = eachMinuteHeight * (meeting.EndTime.diff(meeting.StartTime, 'm')) - 1;
              if (!calendarMetadata.showCustomerImagesOnMeetings || calendarColumns.visibleColumns.length > 7) {
                meeting.preventImage = true;
              }
            });


            meetingsNotInRange.forEach(function (meeting) {
              scope.meetings.remove(meeting);
            });

            setMeetingsWidth();


            scope.meetings.forEach(function (meeting) {
              if (meeting.reRender) {
                meeting.reRender()
              }
            });

            $timeout(function () {
              makeDraggable();
            }, 500);

          }

          var placeholderHeight, placeholderWidth, maxPageTop, startDragPosition, lastDragPosition;

          function makeDraggable() {
            if (window.isMobile && window.isMobile.android.tablet) {
              return;
            }

            var elementsToMakeDraggable = element.find('.calendar-meeting:not(.ui-draggable):not(.history-meeting):not(.disabled):not(.meeting--pending-payment)');
            elementsToMakeDraggable.draggable({
              containment: scrollContainer, // Undefined if not new calendar mode.
              zIndex: 101,
              delay: 100,
              helper: 'clone',
              drag: onDragWrapper(),
              start: function (event, ui) {
                if (scrollContainer) {
                  relativeScrollOffset = scrollContainer.scrollWidth - scrollContainer.offsetWidth;
                }

                $(this).addClass('based-dragged-meeting');
                calendarMetadata.draggingMeeting = true;
                var placeholder = document.querySelector('.calendar-days-placeholder td').getBoundingClientRect();
                placeholderHeight = placeholder.height;
                placeholderWidth = placeholder.width;
                maxPageTop = document.querySelector('.calendar-days-content-scroller').getBoundingClientRect().top;
                startDragPosition = eventToPositionIndexes(ui, event)
              },
              stop: function (event, ui) {
                calendarMetadata.draggingMeeting = false;
                $('.meeting-drag-over-meeting-container').removeClass('meeting-drag-over-meeting-container');
                const dragPositionIndexes = eventToPositionIndexes(ui, event);
                const meeting = angular.element(event.target).scope().meeting;
                cleanUpHovers();

                if (dragPositionIndexes.row < 1) {
                  return $timeout(function () {
                    calendarStore.revertMeetingToItsOriginal(meeting);
                  });
                }

                if (calendarMetadata.forceApprovalOnMeetingDrag) {

                  confirmService.confirm(localize.getLocalizedString('_ApproveMeetingUpdate_'),
                    null,
                    function () {
                      try {
                        handleDropPositionChanged(meeting, dragPositionIndexes);
                      } catch (e) {
                        // Nothing?
                        calendarStore.revertMeetingToItsOriginal(meeting);
                      }
                    },
                    function () {
                      calendarStore.revertMeetingToItsOriginal(meeting);
                    }
                  );
                } else {
                  try {
                    handleDropPositionChanged(meeting, dragPositionIndexes);
                  } catch (e) {
                    // Nothing?
                    calendarStore.revertMeetingToItsOriginal(meeting);
                  }
                }

                $(this).remove();
                // Clean up duplicated.
              },
              revert: function () {
                return lastDragPosition.row < 1;
              }
            })
              .resizable({
                handles: 's',
                containment: '.calendar-days-placeholder',
                minHeight: calendarMetadata.minutesBetweenMeetings * eachMinuteHeight * 0.5,
                grid: [0, (calendarMetadata.stepInMinutesBetweenMeetings * eachMinuteHeight)],
                start: function () {
                  $(this).addClass('resizing-meeting');
                  calendarMetadata.draggingMeeting = true;
                },
                resize: function (e, ui) {
                  var meetingLengthInMinutes =
                    Math.round((ui.size.height / eachMinuteHeight) / calendarMetadata.stepInMinutesBetweenMeetings) * calendarMetadata.stepInMinutesBetweenMeetings;
                  var meetingScope = angular.element(this).scope();
                  var meeting = meetingScope.meeting;
                  meeting.EndTime = meeting.StartTime.clone().add(meetingLengthInMinutes, 'm');
                  $(this).find('.meeting-times').text(meetingDrawerHelper.getMeetingTimeString(meeting));

                },
                stop: function (event) {
                  $(this).removeClass('resizing-meeting');
                  updateMeetingLength(angular.element(this).scope().meeting);
                  // This to prevent the popup for being opened.
                  setTimeout(() => {
                    calendarMetadata.draggingMeeting = false;
                  });
                }
              })
          }

          function onDragWrapper() {
            var throttledDragHandler = _.throttle(handleDrag, 150);
            return function (event, ui) {
              lastDragPosition = eventToPositionIndexes(ui, event);
              throttledDragHandler(this, ui, event);
            };

            function handleDrag(el, ui, event) {
              if (!calendarMetadata.draggingMeeting) {
                return;
              }

              try {
                var updatedTime = resolveTimeStringFromEvent(el, ui, event);
                $('.ui-draggable-dragging').find('.meeting-times').text(meetingDrawerHelper.getMeetingTimeString(updatedTime));
              } catch (e) {
                // Do nothing. simply ignore.
              }


              cleanUpHovers();
              if (lastDragPosition.row > 0) {
                $('.calendar-content-headers tr td:nth-child(' + lastDragPosition.col + ')').addClass('hovers-on');
                $('.calendar-hours-column tr:nth-child(' + lastDragPosition.row + ') td').addClass('hovers-on');
                $('.calendar-days-placeholder tbody tr:nth-child(' + lastDragPosition.row + ') td:nth-child(' + lastDragPosition.col + ') .calendar-cell-placeholder').addClass('meeting-drag-over-meeting-container');
              }
            }
          }

          function resolveTimeStringFromEvent(el, ui, event) {
            var dragPositionIndexes = eventToPositionIndexes(ui, event);
            var meeting = angular.element(el).scope().meeting;
            var columnAndStartTime = positionToCellStartTimeAndColumn(dragPositionIndexes);
            return {
              StartTime: columnAndStartTime.startTime,
              EndTime: columnAndStartTime.startTime.clone().add(meeting.Duration)
            }
          }

          function eventToPositionIndexes(ui, event) {

            var leftDis = event.clientX + (scrollContainer ? (scrollContainer.scrollLeft + relativeScrollOffset) : 0);// ui.offset.left;
            var topDis = ui.position.top;
            if (Math.abs(ui.position.top - ui.originalPosition.top) < MINIMUM_PX_TO_DRAG) {
              topDis = ui.originalPosition.top;
            }

            var leftIndex = Math.floor(leftDis / placeholderWidth);
            var topIndex = Math.floor(topDis / placeholderHeight) + 1 /*0 vs Html (1) index*/;

            if (topIndex === 0 && event.pageY > maxPageTop) {
              topIndex = 1;
            }

            leftIndex = Globalize.culture().isRTL ? calendarColumns.visibleColumns.length - leftIndex : leftIndex;
            return {row: topIndex, col: leftIndex};
          }

          function cleanUpHovers() {
            $('.calendar-content-headers td').removeClass('hovers-on');
            $('.calendar-hours-column td').removeClass('hovers-on');
            $('.meeting-drag-over-meeting-container').removeClass('meeting-drag-over-meeting-container');
          }

          function positionToCellStartTimeAndColumn(droppedPosition) {
            var row = droppedPosition.row, col = droppedPosition.col;
            var column = calendarColumns.visibleColumns[col - 1];
            var date = column.date.clone();
            var time = calendarMetadata.startTime.add(calendarMetadata.minutesBetweenMeetings * (row - 1), 'm');
            date.hour(time.hour());
            date.minute(time.minute());
            return {startTime: date, column: column};
          }

          function handleDropPositionChanged(meeting, droppedPosition) {
            var columnAndStartTime = positionToCellStartTimeAndColumn(droppedPosition);
            var column = columnAndStartTime.column;

            meeting.StartTime = columnAndStartTime.startTime;
            meeting.EndTime = meeting.StartTime.clone().add(meeting.Duration);
            if (column.employee) {
              if (meeting.originalMeeting.CompositeAdapters) {
                var fromEmployeeId = scope.column.employee.id;
                for (var compositeServiceId in meeting.originalMeeting.CompositeAdapters) {
                  var compositeServices = meeting.originalMeeting.CompositeAdapters[compositeServiceId];
                  compositeServices.forEach(function (composedService) {
                    if (composedService.EmployeeId === fromEmployeeId) {
                      composedService.EmployeeId = column.employee.EmployeeId;
                    }
                  });
                }
              } else {
                meeting.EmployeeId = column.employee.id;
              }
            }

            if (meeting.originalMeeting.CompositeAdapters) {
              meeting.CompositeAdapters = meeting.originalMeeting.CompositeAdapters;
            }

            if (column.room) {
              meeting.RoomId = column.room.id;
            }

            calendarStore.meetingPositionChanged(meeting)
              .catch(function (e) {
                $timeout(function () {
                  calendarStore.revertMeetingToItsOriginal(meeting);
                });

                if (e === 'employee-not-available') {
                  var employee = (column.employee || calendarMetadata.filteredEmployee) ||
                    _.first(calendarMetadata.employees, function (employee) {
                      return employee.id === meeting.EmployeeId
                    }) ||
                    {};
                  toastr.error(localize.getLocalizedString('_EmployeeNotAvailable_', employee['EmployeeName'] || employee.name));
                } else if (e && e.data && e.data.Message) {
                  toastr.error(localize.getLocalizedString(e.data.Message));
                } else {
                  toastr.error(localize.getLocalizedString('_ErrorUpdatingMeeting_'));
                }
              });
          }

          function updateMeetingLength(meeting) {
            if (meeting.originalMeeting.CompositeAdapters) {
              meeting.CompositeAdapters = meeting.originalMeeting.CompositeAdapters;
            }
            calendarStore.meetingPositionChanged(meeting)
              .catch(function (e) {
                if (e && e.Message) {
                  toastr.error(localize.getLocalizedString(e.Message));
                } else {
                  toastr.error(localize.getLocalizedString('_ErrorUpdatingMeeting_'));
                }

                return $timeout(function () {
                  calendarStore.revertMeetingToItsOriginal(meeting);
                });
              });
          }

          function setMeetingsWidth() {
            var currentBatch = [];
            scope.meetings.forEach(function (meeting) {
              delete meeting.width;
              delete meeting.left;
            });

            for (var idx = 1; idx < scope.meetings.length; idx++) {
              var previousMeeting = scope.meetings[idx - 1];
              var currentMeeting = scope.meetings[idx];

              if (previousMeeting.EndTime.toDate() - currentMeeting.StartTime.toDate() > 0) {
                if (currentBatch.indexOf(previousMeeting) < 0) {
                  currentBatch.push(previousMeeting);
                }

                currentBatch.push(currentMeeting);
              } else {
                setBatchWidth(currentBatch);
              }
            }

            setBatchWidth(currentBatch);
          }

          function setBatchWidth(batch) {
            if (batch.length === 0) {
              return;
            }


            var width = 100.0 / batch.length;
            batch.forEach(function (meeting, idx) {
              meeting.width = width + '%';
              if (idx > 0) {
                meeting.left = (width * idx) + '%';
              }
            });

            batch.length = 0;
          }

          function sortMeetings(a, b) {
            return a.StartTime.toDate() - b.StartTime.toDate();
          }

          function clearCancelDelegate() {
            if (cancelDelegate && cancelDelegate.cancel) {
              cancelDelegate.cancel();
            }

            cancelDelegate = null;
          }

          function onDestroy() {
            clearCancelDelegate();

            if (cancelSearchMeeting) {
              $timeout.cancel(cancelSearchMeeting);
            }

            calendarStore.off('meeting-changed', meetingChanged);
          }

        },
        template: '<div class="meetings-col-holder">\
                      <calendar-meeting ng-repeat="meeting in meetings track by meeting.DrawingUnique" meeting="meeting"></calendar-meeting>\
                  </div>'
      }
    }])
}());
