import {extractError} from "@tomeravni/easybizy-js-common/errors";
import {getSafely, isNonEmptyObject, isNonEmptyString} from "@tomeravni/easybizy-js-common/common";

const COUNTRY_AREA_CODE = '972';
const DEFAULT_PAYMENT_LINK_TEMPLATE = '__global_payment_link_v2';

(function () {
  angular.module('easybizy.configurations').directive('whatsappProps',
    function (Repository, localize, configuration) {
      return {
        restrict: 'E',
        replace: true,
        link: function (scope) {
          scope.newCustomerFieldsToValidate = {}; // just initialize fields to validate.
          scope.validateActionWrapper = {}; // this is an object to wrap an action, so the form validator can
          scope.whatsappProps = {
            paymentLinkTemplateName: DEFAULT_PAYMENT_LINK_TEMPLATE,
            sendPaymentLinkViaWhatsapp: false
          };

          scope.isLeadFlowEnabled = false;

          applyDefaultHealthDeclarationFormProps()
          let originalBotConfig;
          Promise.all([configuration.loggedInUserAsPromise, configuration.loadBotConfigurations()]).then((results) => {
            const [res, botConfig] = results;
            if (isNonEmptyObject(res.facebookProps)) {
              scope.facebookProps = {
                phone: res.facebookProps.businessPhoneNumber.replace(/^972/, '0'),
                whatsappPhoneId: res.facebookProps.whatsappPhoneId
              }
            }

            scope.isLeadFlowEnabled = isNonEmptyString(getSafely(['config', 'genericLeadFlowId'], botConfig));
            scope.leadsFlowProps = getSafely(['config', 'genericLeadCustomModifiers'], botConfig, {});
            scope.leadsFlowDefaults = getSafely(['genericLeadDefaults', 'SIGN_UP'], botConfig, {});

            if (isNonEmptyObject(res.whatsappProps)) {
              scope.whatsappProps = {
                paymentLinkTemplateName: DEFAULT_PAYMENT_LINK_TEMPLATE,
                sendPaymentLinkViaWhatsapp: false,
                ...res.whatsappProps
              }

              applyDefaultHealthDeclarationFormProps()
            }

            originalBotConfig = {
              userScopedConversation: !!botConfig.config?.userScopedConversation,
              useClosedConversationTemplatesOnChat: !!botConfig.config?.useClosedConversationTemplatesOnChat
            };

            Object.assign(scope.whatsappProps, originalBotConfig);
          }).catch((err) => {
            toastr.error(extractError(err));
          })

          let autoReplyTextField = {
            fieldName: 'autoReplyText',
            type: 'textarea',
            icon: "icon icon-auto-reply",
            placeholder: localize.getLocalizedString('_AutoReplyText_'),
            validation: "{'required': true, 'max-length': 200}"
          };

          let autoReplyOutsideVisitingHoursTextField = {
            fieldName: 'autoReplyOutsideVisitingHoursText',
            type: 'textarea',
            icon: "icon icon-auto-reply",
            placeholder: localize.getLocalizedString('_AutoReplyOutsideVisitingHoursText_'),
            validation: "{'required': true, 'max-length': 200}"
          };

          let paymentLinkTemplateTextField = {
            fieldName: 'paymentLinkTemplateName',
            type: 'textarea',
            icon: "icon icon-whatsapp",
            placeholder: localize.getLocalizedString('_PaymentLinkTemplateName_'),
            validation: "{'required': true, 'max-length': 50}"
          };


          const formOptions = [{name: localize.getLocalizedString("_None_"), value: null}];
          Repository.Custom('Forms').getForms().then((forms) => {
            forms.filter(x => !x.hidden).forEach(function (form) {
              formOptions.push({name: form.name, value: form._id});
            });
          }).catch((e) => toastr.error(extractError(e)))


          const defaultHealthDeclarationForm = {
            fieldName: 'defaultHealthDeclarationForm',
            type: 'combo',
            icon: "icon icon-forms",
            placeholder: localize.getLocalizedString('_DefaultHealthDeclarationForm_'),
            values: formOptions
          };

          scope.fields = [
            {
              fieldName: 'phoneNumberProxy',
              icon: "icon icon-sms",
              placeholder: localize.getLocalizedString('_WhatsappPhoneProxy_'),
              description: localize.getLocalizedString('_WhatsappPhoneProxyDescription_'),
              validation: "{'max-length': 12, 'min-length': 9, 'digits': true}"
            },
            {
              fieldName: 'autoReply',
              placeholder: localize.getLocalizedString('_AutoReply_'),
              icon: "icon icon-auto-reply",
              type: 'checkbox'
            },
            autoReplyTextField,
            {
              fieldName: 'autoReplyOutsideVisitingHours',
              placeholder: localize.getLocalizedString('_AutoReplyOutsideVisitingHours_'),
              icon: "icon icon-auto-reply",
              type: 'checkbox'
            },
            autoReplyOutsideVisitingHoursTextField,
            {
              fieldName: 'sendPaymentLinkViaWhatsapp',
              placeholder: localize.getLocalizedString('_SendPaymentLinkUsingWhatsapp_'),
              icon: "icon icon-whatsapp",
              type: 'checkbox'
            },
            paymentLinkTemplateTextField,
            {
              fieldName: 'noNearbySlotsAvailableMessage',
              type: 'textarea',
              icon: "icon icon-whatsapp",
              placeholder: localize.getLocalizedString('_NoNearbySlotsAvailableMessage_'),
              validation: "{'required': true, 'max-length': 200}"
            },
            {
              fieldName: 'clientSilentMode',
              placeholder: localize.getLocalizedString('_WAClientSilentMode_'),
              icon: "icon icon",
              type: 'checkbox'
            },
            {
              fieldName: 'userScopedConversation',
              placeholder: localize.getLocalizedString('_UserScopedConversation_'),
              description: localize.getLocalizedString('_UserScopedConversationDescription_'),
              icon: "icon icon-auto-reply",
              type: 'checkbox'
            },
            {
              fieldName: 'useClosedConversationTemplatesOnChat',
              placeholder: localize.getLocalizedString('_UseClosedConversationTemplatesOnChat_'),
              description: localize.getLocalizedString('_UseClosedConversationTemplatesOnChatDescription_'),
              icon: "icon icon-auto-reply",
              type: 'checkbox'
            },
            defaultHealthDeclarationForm
          ];

          scope.$watch('whatsappProps.autoReply', (newVal) => {
            autoReplyTextField.hidden = !newVal;
          })

          scope.$watch('whatsappProps.autoReplyOutsideVisitingHours', (newVal) => {
            autoReplyOutsideVisitingHoursTextField.hidden = !newVal;
          })

          scope.$watch('whatsappProps.sendPaymentLinkViaWhatsapp', (newVal) => {
            paymentLinkTemplateTextField.hidden = !newVal;
          })


          scope.save = () => {
            scope.isSaving = true;
            const {
              phoneNumberProxy,
              autoReply,
              autoReplyText,
              autoReplyOutsideVisitingHours,
              autoReplyOutsideVisitingHoursText,
              sendPaymentLinkViaWhatsapp,
              paymentLinkTemplateName,
              noNearbySlotsAvailableMessage,
              defaultHealthDeclarationForm,
              clientSilentMode
            } = scope.whatsappProps

            let patchWAConfigDelegate;
            if (isNonEmptyObject(originalBotConfig)) {
              if (Object.keys(originalBotConfig).some((key) => originalBotConfig[key] !== scope.whatsappProps[key])) {
                const toPatch = Object.keys(originalBotConfig).reduce((acc, key) => {
                  let toReturn = Object.assign({}, acc);
                  if (originalBotConfig[key] !== scope.whatsappProps[key]) {
                    toReturn[key] = scope.whatsappProps[key];
                  }

                  return toReturn;
                }, {})

                patchWAConfigDelegate = Repository.Custom('Configurations').patchBotConfigurations(toPatch);
              }
            }

            const cancelSaving = () => scope.$evalAsync(() => {
              scope.isSaving = false
            });

            Promise.all([patchWAConfigDelegate, Repository.Custom('Configurations').updateWhatsappDetails({
              phoneNumberProxy: adaptPhoneWithCountryAreaCode(phoneNumberProxy),
              autoReply: !!autoReply,
              autoReplyText,
              autoReplyOutsideVisitingHours: !!autoReplyOutsideVisitingHours,
              autoReplyOutsideVisitingHoursText,
              sendPaymentLinkViaWhatsapp: !!sendPaymentLinkViaWhatsapp,
              paymentLinkTemplateName,
              noNearbySlotsAvailableMessage,
              defaultHealthDeclarationFormId: defaultHealthDeclarationForm.value,
              clientSilentMode: !!clientSilentMode
            })]).then(() => {
              cancelSaving();
              toastr.success(localize.getLocalizedString('_EntitySavedSuccessfully_'))
            })
              .catch((err) => {
                cancelSaving();
                toastr.error(extractError(err.data))
              })
          }


          const adaptPhoneWithCountryAreaCode = (phoneNumber) => {
            if (!isNonEmptyString(phoneNumber)) {
              return phoneNumber;
            }

            let toReturn = phoneNumber;
            if (phoneNumber.startsWith('0')) {
              toReturn = toReturn.replace(/^0/i, COUNTRY_AREA_CODE);
            }

            return toReturn.trim();
          };

          function applyDefaultHealthDeclarationFormProps() {
            Object.defineProperty(scope.whatsappProps, 'defaultHealthDeclarationForm', {
              get() {
                const defaultVal = formOptions[0]
                if (isNonEmptyString(scope.whatsappProps.defaultHealthDeclarationFormId)) {
                  return formOptions.find(x => x.value === scope.whatsappProps.defaultHealthDeclarationFormId) || defaultVal;
                }

                return defaultVal
              },
              set(value) {
                const selectedValue = getSafely(['value'], value)
                if (isNonEmptyString(selectedValue)) {
                  scope.whatsappProps.defaultHealthDeclarationFormId = selectedValue;
                } else {
                  scope.whatsappProps.defaultHealthDeclarationFormId = null;
                }
              }
            })

          }
        },
        template: require('./whatsapp.props.directive.tpl.html')
      };
    });
}());
