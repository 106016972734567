import './online.scheduling.configurations.directive.less';

(function () {
  angular.module('easybizy.online.scheduling').directive('onlineSchedulingConfigurations', [
    '$timeout',
    'localize', 'timeConverter', function ($timeout, localize, timeConverter,configurations) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          model: '=',
          mediator: '=',
        },
        link: function (scope) {

          var getRandomId = function () {
            return 'input-' + Math.floor(Math.random() * 1000000);
          };

          scope.sections = [
            {
              name: null,
              fields: [
                {
                  fieldName: 'OnlineSchedulingAvailable',
                  name: localize.getLocalizedString('_OnlineSchedulingAvailable_'),
                  type: 'checkbox'
                }
              ]
            },
            {
              name: localize.getLocalizedString('_Booking_'),
              fields: [
                {
                  fieldName: 'AllMeetingNeedsApproval',
                  name: localize.getLocalizedString('_AllMeetingsRequireApproval_'),
                  type: 'checkbox'
                },
                {
                  fieldName: 'MinimumTimeBeforeMeeting',
                  name: localize.getLocalizedString('_MinimumTimeBeforeMeeting_'),
                  type: 'combo',
                  values: [
                    {
                      name: localize.getLocalizedString('_AnyTimeBefore_'),
                      value: 0
                    }
                  ].concat([30, 60, 120, 180, 240, 360, 720, 1440, 1440 * 2, 1440 * 3, 1440 * 4, 1440 * 5, 1440 * 6, 1440 * 7, 1440 * 14, 1440 * 30].map(function (value) {
                    return {
                      name: timeConverter.secondsToString(value * 60),
                      value: value
                    };
                  }))
                },
                {
                  fieldName: 'MaximumMeetingTimeFromNow',
                  name: localize.getLocalizedString('_MaximumMeetingTimeFromNow_'),
                  type: 'combo',
                  values: [
                    {
                      name: localize.getLocalizedString('_AnyTimeInTheFuture_'),
                      value: 0
                    }
                  ].concat([1440 * 7, 1440 * 10, 1440 * 14, 1440 * 21, 1440 * 30, 1440 * 30 * 2, 1440 * 30 * 3, 1440 * 30 * 4, 1440 * 30 * 5, 1440 * 30 * 6, 1440 * 30 * 12].map(function (value) {
                    return {
                      name: timeConverter.secondsToString(value * 60),
                      value: value
                    };
                  }))
                },
                {
                  fieldName: 'MaxFutureMeetingsForCustomer',
                  name: localize.getLocalizedString('_MaxFutureMeetingsForCustomer_'),
                  type: 'combo',
                  values: [
                    {
                      name: localize.getLocalizedString('_AnyAmount_'),
                      value: 0
                    }
                  ].concat([1, 2, 3, 4, 5, 6, 7, 8, 9].map(function (value) {
                    return {
                      name: value,
                      value: value
                    };
                  }))
                },
                {
                  fieldName: 'EnableNewCustomers',
                  name: localize.getLocalizedString('_EnableNewCustomers_'),
                  type: 'checkbox'
                },
                {
                  fieldName: 'EnableCustomersToWriteRemarks',
                  name: localize.getLocalizedString('_EnableCustomersToWriteRemarks_'),
                  type: 'checkbox'
                }
                // TODO
                // {
                //   fieldName: 'ForceApprovalOnEveryService',
                //   name: localize.getLocalizedString('_ApprovalForEveryService_'),
                //   type: 'checkbox'
                // },
                // TODO
                // {
                //   fieldName: 'ForceCreditCardOnEveryService',
                //   name: localize.getLocalizedString('_CreditCardForEveryService_'),
                //   type: 'checkbox'
                // },
              ]
            },
            {
              name: localize.getLocalizedString('_Strategy_'),
              fields: [
                {
                  fieldName: 'PreferMostBusyEmployee',
                  name: localize.getLocalizedString('_PreferMostBusyEmployee_'),
                  type: 'checkbox'
                },
                {
                  fieldName: 'CustomOptionSuggestionsStrategy',
                  name: localize.getLocalizedString('_CustomOptionSuggestionsStrategy_'),
                  type: 'combo',
                  values: [
                    {
                      name: localize.getLocalizedString('_WhateverAvailable_'),
                      value: 'None'
                    },
                    {
                      name: localize.getLocalizedString('_OnlyAllowOptionsNearbyMeetings_'),
                      value: 'OnlyAllowOptionsNearbyMeetings'
                    }, {
                      name: localize.getLocalizedString('_OnlyAllowOptionsNearbyMeetingsAndEdge_'),
                      value: 'OnlyAllowOptionsNearbyMeetingsAndEdge'
                    }, {
                      name: localize.getLocalizedString('_OnlyAllowOptionsNearbyMeetingsAndEdgeIfDayHasNoMeeting_'),
                      value: 'OnlyAllowOptionsNearbyMeetingsAndEdgeIfDayHasNoMeeting'
                    }
                  ]
                },
                {
                  fieldName: 'MinimumTimeSkippingBetweenOptions',
                  name: localize.getLocalizedString('_MinimumTimeSkippingBetweenOptions_'),
                  type: 'combo',
                  values: [
                    {
                      name: localize.getLocalizedString('_Default_'),
                      value: 0
                    }
                  ].concat([5, 10, 15, 20, 30, 45, 60, 75, 90, 105, 120, 180].map(function (value) {
                    return {
                      name: timeConverter.secondsToString(value * 60),
                      value: value
                    };
                  }))
                },
                {
                  fieldName: 'ConstantGapBetweenMeetings',
                  name: localize.getLocalizedString('_ConstantGapBetweenMeetings_'),
                  type: 'combo',
                  values: [
                    {
                      name: localize.getLocalizedString('_None_'),
                      value: 0
                    }
                  ].concat([5, 10, 15, 20, 30, 45, 60].map(function (value) {
                    return {
                      name: timeConverter.secondsToString(value * 60),
                      value: value
                    };
                  }))
                }]
            },
            {
              name: localize.getLocalizedString('_Credit_'),
              fields: [
                {
                  fieldName: 'AllMeetingNeedsCreditToken',
                  name: localize.getLocalizedString('_AllMeetingNeedsCreditToken_'),
                  type: 'checkbox'
                },
                {
                  fieldName: 'CustomersRequiredForCreditOnBooking',
                  name: localize.getLocalizedString('_CustomersRequireForCreditOnBooking_'),
                  type: 'combo',
                  values: [
                    {
                      name: localize.getLocalizedString('_AllCustomers_'),
                      value: 'AllCustomers'
                    },
                    {
                      name: localize.getLocalizedString('_NewCustomersOnly_'),
                      value: 'NewCustomersOnly'
                    }, {
                      name: localize.getLocalizedString('_NewCustomersOrMarked_'),
                      value: 'NewCustomersOrMarked'
                    }, {
                      name: localize.getLocalizedString('_AllCustomersButMarked_'),
                      value: 'AllCustomersButMarked'
                    }, {
                      name: localize.getLocalizedString('_OnlyMarkedCustomers_'),
                      value: 'OnlyMarkedCustomers'
                    }
                  ]
                },
                {
                  fieldName: 'CreditPaymentOptionOnBooking',
                  name: localize.getLocalizedString('_CreditPaymentOptionOnBooking_'),
                  type: 'combo',
                  values: [
                    {
                      name: localize.getLocalizedString('_TokenOnly_'),
                      value: 'TokenOnly'
                    },
                    {
                      name: localize.getLocalizedString('_FixedPrePaymentPrice_'),
                      value: 'FixedPrePaymentPrice'
                    }, {
                      name: localize.getLocalizedString('_FixedPrePaymentPercentage_'),
                      value: 'FixedPrePaymentPercentage'
                    }, {
                      name: localize.getLocalizedString('_CustomForEachService_'),
                      value: 'CustomForEachService'
                    }, {
                      name: localize.getLocalizedString('_OnlineServicePrice_'),
                      value: 'OnlineServicePrice'
                    }, {
                      name: localize.getLocalizedString('_OnlineServicePriceAsPrePayment_'),
                      value: 'OnlineServicePriceAsPrePayment'
                    }
                  ]
                },
                {
                  fieldName: 'CreditFixedPrePaymentValue',
                  name: localize.getLocalizedString('_CreditFixedPrePaymentValue_'),
                  type: 'input'
                },
                {
                  fieldName: 'AlertAbandonedCartNumber',
                  name: localize.getLocalizedString('_AlertAbandonedCartNumber_'),
                  type: 'input'
                },
                {
                  fieldName: 'SupportsGiftCardOnPaymentLink',
                  name: localize.getLocalizedString('_SupportsGiftCardOnPaymentLink_'),
                  type: 'checkbox'
                },
                {
                  fieldName: 'AvoidSettingFullPaymentAsPrePaymentOnCancellation',
                  name: localize.getLocalizedString('_AvoidSettingFullPaymentAsPrePaymentOnCancellation_'),
                  type: 'checkbox'
                }
              ]
            },
            {
              name: localize.getLocalizedString('_OwnerNotifications_'),
              fields: [
                {
                  fieldName: 'OwnerMobileNumbers',
                  name: localize.getLocalizedString('_OwnerPhoneNumbers_'),
                  placeholder: localize.getLocalizedString('_OwnerPhoneNumbersPlaceholder_'),
                  type: 'input'
                },
                {
                  fieldName: 'SendPushNotificationToOwnerOnMeetingCreation',
                  name: localize.getLocalizedString('_SendPushNotificationToOwnerOnMeetingCreation_'),
                  type: 'checkbox'
                },
                {
                  fieldName: 'SendPushNotificationToOwnerOnlyOnMeetingChange',
                  name: localize.getLocalizedString('_SendPushNotificationToOwnerOnlyOnMeetingChange_'),
                  type: 'checkbox'
                },
                {
                  fieldName: 'SendSMSToOwnerOnMeetingCreation',
                  name: localize.getLocalizedString('_SendSMSToOwnerOnMeetingCreation_'),
                  type: 'checkbox'
                },
                {
                  fieldName: 'FbPixel',
                  name: localize.getLocalizedString('_FbPixel_'),
                  type: 'input'
                },
                {
                  fieldName: 'ForceLanguage',
                  name: localize.getLocalizedString('_ForceLanguage_'),
                  type: 'combo',
                  values: [
                    {
                      name: localize.getLocalizedString('_None_'),
                      value: null
                    },
                    {
                      name: localize.getLocalizedString('_Hebrew_'),
                      value: 'he-IL'
                    }, {
                      name: localize.getLocalizedString('_English_'),
                      value: 'en-US'
                    }
                  ]
                },
                {
                  fieldName: 'ForwardUrlAfterSchedule',
                  name: localize.getLocalizedString('_ForwardUrlAfterSchedule_'),
                  type: 'input'
                }
              ]
            },
            {
              name: localize.getLocalizedString('_Services_'),
              fields: [
                {
                  fieldName: 'HideServicePrices',
                  name: localize.getLocalizedString('_HideServicePrices_'),
                  type: 'checkbox'
                },
                {
                  fieldName: 'HideServiceEndTime',
                  name: localize.getLocalizedString('_HideServiceEndTime_'),
                  type: 'checkbox'
                },
                {
                  fieldName: 'PickMultipleServices',
                  name: localize.getLocalizedString('_PickMultipleServices_'),
                  type: 'checkbox'
                },
                {
                  fieldName: 'ShowServiceCategories',
                  name: localize.getLocalizedString('_ShowServiceCategories_'),
                  type: 'checkbox'
                },
                {
                  fieldName: 'ShowServiceDescription',
                  name: localize.getLocalizedString('_ShowServiceDescription_'),
                  type: 'checkbox'
                }
              ]
            },
            {
              name: localize.getLocalizedString('_Cancellations_'),
              fields: [
                {
                  fieldName: 'MinimumHoursBeforeEditingMeeting',
                  name: localize.getLocalizedString('_MinimumTimeToCancel_'),
                  type: 'combo',
                  values: [
                    {
                      name: localize.getLocalizedString('_DoNotAllowCancellation_'),
                      value: 0
                    }
                  ].concat([1, 2, 3, 4, 6, 12, 24, 24 * 2, 24 * 3, 24 * 4, 24 * 7].map(function (value) {
                    return {
                      name: timeConverter.secondsToString(value * 60 * 60),
                      value: value
                    };
                  }))
                }
              ]
            },
            {
              name: localize.getLocalizedString('_Employees_'),
              fields: [
                {
                  fieldName: 'AllowPickingEmployees',
                  name: localize.getLocalizedString('_AllowPickingEmployees_'),
                  type: 'checkbox'
                },
                {
                  fieldName: 'ForcePickingEmployees',
                  name: localize.getLocalizedString('_ForcePickingEmployees_'),
                  type: 'checkbox'
                },
                {
                  fieldName: 'ForceSameEmployeeOnMultiServicesIfSelected',
                  name: localize.getLocalizedString('_ForceSameEmployeeOnMultiServicesIfSelected_'),
                  type: 'checkbox'
                },
                {
                  fieldName: 'ShowEmployeesAvatar',
                  name: localize.getLocalizedString('_ShowEmployeesAvatar_'),
                  type: 'checkbox'
                },
                {
                  fieldName: 'SetAvailabilityByRooms',
                  name: localize.getLocalizedString('_SetAvailabilityByRooms_'),
                  type: 'checkbox'
                }
              ]
            },
            {
              name: localize.getLocalizedString('_Notifications_'),
              fields: [
                {
                  fieldName: 'SendSchedulingSms',
                  name: localize.getLocalizedString('_SendConfirmationSMS_'),
                  type: 'checkbox'
                },
                {
                  fieldName: 'ShowEmployeeNameOnSchedulingSms',
                  name: localize.getLocalizedString('_ShowEmployeeNameOnSchedulingSms_'),
                  type: 'checkbox'
                },
                {
                  fieldName: 'SchedulingSmsContent',
                  name: localize.getLocalizedString('_OnlineSchedulingPolicyMessage_'),
                  placeholder: localize.getLocalizedString('_SchedulePolicyMessagePlaceholder_'),
                  type: 'textarea'
                }
              ]
            },
            {
              name: localize.getLocalizedString('_Texts_'),
              fields: [
                {
                  fieldName: 'FirstPageDescriptionTopHeader',
                  name: localize.getLocalizedString('_HeaderOnScheduling_'),
                  placeholder: localize.getLocalizedString('_HeaderOnSchedulingPlaceholder_'),
                  type: 'textarea'
                },
                {
                  fieldName: 'FirstPageDescriptionSubHeader',
                  name: localize.getLocalizedString('_SubHeaderOnScheduling_'),
                  placeholder: localize.getLocalizedString('_SubHeaderOnSchedulingPlaceholder_'),
                  type: 'textarea'
                },
                {
                  fieldName: 'FirstPageDescription',
                  name: localize.getLocalizedString('_FirstPageDescription_'),
                  placeholder: localize.getLocalizedString('_FirstPageDescriptionPlaceholder_'),
                  type: 'textarea'
                },
                {
                  fieldName: 'MessageAfterScheduling',
                  name: localize.getLocalizedString('_LastPageMessage_'),
                  placeholder: localize.getLocalizedString('_LastPageMessagePlaceholder_'),
                  type: 'textarea'
                },
                {
                  fieldName: 'CreditPaymentDescription',
                  name: localize.getLocalizedString('_CreditPaymentDescription_'),
                  type: 'textarea'
                }
              ]
            }
          ];

          if (scope.mediator.isWhatsappSupported) {
            scope.sections.filter(section => section.name === localize.getLocalizedString('_OwnerNotifications_'))[0].fields.push({
              fieldName: 'AllowWhatsappNotifications',
              name: localize.getLocalizedString('_SendByWhatsapp_'),
              type: 'checkbox'
            });
          }

            scope.configurationLoaded = false;
            scope.$watchOnce('model', function (newVal) {
              if (newVal && !scope.configurationLoaded) {
                adaptValuesToWrappedValues();
                scope.configurationLoaded = true;
              }
            });

            scope.valueChanged = function (newVal, _, field) {
              scope.model[field.fieldName] = newVal.value;
            };

            scope.comboModelValuesProxy = {};

            function adaptValuesToWrappedValues() {
              scope.sections.forEach(function (section) {
                section.fields.forEach(function (field) {
                  if (field.type === 'combo') {
                    var selectedValue = field.values.filter(function (nameValue) {
                      return nameValue.value === scope.model[field.fieldName];
                    });

                    scope.comboModelValuesProxy[field.fieldName] = (selectedValue.length > 0 ? selectedValue : field.values)[0];
                  }

                  field.id = getRandomId();
                });
              });
            }
          },
        template: require('../../../partial-views/online-scheduling/online-scheduling-configurations/online.scheduling.configurations.directive.html')

      };
    }]);

}());
