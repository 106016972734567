import './conversations.list.directive.less'
import {cleanObj, isNonEmptyArray, isNonEmptyString} from "@tomeravni/easybizy-js-common/common";

angular.module('easybizy.common.common-controls').directive('conversationsList',
  function ($rootScope, $timeout, localize, stateManager, $state, globalEntitiesCreationService,
            promotionTemplateResolver, Repository) {
    const OPEN_CONVERSATIONS_KEY = 'openConversations';
    const MINIMIZED_CONVERSATIONS_KEY = 'minimizedConversations';
    const MAX_OPEN_CONVERSATIONS = 3;
    const MAX_MINIMIZED_CONVERSATIONS = 12;

    return {
      restrict: 'E',
      replace: true,
      template: require('./conversations.list.directive.tpl.html'),
      link: function (scope) {
        scope.removeAllConversationsTooltip = localize.getLocalizedString('_Remove_')
        scope.conversationsURI = `${chatURI}/conversations/list`;
        scope.isVisible = false;
        $rootScope.$on('toggle-conversations', () => {
          scope.isVisible = !scope.isVisible;
        })

        scope.closeList = () => {
          scope.isVisible = !scope.isVisible;
        }

        scope[OPEN_CONVERSATIONS_KEY] = [];
        scope[MINIMIZED_CONVERSATIONS_KEY] = [];

        if ($.localStorage.isSet(OPEN_CONVERSATIONS_KEY)) {
          const openConversations = $.localStorage.get(OPEN_CONVERSATIONS_KEY)
          $timeout(() => {
            openConversations.forEach(openConversation)
          }, 1000)
        }


        if ($.localStorage.isSet(MINIMIZED_CONVERSATIONS_KEY)) {
          scope.minimizedConversations = $.localStorage.get(MINIMIZED_CONVERSATIONS_KEY)
        }

        scope.$watchCollection(OPEN_CONVERSATIONS_KEY, (openC) => {
          if (isNonEmptyArray(openC)) {
            $.localStorage.set(OPEN_CONVERSATIONS_KEY, openC.map((x) => x.conversationId))
          } else {
            $.localStorage.remove(OPEN_CONVERSATIONS_KEY)
          }
        })

        scope.$watchCollection(MINIMIZED_CONVERSATIONS_KEY, (minimizedC) => {
          if (isNonEmptyArray(minimizedC)) {
            $.localStorage.set(MINIMIZED_CONVERSATIONS_KEY, minimizedC)
          } else {
            $.localStorage.remove(MINIMIZED_CONVERSATIONS_KEY)
          }
        })


        scope.clearMinimized = () => {
          scope.minimizedConversations.length = 0;
        }

        scope.restore = (conversationId) => {
          const index = scope.minimizedConversations.findIndex((x) => x.conversationId === conversationId);
          scope.minimizedConversations.splice(index, 1);
          openConversation(conversationId);
        }

        $rootScope.$on('conversation-change', (e, data) => {
          const {unreadCount, conversationId} = data;
          const idx = scope.minimizedConversations.findIndex((x) => x.conversationId === conversationId);
          const minimized = scope.minimizedConversations[idx]
          if (minimized) {
            minimized.unreadCount = unreadCount;
            scope.$evalAsync(() => {
              scope.minimizedConversations[idx] = {...minimized};
              scope.minimizedConversations = [...scope.minimizedConversations]
            })
          }
        })

        window.addEventListener('message', (event) => {
          if (event.data?.source === 'conversations') {
            const conversationId = event.data.data?.conversationId;
            $timeout(() => {
              scope.isVisible = false;
              if (event.data.event === 'conversation-selected') {
                openConversation(conversationId)
              } else if (event.data.event === 'minimize-conversation') {
                const index = scope.openConversations.findIndex((x) => x.url.includes(conversationId));
                scope.openConversations.splice(index, 1);
                if (event.data.data.customerImage) {
                  event.data.data.customerImage = event.data.data.customerImage.replace('https://filesdev.easybizy.net', '');
                }
                scope.minimizedConversations.push(event.data.data);
                if (scope.minimizedConversations.length > MAX_MINIMIZED_CONVERSATIONS) {
                  scope.minimizedConversations.splice(0, 1);
                }

              } else if (event.data.event === 'close-conversation') {
                const index = scope.openConversations.findIndex((x) => x.url.includes(conversationId))
                scope.openConversations.splice(index, 1);
              } else if (event.data.event === 'move-to-conversations') {
                $state.go('Conversations')
              } else if (event.data.event === 'convert_to_customer') {
                const {customerName, customerPhoneNumber} = event.data.data;
                globalEntitiesCreationService.addCustomer(customerName, customerPhoneNumber);
              } else if (event.data.event === 'convert_to_lead') {
                const {customerName, customerPhoneNumber} = event.data.data;
                globalEntitiesCreationService.addLead(customerName, customerPhoneNumber);
              } else if (event.data.event === 'navigate-to-entity') {
                const {id, entityType} = event.data.data;
                const navigationRoute = `/${entityType}s/${id}`;
                window.open(navigationRoute, '_blank');
              } else if (event.data.event === 'load-dynamic-fields') {
                const fields = promotionTemplateResolver.getDynamicFields({CustomFields: ['NextMeetings', 'NextMeetingsAndServices','NextFirstMeetingsPerDay']});
                Repository.Custom('Configurations').loggedInUser().then((contextMeta) => {
                  const contextName = contextMeta.context.name.toLowerCase();
                  event.source.postMessage({
                    event: 'dynamic-fields-loaded',
                    data: JSON.stringify({
                      fields, extras: cleanObj({
                        onlineBookingURL: `${window.onlineBookingURI}/${contextName}`,
                        language: localize.language,
                        contextName
                      })
                    })
                  }, event.origin);
                });
              }
            })
          }
        }, false)

        $rootScope.$on('openConversation', (_, id) => {
          openConversation(id);
        })

        const toStandaloneUrl = (conversationId) => {
          return `${chatURI}/conversations/standalone/${conversationId}`;
        }

        const openConversation = (conversationId) => {
          if (!isNonEmptyString(conversationId) || scope.openConversations.some((x) => x.url.includes(conversationId))) {
            return;
          }

          if (scope.minimizedConversations.some((x) => x.conversationId === conversationId)) {
            return scope.restore(conversationId)
          }

          const url = toStandaloneUrl(conversationId)
          if (scope.openConversations.length >= MAX_OPEN_CONVERSATIONS) {
            const {idx} = scope.openConversations.reduce((oldest, curr, idx) => {
              if (!oldest) {
                return {timeInserted: curr.timeInserted, idx};
              }

              if (curr.timeInserted.getTime() < oldest.timeInserted.getTime()) {
                return {timeInserted: curr.timeInserted, idx}
              }

              return oldest;
            }, null)

            scope.openConversations[idx] = {url, timeInserted: new Date(), conversationId};
          } else {
            scope.openConversations.push({url, timeInserted: new Date(), conversationId});
          }
        }
      }
    };
  });
