import './common-controls.less';
import {asArray, guid} from '../../general/helpers';
import {DATE_DISPLAY_FORMAT} from '../../constants';
import Tippy from "tippy.js";
import {isConcreteValue} from "@tomeravni/easybizy-js-common/common";

const commonControlsModule = angular.module('easybizy.common.common-controls');

commonControlsModule.directive('scrollWidthAware', function () {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      if (attrs.scrollWidthAware.length > 0 && scope.$eval(attrs.scrollWidthAware) === false) {
        return;
      }
      var scrollCalculated = calculateScrollBarWidth();
      scrollCalculated = Math.max(scrollCalculated, 10);
      var calculatedWidth = "calc(100% - " + (scrollCalculated + 4) + "px)";
      if (!angular.isDefined(attrs.scrollbarWidth) || attrs.scrollbarWidth === true) {
        element.css("width", calculatedWidth);
      }

      function calculateScrollBarWidth() {
        var $inner = jQuery('<div style="width: 100%; height:200px;">test</div>'),
          $outer = jQuery('<div style="width:200px;height:150px; position: absolute; top: 0; left: 0; visibility: hidden; overflow:hidden;"></div>').append($inner),
          inner = $inner[0],
          outer = $outer[0];

        jQuery('body').append(outer);
        var width1 = inner.offsetWidth;
        $outer.css('overflow', 'scroll');
        var width2 = outer.clientWidth;
        $outer.remove();
        $inner.remove();
        // delete inner;
        // delete $outer;

        return (width1 - width2);
      }
    }
  };
});

commonControlsModule.directive('preventAutoComplete', function ($compile) {
  return {
    compile: function (telement, tattrs) {
      var randomId = 'rnd-' + guid() + '-' + new Date().getTime();
      telement.attr('autocomplete', randomId).attr('type', 'search');
      if (tattrs.preventAutoComplete !== 'ignoreId') {
        telement.attr('id', randomId)
      }
    }

  }
});

commonControlsModule.directive('dtpicker', [
  '$timeout', 'localize', function ($timeout, localize) {
    if (localize.isRightToLeft()) {
      var monthsShortName = [
        'ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני',
        'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'
      ];

      var dayNamesShort = ["א", "ב", "ג", "ד", "ה", "ו", "ש"];
      var dayNamesMin = dayNamesShort;
    }

    return {
      restrict: 'A',
      require: 'ngModel',
      scope: {
        isVisibleWrapper: '=',
        dateSelected: '&',
        isDateAvailablePointer: '&'
      },
      link: function (scope, element, attrs, ngModelCtrl) {
        element.addClass('.animate-visibility');
        scope.$watch('isVisibleWrapper.isVisible', function (newVal) {
          if (angular.isDefined(newVal)) {
            if (newVal) {
              element.datepicker("setDate", ngModelCtrl.$modelValue);
              element.parent().removeClass('zero-opacity');
            } else {
              element.parent().addClass('zero-opacity');
            }
          }
        });

        $timeout(function () {
          var datePickerProperties = {
            dateFormat: DATE_DISPLAY_FORMAT.toLowerCase().replace('yyyy', 'yy'),
            defaultDate: new Date(),
            regional: ['he'],
            changeMonth: true,
            yearRange: "-5:+1",
            changeYear: true,
            onSelect: function (date) {
              ngModelCtrl.$setViewValue(date);
              if (scope.dateSelected()) {
                scope.dateSelected()(
                  moment(date, DATE_DISPLAY_FORMAT));
              }

              scope.$digestIfNeeded();
            }
          };

          if (scope.isDateAvailablePointer()) {
            datePickerProperties.beforeShowDay = function (date) {
              return [scope.isDateAvailablePointer()(date)];
            }
          }

          if (localize.isRightToLeft()) {
            datePickerProperties.monthNamesShort = monthsShortName;
            datePickerProperties.dayNamesShort = dayNamesShort;
            datePickerProperties.dayNamesMin = dayNamesMin;
          }

          element.datepicker(datePickerProperties);
        });

        scope.$on('$destroy', function () {
          element.datepicker("destroy");
          element.removeClass("hasDatepicker").removeAttr('id');
          $timeout(function () {
            $('#ui-datepicker-div').remove();
          });
        });
      }
    };
  }]);

commonControlsModule.directive('formValidator', function () {
  return {
    restrict: 'A',
    replace: true,
    scope: {
      validate: '=fieldsToValidate',
      validationAction: '=formValidator'
    },
    link: function (scope, element, attrs) {
      scope.$watchOnce('validationAction', init);

      function init() {
        scope.validationAction.validate = function () {
          angular.forEach(scope.validate, function (validatable, fieldName) {
            validatable.validationError = validatable.validate();
          });

          let filter = asArray(scope.validate).filter(x => x.validationError);
          return filter.map(x => ({[x.key]: x.validationError}));
        };
      }
    }
  };
});

commonControlsModule.directive('fieldTextbox', function ($compile, $timeout, $parse, localize) {
  return {
    restrict: "E",
    replace: true,
    scope: {
      model: '=ngModel',
      field: '=fieldMetadata',
      place: '@placeholder',
      validatedFieldsAggregator: '=',
      icon: '@iconClass'
    },
    template: '<div class="field-with-text-box-wrapper" bindonce="field">\
                        <div class="field-with-text-box-view-mode relative-container">\
                            <div class="field-with-text-box-icon" bo-class="field.icon"></div>\
                            <div class="field-with-text-box-value field-with-text-box-value-concrete-value" ng-bind="fieldValue"></div>\
                            <span class="field-with-text-box-value" ng-if="fieldValue">{{field.unitOfMeasure}}</span>\
                        </div>\
                        <div class="absolute-container field-with-text-box-text">\
                            <textbox ng-model="model" placeholder="{{place}}" validated-fields-aggregator="validatedFieldsAggregator" \
                                     field-metadata="field"></textbox>\
                        </div>\
                   </div>',

    link: function (scope, element) {
      scope.$watch('model', function (newVal) {
        if (newVal) {
          scope.fieldValue = scope.model[scope.field.fieldName];
        }
      }, true);

      if (scope.field.customValueTemplate) {
        const elToReplace = $compile(scope.field.customValueTemplate)(scope);
        element.find('.field-with-text-box-view-mode > .field-with-text-box-value-concrete-value').replaceWith(elToReplace);
      }

      // scope.click2Call = Object.getSafely(['field', 'validation', 'phone'], scope) ? scope.field.fieldName : undefined;

      scope.$watch('fieldValue', function (newVal) {
        if (newVal) {
          scope.model[scope.field.fieldName] = newVal;
          element.find('.field-with-text-box-value').removeClass("no-value");
        } else if (typeof newVal !== 'undefined') {
          element.find('.field-with-text-box-value').addClass("no-value");
        }
      });
    }
  };
});

commonControlsModule.directive('fieldWrapper', function (localize) {
  return {
    restrict: "E",
    replace: true,
    transclude: true,
    scope: {
      value: '=',
      place: '@placeholder',
      icon: '@iconClass',
      editMode: '='
    },
    template: '<div class="field-with-text-box-wrapper field-wrapper">\
                        <div class="field-with-text-box-view-mode relative-container">\
                            <div class="field-with-text-box-icon" ng-class="icon"></div>\
                            <div class="field-with-text-box-value">{{ value || placeholder }}</div>\
                        </div>\
                        <div class="absolute-container field-with-text-box-text top-space" ng-if="editMode">\
                            <ng-transclude/>\
                        </div>\
                   </div>',

    link: function (scope) {
      if (scope.place) {
        scope.placeholder = localize.getLocalizedString(scope.place);
      }
    }
  };
});


commonControlsModule.directive('textbox', [
  '$compile', '$timeout', '$parse', 'validator', 'localize', 'colorsService'
  , function ($compile, $timeout, $parse, validator, localize, colorsService) {
    if (localize.isRightToLeft()) {
      var monthsShortName = [
        'ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני',
        'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'
      ];

      var dayNamesShort = ["א", "ב", "ג", "ד", "ה", "ו", "ש"];
      var dayNamesMin = dayNamesShort;
    }

    var inputIdIndex = Math.floor(Math.random() * 1000);

    return {
      restrict: 'E',
      scope: {
        model: '=ngModel',
        field: '=fieldMetadata',
        validatedFieldsAggregator: '=',
        disabled: '=',
        focus: '&',
        blur: '&',
        submit: '&',
        requestFocus: '=',
        selectOnFocus: '='
      },
      replace: true,
      link: function (scope, element, attrs) {

        if (scope.field && scope.field.fieldName) {
          element.addClass(`textbox-${scope.field.fieldName.toDash().toLowerCase()}`)

        }
        scope.inputId = 'text-box-' + (++inputIdIndex);
        // if (attrs.type === 'password') {
        //   setTimeout(function() {
        //     element.find('input').attr('name', scope.randomInt());
        //   }, 100);
        // }

        // wait till view is rendered to add event on input.
        if (!angular.isDefined(scope.model)) {
          scope.$watchOnce('model', init);
        } else {
          init();
        }

        scope.isValid = true;
        scope.focused = false;
        scope.textboxFieldValueWrapper = {};
        scope.textboxFieldValueWrapper.value = undefined;

        var literalWatcher = scope.$watch('model', function (newVal) {
          if (angular.isDefined(newVal)) {
            if (angular.isObject(newVal)) {
              literalWatcher();
              registerWatchObjectModel();
            } else if (scope.textboxFieldValueWrapper.value !== newVal) {
              scope.textboxFieldValueWrapper.value = newVal;
            }
          }
        });

        scope.$watch('textboxFieldValueWrapper.value', function (newVal) {
          if (angular.isDefined(newVal)) {
            if (scope.field) {
              if (scope.model && scope.model[scope.field.fieldName] !== newVal) {
                scope.model[scope.field.fieldName] = newVal;
              }
            } else {
              scope.model = newVal;
            }
          }
        });

        scope.setValidState = function (isValid) {
          if (isValid) {
            element.removeClass("invalid");
          } else {
            element.addClass("invalid");
          }
        };

        element.find('.clear-icon').mousedown(function (e) {
          scope.$apply(function () {
            scope.textboxFieldValueWrapper.value = "";
          });
        });

        scope.internalValidate = validate;
        var validationField;
        var type;

        /********************************* Helper methods *********************************/
        /********************************* Validation *********************************/
        function initValidation() {
          if (scope.validatedFieldsAggregator) {
            var key = scope.field ? scope.field.fieldName :
              attrs.placeholder ? attrs.placeholder : "default";

            scope.validatedFieldsAggregator[key] = {isValid: true, validate: validate};
          }

          validationField = scope.internalValidationField = scope.field ? scope.field.validation : attrs.validation;
          if (validationField) {
            var result = validationField.replace(/'/g, "\"");
            scope.validationRules = angular.fromJson(result);
            if (!(scope.field && scope.field.allowMultiple)) {
              scope.$watch('textboxFieldValueWrapper.value', function (value) {
                if (typeof value === 'undefined' || !scope.isToValidate) {
                  return;
                }

                scope.isValid = validate(value) == null;
              });
            }
          }
        }

        function toggleFocus(focus, ev) {
          scope.$applyIfNeeded(function () {
            scope.focused = focus;
            if (scope.focused && scope.focus()) {
              scope.focus()(ev, scope.field, scope.model);
            } else if (!scope.focused && scope.blur()) {
              scope.blur()(ev, scope.field, scope.model);
            }
          });
        }

        function validate(value) {
          var isValid = true;
          if (!value) {
            value = scope.textboxFieldValueWrapper.value;
          }

          var error = null;
          if (scope.field && scope.field.allowMultiple && arguments.length == 0) {
            var splittedValues = value.split(',');
            if (splittedValues.length > 0) {// hasMultipleValues
              splittedValues.forEach(function (val) {
                error = validate(val.trim());
                isValid = isValid && error == null;
              });
            }
          } else {
            $.each(scope.validationRules, function (index, ruleValue) {
              isValid = validator[index.toCamelCase()].validate(value, ruleValue);
              if (!isValid) {
                scope.error = validator[index.toCamelCase()].error();
                error = scope.error;
                return false;
              }
            });
          }

          scope.setValidState(isValid);
          scope.isValid = isValid;

          return isValid ? null : error;
        }

        function registerWatchObjectModel() {
          scope.$watch(function () {
            return scope.model[scope.field.fieldName];
          }, function (newVal, oldVal) {
            if (oldVal !== newVal !== scope.textboxFieldValueWrapper.value && angular.isDefined(newVal)) {
              scope.textboxFieldValueWrapper.value = scope.model[scope.field.fieldName];
            }
          });
        }

        function getDefaultStartDate() {
          var toReturn = "-25y";
          if (scope.field.defaultStartDate) {
            // toReturn = '05/05/2017';
            toReturn = scope.field.defaultStartDate;
          }
          return toReturn;
        }

        function init() {
          /********************************* Internal Fields *********************************/
          scope.icon = scope.field ? scope.field.icon : attrs.iconClass;
          type = scope.field ? scope.field.type : attrs.type;
          if (type === 'color') {
            $timeout(function () {
              initPlaceholder();
              initColorPicker();
            });

            return;
          } else if (type === 'password') {
            $timeout(function () {
              element.find('input.text-box-with-icon-input.main-text-field').addClass('masked');
            });
          }

          if (attrs.autoFocus) {
            $timeout(function () {
              element.find('input.text-box-with-icon-input.main-text-field').focus();
            }, attrs.autoFocus);
          }

          if (attrs.submit) {
            $timeout(function () {
              element.find('input.text-box-with-icon-input.main-text-field').on('keydown.textbox', function (e) {
                if (e.which === 13) {
                  e.preventDefault();
                  e.stopPropagation();
                  scope.submit()(scope.model);
                }
              });
            });
          }

          scope.allowMultiple = angular.isDefined(scope.field) && scope.field.allowMultiple == true;
          initValidation();
          $timeout(initInputFields);
          scope.$on('destroy', function () {
            element.find('input.text-box-with-icon-input.main-text-field').off('keyup.textbox');
          });

          if (scope.selectOnFocus) {
            element.find('input').on('focus', function () {
              var el = this;
              setTimeout(function () {
                el.select();
              }, 50);
            });
          }
        }

        function initInputFields() {
          element.find('input.text-box-with-icon-input.main-text-field')
            .focus(function (ev) {
              toggleFocus(true, ev);
            })
            .blur(function (e) {
              if (validationField) {
                var isValidationOn = scope.isToValidate;
                if (!isValidationOn) {
                  scope.isToValidate = true;
                  scope.$apply(function () {
                    scope.isValid = validate(e.target.value) == null;
                  });
                }
              }

              $timeout(function () {
                toggleFocus(false, e);
              });
            });

          initPlaceholder();


          if (type === 'date') {
            var input = element.find("input.text-box-with-icon-input");
            input.attr('type', 'text').on('touchstart', function (e) {
              if (!input.datepicker("widget").is(":visible")) {
                e.preventDefault();
                e.stopPropagation();
                input.datepicker("show");
              }
            });

            var datePickerProperties = {
              dateFormat: DATE_DISPLAY_FORMAT.toLowerCase().replace('yyyy', 'yy'),
              defaultDate: getDefaultStartDate(),
              regional: ['he'],
              changeMonth: true,
              yearRange: (scope.field && scope.field.yearRange) ? scope.field.yearRange : "-100:+0",
              changeYear: true,
              onSelect: function (date) {
                scope.$apply(function () {
                  scope.textboxFieldValueWrapper.value = date;
                });
              },
              beforeShow: function () {
                setTimeout(function () {
                  $('.ui-datepicker').css('z-index', 10001);
                }, 0);
              }
            };

            if (scope.field && scope.field.minDate) {
              datePickerProperties.minDate = scope.field.minDate;
            }

            if (localize.isRightToLeft()) {
              datePickerProperties.monthNamesShort = monthsShortName;
              datePickerProperties.dayNamesShort = dayNamesShort;
              datePickerProperties.dayNamesMin = dayNamesMin;
            }

            input.datepicker(datePickerProperties);

            scope.$on('$destroy', function () {
              input.removeClass("hasDatepicker").removeAttr('id');
              input.datepicker("destroy");
              $timeout(function () {
                // $('#ui-datepicker-div').remove();
              });
            });
          } else if (type === 'address') {
            var inputElement = element.find('input')[0];
            var autocomplete = new google.maps.places.Autocomplete(
              /** @type {!HTMLInputElement} */(inputElement),
              {types: ['address']});


            autocomplete.setComponentRestrictions(
              {'country': ['il']});


            // When the user selects an address from the dropdown, populate the address
            // fields in the form.
            autocomplete.addListener('place_changed', function (address) {
              var place = autocomplete.getPlace();
              scope.$evalAsync(function () {
                scope.textboxFieldValueWrapper.value = place.formatted_address;
              })
            });
          }
        }

        /********************************* Placeholder *********************************/
        function initPlaceholder() {
          type = scope.field ? scope.field.type : attrs.type;
          if (type === 'password') {
            return;
          }

          scope.placeholder = scope.field ? scope.field.placeholder :
            (attrs.placeholder && attrs.placeholder.length > 0 ? localize.getLocalizedString(attrs.placeholder) : "");

          if (scope.placeholder === '' && attrs.stringPlaceholder) {
            scope.placeholder = attrs.stringPlaceholder;
          }


          if (scope.placeholder.length == 0) {
            var observed = attrs.$observe('placeholder', function (newVal) {
              if (newVal) {
                scope.placeholder = newVal;
              }
            });
          }
        }

        function initColorPicker() {
          var template = '<color-picker select="colorSelected" selected-wrapper="model" \
                                                  selected-field-name="{{field.fieldName}}" \
                                                  is-visible-wrapper="isColorPickerVisibleWrapper">\
                                    </color-picker>';

          element.addClass('textbox-color-input');
          element.find('.textbox-elements-wrapper').append($compile(template)(scope));
          scope.isColorPickerVisibleWrapper = {};
          scope.isColorPickerVisibleWrapper.isVisible = false;

          scope.preventMe = function (e) {
            e.preventDefault();
            e.stopPropagation();
          };

          scope.onFocusMe = function () {

            scope.isColorPickerVisibleWrapper.isVisible = true;
          };

          scope.blurred = function () {
            scope.isColorPickerVisibleWrapper.isVisible = false;
          };

          var iconEl = element.find('.text-box-with-icon-left-icon');
          var colorMapper = null;
          scope.$watch('model[field.fieldName]', function (newVal, oldVal) {
            if (!angular.isDefined(newVal)) {
              return;
            }

            if (!colorMapper) { // init dictinary of selector.
              colorMapper = {};
              angular.forEach(colorsService.getDefaultColors(), function (color) {
                colorMapper[color.name] = color;
              });
            }

            if (oldVal) {
              iconEl.removeClass(colorMapper[oldVal].value);
            }

            if (newVal) {
              iconEl.addClass(colorMapper[newVal].value);
            }
          });

        }
      },
      template: '<div class="text-box-with-icon-container" bindonce="icon" bindonce="field" ng-class="{\'focused\': focused, \'no-icon\': !icon}">\
                        <div class="relative-container textbox-elements-wrapper" \
                        bo-class="{\'with-field-name\': field.showLabel}">\
                            <div bo-if="icon" bo-class="icon" class="text-box-with-icon-left-icon"></div>\
                            <div class="textbox-with-icon-field-name ui-ellipsis" bo-if="field.showLabel" bo-bind="field.placeholder"></div>\
                            <multiple-value-textbox bo-if="allowMultiple"></multiple-value-textbox>\
                            <input ng-if="!allowMultiple" class="text-box-with-icon-input main-text-field" \
                                   prevent-auto-complete="ignoreId" \
                                   ng-disabled="disabled"\
                                   ng-model="textboxFieldValueWrapper.value" ng-keypress="preventMe($event)" ng-paste="preventMe($event)" \
                                   ng-focus="onFocusMe()" ng-blur="blurred()" placeholder="{{::placeholder}}"/>\
                            <div class="text-box-with-icon-icon-wrapper centered-div-container" ng-class="{\'invisible\': !(focused && textboxFieldValue.length > 0)}">\
                                <div class="centered-div-content">\
                                    <div class="icon icon-flat-close clear-icon" ></div>\
                                </div>\
                            </div>\
                            <div class="text-box-with-icon-icon-wrapper centered-div-container" ng-class="{\'invisible\': (focused || isValid)}">\
                                <div class="centered-div-content">\
                                    <div class="glyphicon glyphicon-warning-sign warning-icon" title="{{error}}" ></div>\
                                </div>\
                            </div>\
                        </div>\
                    </div>'
    };
  }]);

commonControlsModule.directive('colorPicker', [
  '$timeout', 'colorsService', 'localize', function ($timeout, colorsService, localize) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        select: '&',
        selectedWrapper: '=',
        selectedFieldName: '@',
        isVisibleWrapper: '='
      },
      link: function (scope, element) {
        scope.possibleColors = colorsService.getDefaultColors();
        scope.selectColor = function (color) {
          if (scope.select()) {
            scope.select()({name: color.name, value: color.value});
          }

          scope.selectedWrapper[scope.selectedFieldName] = color.value ? color.name : "";
        };
      },
      template: '<div class="color-picker" ng-class="{\'invisible\': !isVisibleWrapper.isVisible}"> \
                        <div ng-repeat="color in possibleColors" class="color-picker-color-element" \
                             ng-mousedown="selectColor(color)" bindonce>\
                            <div class="color-picker-back" bo-class="color.value"></div>\
                            <div class="color-picker-label" bo-bind="color.name"></div>\
                        </div>\
                   </div>'

    }
  }]);

commonControlsModule.directive('multipleValueTextbox', function ($timeout, $log) {
  return {
    restrict: 'E',
    replace: true,
    link: function (scope, element, attrs) {
      scope.values = [];
      scope.textValues = "";


      var firstTimeWatcher = scope.$watch('model[field.fieldName]', function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          firstTimeWatcher();
          setValueFromElement(newVal);
        }
      }, true);

      var inputEl = element.find('.multiple-values-textbox-value');
      inputEl.attr('placeholder', scope.placeholder);
      element.on('keydown', function (event) {
        if (event.keyCode == 13 || event.keyCode === 9) {
          var textValue = inputEl.val().trim();
          if (textValue.length == 0) {
            return;
          }

          event.preventDefault();
          setValueFromElement(textValue)
        }
      });

      function setValueFromElement(textValue) {
        inputEl.val("");
        var newEl = $('<div class="multiple-values-textbox-single-value"><span class="multiple-values-concrete">' + textValue +
          '</span><span class="delete-value icon icon-flat-close"></span></div>');
        scope.values.push(newEl);
        newEl.find('span.delete-value').on('click', function (e) {
          e.preventDefault();
          e.stopPropagation();
          var index = scope.values.indexOf(newEl);
          scope.values.remove(newEl);
          newEl.remove();
          if (index > 0) {
            element.scrollTo(scope.values[index - 1]);
          }

          updateValues();
        });

        newEl.insertBefore(inputEl);
        focusTextEditor();
        element.scrollTo(inputEl);
        updateValues();
      }

      inputEl.on('keydown', function (event) {
        if (event.keyCode == 8 && inputEl.val().trim().length == 0) { // back;
          var el = scope.values.pop();
          el.remove();
          updateValues();
        }
      });

      inputEl.on('blur', function () {
        element.animate({scrollLeft: 0});
      });

      element.on('click', function () {
        element.scrollTo(inputEl);
        focusTextEditor();
      });

      function focusTextEditor() {
        $timeout(function () {
          inputEl.focus();
        });
      }

      function updateValues() {
        scope.textboxFieldValueWrapper.value = "";
        var isValid = true;
        angular.forEach(scope.values, function (val) {
          var valueToPush = val.text();
          scope.textboxFieldValueWrapper.value += scope.textboxFieldValueWrapper.value.length > 0 ? ", " + valueToPush : valueToPush;
          if (scope.internalValidationField) {
            if (scope.internalValidate(valueToPush) != null) {
              val.addClass("invalid-field");
              isValid = false;
            }
          }
        });

        scope.isValid = isValid;
        scope.setValidState(isValid);
        scope.$digestIfNeeded();

      }


    },
    template: '<div class="text-box-with-icon-input multiple-values-textbox"> \
                        <input class="text-box-with-icon-input multiple-values-textbox-value" contenteditable="true"></input>\
                   </div>'
  }
});


commonControlsModule.directive('rightToLeft', [
  'rightToLeftSwitcher', function (rightToLeftSwitcher) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        element.addClass(rightToLeftSwitcher.switchRightToLeft(attrs.rightToLeft));
      }
    };
  }]);


commonControlsModule.directive('loadingAnimation', [
  '$timeout', function ($timeout) {
    return {
      restrict: 'E',
      scope: {
        isLoading: '=show',
        preventMaskDelay: '@',
        preventBallsAnimation: '@',
        hideMask: '='
      },
      replace: true,
      link: function (scope, element, attrs) {
        scope.wasValueInitilized = angular.isDefined(scope.preventMaskDelay);
        scope.$watch('isLoading', function (newVal) {
          if (!angular.isDefined(newVal)) {
            return;
          }

          // this timeout is to prevent the first load from masking too soon.
          if (!scope.wasValueInitilized) {
            $timeout(function () {
              scope.wasValueInitilized = true;
            }, 500);
          }


          if (newVal) {
            element.removeClass('paused');
            element.css('display', 'block');

          } else {
            element.addClass('paused');
            $timeout(function () {
              element.css('display', 'none');
            }, 500);
          }
        });

        scope.$on('$routeChangeStart', function (next, current) {
          element.addClass('paused');
        });

        if (attrs.hasOwnProperty('class')) {
          element.addClass(attrs.class);
        }

        if (attrs.hasOwnProperty('numOfBalls')) {
          scope.balls = new Array(parseInt(attrs.numOfBalls));
        } else {
          scope.balls = new Array(6);
        }

        var numOfBalls = parseInt(attrs.numOfBalls);
        var width = numOfBalls + (numOfBalls - 1) * (1.7 - 1); // base margin is defined in the less file.
        element.find(".loading-element-wrapper").css('width', width + "em");
        element.find(".loading-element-wrapper").css('left', "calc(50% - " + width / 2 + "em" + ")");
      },
      template: '<div class="absolute-container loading-element">\
                        <div class="relative-container">\
                            <div ng-if="!hideMask" class="loading-element-mask absolute-container" ng-class="{\'zero-opacity\': !isLoading || !wasValueInitilized}"></div>\
                            <div class="loading-element-container absolute-container" ng-if="::(preventBallsAnimation != \'true\')">\
                                <div class="loading-element-wrapper">\
                                    <div class="dot" ng-repeat="ball in balls track by $index"></div>\
                                </div>\
                           </div>\
                        </div>\
                   </div>'
    };
  }]);

commonControlsModule.directive('filterHeaderFooter', function () {
  return {
    restrict: 'E',
    transclude: true,
    replace: true,
    link: function (scope, element, attrs) {

    },
    template: '\
                <div class="customers-filter-header absolute-container">\
                    <div class="relative-container">\
                        <div class="relative-container customers-filter-mask">\
                        </div>\
                        <div class="absolute-container customers-filter-content-wrapper prevent-selection-children">\
                            <div class="relative-container">\
                                <div class="centered-div-container">\
                                    <div class="centered-div-content left-text-aligned customers-current-filters-wrapper">\
                                        <div ng-transclude=""></div>\
                                    </div>\
                                </div>\
                            </div>\
                        </div>\
                    </div>\
                </div>\
            '
  };
});

commonControlsModule.directive('submitButton', [
  'localize', 'analytics', 'rightToLeftSwitcher', 'confirmService', function (localize, analytics, rightToLeftSwitcher, confirmService) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        click: '&',
        subButtonClick: '&',
        confirmCancel: '&',
        inProgress: '=',
        localizedText: "=",
        externalIcon: "=icon",
        disabled: "=",
        warningMode: "=",
        multipleValues: '='
      },
      compile: function (telement, tattrs) {
        if (tattrs.subButtonClick) {
          var subButtonIcon = tattrs.subButtonIcon || 'icon icon-add-multiple';
          var element = '<div class="sub-submit-button" ng-click="!disabled && secondSubmitClick($event)" disable-click-effect="{{disabled}}">\
              <div class="sub-submit-button-content ' + subButtonIcon + '"></div>\
           </div>';

          element = $(element);

          if (tattrs.subButtonTooltip) {
            var tooltipAttributes = {
              'tooltips': '',
              'tooltip-title': localize.getLocalizedString(tattrs.subButtonTooltip),
              'tooltip-speed': 'medium',
              'tooltip-class': 'tooltip-custom tooltip-on-modal'
            };

            element.attr(tooltipAttributes);
          }

          telement.append(
            element);
        }

        return function (scope, element, attrs) {
          var analyticsText = attrs.analyticsText || '';

          if (attrs.text) {
            scope.submitText = analyticsText = localize.getLocalizedString(attrs.text);
          }

          if (attrs.litteralIcon) {
            scope._icon = attrs.rightToLeftIcon == "true" ? rightToLeftSwitcher.switchRightToLeft(attrs.litteralIcon) : attrs.litteralIcon;
          } else {
            var watchedExternalIcon = scope.$watch('externalIcon', function (newVal) {
              if (angular.isDefined(newVal)) {
                watchedExternalIcon();
                scope._icon = attrs.rightToLeftIcon == "true" ? rightToLeftSwitcher.switchRightToLeft(newVal) : newVal;
              }
            });
          }

          scope.$watch('localizedText', function (newVal) {
            if (newVal) {
              scope.submitText = analyticsText = newVal;
            }
          });

          var wasSecondSubmitted = false;
          scope.secondSubmitClick = function () {
            wasSecondSubmitted = true;
            scope.subButtonClick();
          };

          scope.internalClick = function ($event) {
            if (!scope.disabled && !wasSecondSubmitted && !scope.inProgress) {
              if (!attrs.confirm) {
                click($event);
              } else {
                const yesAnswer = localize.getLocalizedString(attrs.confirmAnswersYes || "_Yes_");
                const noAnswer = localize.getLocalizedString(attrs.confirmAnswersNo || "_No_");
                confirmService.confirm(localize.getLocalizedString(attrs.confirm), null, (function () {
                  click($event);
                }), function () {
                  if (attrs.confirmCancel) {
                    scope.confirmCancel();
                  }
                }, yesAnswer, noAnswer);
              }
            }

            wasSecondSubmitted = false;
          };

          function click(event) {
            event.preventDefault();
            event.stopPropagation();
            analytics.trackEvent('submit-button', 'click', analyticsText);

            if (scope.multipleValues && scope.multipleValues.length > 0) {
              scope.pickFromMultiple = true;
            } else {
              const clickResult = scope.click();
              if (angular.isFunction(clickResult)) {
                clickResult()
              }

            }
          }

          scope.clickMultipleValue = ($event, value) => {
            $event.preventDefault();
            $event.stopPropagation();
            scope.click()(value);
          }

          scope.hideMultipleList = ($event) => {
            $event.preventDefault();
            $event.stopPropagation();
            scope.pickFromMultiple = false

          }
        }
      },
      template: '<div class="submit-button prevent-selection-children" disable-click-effect="{{disabled || confirmMode}}" \
                    ng-click="internalClick($event)" ng-class="{\'in-progress\': inProgress, \'disabled\': disabled, \'warning-mode\': warningMode, \'confirm-mode\':confirmMode}"> \
                            <div class="loading-slider">\
                                <div class="line"></div>\
                                <div class="break dot1"></div>\
                                <div class="break dot2"></div>\
                                <div class="break dot3"></div>\
                            </div>\
                            <div class="submit-button-content" bindonce="_icon">\
                                <span bo-if="_icon" bo-class="_icon"></span>\
                                <span ng-bind="submitText"></span>\
                            </div>\
                            <div class="multiple-values-wrapper" ng-if="pickFromMultiple && multipleValues.length > 0">\
                              <div class="multiple-values-mask disable-click-effect" disable-click-effect="true" ng-click="hideMultipleList($event)"></div>\
                              <div class="list-values">\
                                <div ng-repeat="value in multipleValues" ng-click="clickMultipleValue($event, value.value)">{{ value.name }}</div>\
                              </div>\
                            </div>\
                    </div>'
    };
  }]);

commonControlsModule.directive('checkbox', [
  'localize', '$timeout', function (localize, $timeout) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        text: '@',
        stringText: '=',
        active: '=',
        enabled: '=',
        clicked: '&',
        animateTextReplacement: "@",
        //manuallySelectWrapper: "="
        model: '=',
        field: '@'
      },
      link: function (scope, element, attrs) {
        if (scope.stringText) { // is To Localize String ?
          scope.label = scope.stringText;
        } else {
          scope.label = scope.text ? localize.getLocalizedString(scope.text) : "";
        }

        scope.disabled = () => {
          return scope.enabled === false;
        }

        scope.$watch('stringText', function (newVal) {
          if (newVal) {
            scope.label = newVal;
          }

          if (angular.isDefined(scope.animateTextReplacement) && scope.animateTextReplacement) {
            duplicateAndFadeOut();
          }
        });

        scope.toggleActive = function ($event) {
          if (scope.disabled()) {
            return false;
          }

          var isSelected = null;
          if (scope.isModelDefined()) {
            isSelected = scope.model[scope.field] = !scope.model[scope.field];
          } else {
            isSelected = scope.active = !scope.active;
          }

          if (scope.clicked()) {
            scope.clicked()(isSelected, $event);
          }
        };

        scope.isModelDefined = function () {
          return angular.isDefined(scope.model);
        };

        function duplicateAndFadeOut() {
          var offset = element.offset();
          var cloned = element.clone();
          cloned.removeClass('ng-isolate-scope');
          cloned.children().removeClass('ng-binding');

          //var el = angular.element(element.clone().find('*').removeAttr('ng-binding'));
          cloned.css({left: offset.left, top: offset.top, position: 'fixed'}).appendTo(element.parent());
          cloned.addClass('scale-transform-base');
          $timeout(function () {
            cloned.addClass('scale-transform-out');
          });

          $timeout(function () {
            cloned.remove();
          }, 500);

        }
      },
      template: '<div class="custom-checkbox prevent-selection-children" ng-class="disabled() && \'opacity-30\'">\
                        <div class="custom-checkbox" ng-class="{\'active\': isModelDefined() ? model[field] : active}">\
                            <div class="checkbox-icon" ng-click="toggleActive($event)">\
                                <span class="glyphicon glyphicon-ok"></span>\
                            </div>\
                            <div class="checkbox-value" ng-click="toggleActive($event)">{{label}}</div>\
                        </div>\
                   </div>'
    };
  }]);

commonControlsModule.directive('newCheckbox', [
  'localize', function (localize) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        text: '@',
        stringText: '=',
        disabled: '=',
        model: '=',
        field: '@',
        clicked: '&'
      },
      link: function (scope) {
        if (scope.stringText) { // is To Localize String ?
          scope.label = scope.stringText;
        } else {
          scope.label = scope.text ? localize.getLocalizedString(scope.text) : "";
        }
      },
      template: '<div class="checkbox-button" disable-click-effect="true" ng-init="id = randomInt()" ng-click="::clicked()(model[field])">\
                <input type="checkbox" id="{{::id}}" ng-model="model[field]" ng-disabled="disabled"/>\
                <label for="{{::id}}">{{::label}}</label>\
              </div>'
    };
  }]);

commonControlsModule.directive('onOffSwitch', [
  'localize', '$timeout', function (localize, $timeout) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        value: '=',
        disabled: '=',
        toggled: '&',
        buttonLabels: '='
      },
      link: function (scope, element, attrs) {
        var switchComponent = element.find('.switch-animate');
        scope.$watch('value', function (newVal) {
          if (angular.isDefined(newVal)) {
            switchComponent.removeClass('switch-on switch-off');
            if (scope.value) {
              switchComponent.addClass('switch-on');
            } else {
              switchComponent.addClass('switch-off');
            }
          }
        });

        scope.switchLeftText = localize.getLocalizedString(scope.buttonLabels ? scope.buttonLabels.on : '_On_');
        scope.switchRightText = localize.getLocalizedString(scope.buttonLabels ? scope.buttonLabels.off : '_Off_');

        scope.toggle = function () {
          if (!scope.disabled) {
            scope.value = !scope.value;
            if (scope.toggled()) {
              scope.toggled()(scope.value);
            }
          }
        };
      },
      template: '<div class="on-off-switch" ng-click="::toggle()" disable-click-effect="{{disabled}}">\
                        <div class="switch-animate switch-off">\
                            <input type="checkbox">\
                            <span class="switch-left">{{::switchLeftText}}</span>\
                            <div class="switch-icon"></div>\
                            <span class="switch-right">{{::switchRightText}}</span>\
                        </div>\
                    </div>'
    };
  }]);


commonControlsModule.directive("scrollBarHider", function () {
  return {
    restrict: 'E',
    replace: true,
    link: function (scope, element, attrs) {
      element.css('width', $.getScrollbarWidth() + "px");
    },
    template: '<div class="absolute-container scroll-hider">'


  };
});


commonControlsModule.directive('mySrc', [
  '$compile', function ($compile) {
    var replacementTemplate = '<div class="glyphicon glyphicon-picture image-replacement" ng-show="isError"></div>';
    return {
      link: function (scope, element, attrs) {
        var hasImageReplacement = false;
        attrs.$observe('mySrc', function (newVal) {
          scope.isError = false;
          element.get()[0].src = newVal;
          element.show();


        });

        element.on('error', function () {
          scope.isError = true;
          if (!hasImageReplacement) {
            var replacementEl = $compile(replacementTemplate)(scope);
            $(replacementEl).css('font-size', element.width() * .8 + "px");
            element.hide();
            element.parent().prepend(replacementEl);
            hasImageReplacement = true;
          }
        });
      }
    };
  }]);

commonControlsModule.directive('imageLoadError', function () {
  return {
    link: function (scope, element, attrs) {
      element.on('error', function () {
        scope.$eval(attrs.imageLoadError);
      })
    }
  }
});

commonControlsModule.directive("radioSwitcher", function () {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      states: "=values",
      selected: "="
    },
    link: function (scope, element, attrs) {
      scope.select = function (selectedState) {
        //                angular.forEach(scope.states, function(state) {
        //                    state.isSelected = false;
        //                })

        scope.selected = selectedState;
      };
    },
    template: '<div class="radio-switcher">\
                        <ul>\
                            <li ng-repeat="state in states" ng-click="select(state)" ng-class="{\'selected-state\': state == selected}">\
                                <div class="radio-switch-button">\
                                    <span class="radio-switch-icon {{state.icon}}"></span>\
                                    <span class="radio-switch-text">{{state.text}}</span> \
                                </div> \
                            </li>\
                        </ul>\
                   </div>'


  };
});

function stringToTime(string, addMinutes, max, min) {
  addMinutes = angular.isDefined(addMinutes) ? addMinutes : 0;
  var hAndM = string.split(":");
  var minutes = addMinutes;
  var hours = 0;
  if (hAndM.length == 2) { // there are hours and minutes
    hours = parseInt(hAndM[0]);
    minutes += parseInt(hAndM[1]);
  } else {
    minutes += parseInt(hAndM[0]);
  }

  // make sure minutes are up to 59
  if (minutes > 59) {
    hours += Math.floor(minutes / 60);
    minutes = minutes % 60;
  }

  // if minutes < 0;
  if (minutes < 0) {
    if (hours === 0 || minutes < -60) {
      minutes = 0;
    } else {
      hours--;
      minutes = 60 + minutes;
    }
  }

  if (!angular.isDefined(max)) {
    max = [23, 59];
  }

  if (!angular.isDefined(min)) {
    min = [0, 0];
  }

  hours = Math.min(max[0], hours);
  minutes = Math.min(hours < max[0] ? 59 : max[1], minutes);
  hours = Math.max(min[0], hours);
  minutes = Math.max((hours > min[0] ? 0 : min[1]), minutes);

  return (hours > 9 ? hours : "0" + hours) + ":" + (minutes > 9 ? minutes : "0" + minutes);
}

commonControlsModule.directive("currency", [
  'configuration', function (configuration) {
    return {
      restrict: 'A',
      link: function (scope, element) {
        // element.text(configuration.get().CashRegisterSettings.Currency.Value === "NIS" ? "₪" : "$");
        element.text("₪");
        //element.text(Globalize.culture().numberFormat.currency.symbol);
      }
    };
  }]);

commonControlsModule.directive("numberEditor", function ($timeout) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      disabled: '=',
      model: '=',
      min: '=',
      max: '=',
      allowUndefinedModel: '=',
      delta: '=',
      differentialDelta: '&',
      forceNegative: '=',
      manuallyChanged: '&',
      field: '@',
      type: '@',
      isManuallyDisabled: '@',
      momentumClick: '@'
    },
    link: function (scope, element, attrs) {
      if (scope.allowUndefinedModel) {
        if (!angular.isDefined(scope.model[scope.field])) {
          scope.model[scope.field] = scope.min ? scope.min : scope.delta;
        }
      }


      scope.action = function (multiplier, $event) {
        if (scope.disabled) {
          return;
        }

        // Momentum click.
        // if (scope.momentumClick != "false" && angular.isDefined($event)) {
        //   handleWithMomentumOnEvent(multiplier, $event);
        // }

        var newValueToSet = getValueAfterCommand(scope.model[scope.field], multiplier, multiplier > 0 ? scope.max : scope.min);
        if (scope.type === "float") {
          newValueToSet = newValueToSet.toFixed(2);
        }
        scope.model[scope.field] = newValueToSet;
        if (scope.manuallyChanged()) {
          scope.manuallyChanged()(newValueToSet);
        }
      };

      var valueBeforeFocused;
      scope.focused = function (e) {
        valueBeforeFocused = scope.model[scope.field];
        $timeout(function () {
          $(e.target).select();
          e.target.setSelectionRange(0, 9999);
        });
      };

      scope.focusedOut = function () {
        if (valueBeforeFocused !== scope.model[scope.field] && scope.manuallyChanged()) {
          scope.manuallyChanged()(scope.model[scope.field]);
        }
      };

      function getValueAfterCommand(currentValue, multiplier, toCompareWith) {
        let delta = multiplier > 0 ? momentumClick.currentAddDelta : momentumClick.currentSubtractDelta;
        if (angular.isFunction(scope.differentialDelta())) {
          let diffDelta = scope.differentialDelta()(scope.model);
          if (diffDelta) {
            delta = multiplier > 0 ? diffDelta.subtraction : diffDelta.addition;
          }

        }

        if (scope.type === "integer") {
          currentValue = parseInt(currentValue);
        } else if (scope.type === "float") {
          currentValue = parseFloat(currentValue);
        } else if (scope.type === "time") {
          return stringToTime(currentValue, multiplier * delta, scope.max.splitToInt(":"), scope.min.splitToInt(":"));
        }

        currentValue += (delta * multiplier);
        var isDefinedToCompareWith = angular.isDefined(toCompareWith) && !(isNaN(parseFloat(toCompareWith)));
        if (isDefinedToCompareWith) {
          if (multiplier < 0 && currentValue < toCompareWith) {
            currentValue = toCompareWith;

          } else if (multiplier > 0 && currentValue > toCompareWith) {
            currentValue = toCompareWith;

          }
        }

        return currentValue;
      }

      /*************************** Momentum click *************************/

      var momentumClick = {};
      var k_averageTimeBetweenClick = 700;
      var k_maxMomentumMultiplier = 100;

      if (scope.momentumClick != "false") {
        momentumClick.addCounter = 0;
        momentumClick.subtractCounter = 0;
        momentumClick.currentAddDelta = scope.delta;
        momentumClick.currentSubtractDelta = scope.delta;
        momentumClick.lastAddTimeout = null;
        momentumClick.lastSubtractTimeout = null;
      } else {
        momentumClick.currentAddDelta = scope.delta;
        momentumClick.currentSubtractDelta = scope.delta;
      }

      // function handleWithMomentumOnEvent(multiplier, $event) {
      //   var counter = "addCounter";
      //   var timeDelta = "addDelta";
      //   var quantityDelta = "currentAddDelta";
      //   var timeout = "lastAddTimeout";
      //
      //   if (multiplier < 0) {
      //     counter = "subtractCounter";
      //     timeDelta = "subtractDelta";
      //     quantityDelta = "currentSubtractDelta";
      //     timeout = "lastSubtractTimeout";
      //   }
      //
      //   momentumClick[counter]++;
      //   momentumClick[timeDelta] = $event.timeStamp;
      //   var deltaFromLastClick = $event.timeStamp - momentumClick[timeDelta];
      //   if (deltaFromLastClick < k_averageTimeBetweenClick && momentumClick[counter] > 2) { // we click fast!
      //     momentumClick[quantityDelta] = Math.min(k_maxMomentumMultiplier * scope.delta,
      //       momentumClick[quantityDelta] * 2);
      //     momentumClick[counter] = 0;
      //     if (momentumClick[timeout]) {
      //       $timeout.cancel(momentumClick[timeout]);
      //     }
      //
      //     momentumClick[timeout] = $timeout(registerMomentumTimeout, k_averageTimeBetweenClick);
      //   } else if (momentumClick[timeout]) {
      //     $timeout.cancel(momentumClick[timeout]);
      //     momentumClick[timeout] = $timeout(registerMomentumTimeout, k_averageTimeBetweenClick);
      //   }
      // }

      // function registerMomentumTimeout() {
      //   momentumClick.addCounter = 0;
      //   momentumClick.subtractCounter = 0;
      //   momentumClick.currentAddDelta = scope.delta;
      //   momentumClick.currentSubtractDelta = scope.delta;
      //   momentumClick.lastAddTimeout = null;
      //   momentumClick.lastSubtractTimeout = null;
      // }

      var baseValue;
      scope.$watch('model[field]', function (newVal) {
        if (angular.isDefined(newVal)) {
          if (!angular.isDefined(baseValue)) {
            baseValue = parseFloat(newVal);
          } else if (baseValue !== newVal && (!Number.isNaN(baseValue) || newVal !== 0)) {
            scope.model.dirty = true;
          }
        }
      });
    },
    template: '<div class="number-editor relative-container" ng-class="{\'disabled-number-editor\': disabled}">\
                  <div class="concrete-number-editor-button-value number-editor-left-part prevent-selection flex-center icon icon-plus" ng-click="action(1, $event)"></div>\
                    <input ng-model="model[field]" valid-regex="{{::type}}" ng-blur="focusedOut()" ng-focus="focused($event)" \
                                   force-negative="forceNegative" min="min" max="max" ng-disabled="disabled || isManuallyDisabled == \'true\'" /> \
                  <div class="concrete-number-editor-button-value number-editor-right-part prevent-selection flex-center icon icon-minus" ng-click="action(-1, $event)"></div>\
               </div>'
  };
});

commonControlsModule.directive("jtimepicker", [
  '$timeout', 'localize', function ($timeout, localize) {
    var generationIndex = 0;
    // if (localize.isRightToLeft()) {
    //   // source: src/i18n/jquery-ui-timepicker-he.js
    //   /* Hebrew translation for the jQuery Timepicker Addon */
    //   /* Written by Lior Lapid */
    //   $.timepicker.regional["he"] = {
    //     timeOnlyTitle: "בחירת זמן",
    //     timeText: "שעה",
    //     hourText: "שעות",
    //     minuteText: "דקות",
    //     secondText: "שניות",
    //     millisecText: "אלפית השנייה",
    //     microsecText: "מיקרו",
    //     timezoneText: "אזור זמן",
    //     currentText: "עכשיו",
    //     closeText: "סגור",
    //     timeFormat: "HH:mm",
    //     amNames: ['לפנה"צ', 'AM', 'A'],
    //     pmNames: ['אחה"צ', 'PM', 'P'],
    //     isRTL: true
    //   };
    //
    //   $.timepicker.setDefaults($.timepicker.regional['he']);
    // }

    return {
      restrict: 'E',
      replace: true,
      scope: {
        time: '=',
        eventsDelegate: '=',
        timeSelected: '='
      },
      link: function (scope, element, attrs) {
        var itemIdx = 'time-picker-' + ++generationIndex;
        element.timepicker({
          show2400: true, timeFormat: 'H:i',
          setTime: scope.time,
          className: itemIdx,
          step: () => 15
        });

        scope.$on('$destroy', function () {
          // Remove elements.
          setTimeout(() => {
            const popover = $('.' + itemIdx);
            console.log(popover)
            popover.remove();
          })
        });

        scope.$watchOnce('eventsDelegate', function () {
          scope.eventsDelegate.show = function () {
            $timeout(function () {
              element.timepicker('show');
            });
          };
        });

        scope.focused = () => {

        }

      },
      template: '<input class="jtimepicker-input" ng-focus="focused" ng-model="time" type="text"/>'
    }
  }]);

commonControlsModule.directive('validRegex', function () {
  return {
    restrict: 'A',
    scope: {
      validRegex: "@",
      min: "=",
      max: "=",
      ngModel: '=',
      forceNegative: '='
    },
    require: '?ngModel',
    link: function (scope, element, attrs, ngModelCtrl) {
      if (!ngModelCtrl) {
        return;
      }

      var allowMinus = !scope.min || scope.min < 0;

      var lastValidatedValue = undefined;

      var handleModelChanged = function (newVal) {
        if (!angular.isDefined(lastValidatedValue) || (lastValidatedValue !== newVal && angular.isDefined(newVal))) {
          lastValidatedValue = parser("" + newVal, true);
          validateManualChange();
        }
      };

      var watchedModel = scope.$watch('ngModel', handleModelChanged);
      var parser = function (val, forceFixed) {
        var cleaned = clean(val, forceFixed == true);

        if (val !== cleaned) {
          ngModelCtrl.$setViewValue(cleaned + "");
          ngModelCtrl.$render();
        }

        return cleaned;
      };

      var clean = function (string, forceFixed) {
        var toReturn = "";
        if (scope.validRegex === "integer") {
          toReturn = string.replace(/[^\-0-9\.]+/g, '');
          toReturn = parseInt(toReturn);
          if (Number.isNaN(toReturn)) {
            toReturn = 0;
          }
        } else if (scope.validRegex === "float") {
          toReturn = string.replace(/[^\-0-9\.]+/g, '');
          toReturn = replaceMoreThanOneOccurrence(toReturn, /\./g);
          var parsedResultEqualOriginal = !forceFixed && parseFloat(toReturn) != parseFloat(string);
          if (!(allowMinus && toReturn == '-') && (forceFixed || parsedResultEqualOriginal)) {
            toReturn = parseFloat(toReturn);
            toReturn = toReturn.toFixed(2) + "";
            if (toReturn == "NaN") {
              toReturn = 0;
            }
          }

        } else if (scope.validRegex === "time") {
          toReturn = string.replace(/[^0-9:]+/g, '');
          toReturn = replaceMoreThanOneOccurrence(toReturn, /:/g);
          if (toReturn.length > 5) {
            toReturn = ngModelCtrl.$modelValue;
          }
        }

        if (scope.forceNegative && (scope.validRegex === "integer" || scope.validRegex === "float")) {
          if (toReturn.length > 0 && parseInt(toReturn) > 0 && toReturn.indexOf('-') < 0) {
            toReturn = '-' + toReturn;
          }
        }

        return toReturn;
      };


      ngModelCtrl.$parsers.push(parser);

      element.on('keypress', function (event) {
        if (event.keyCode === 32) {
          event.preventDefault();
        }
      });

      element.on('focus', function (e) {
        watchedModel();
      });

      element.on('focusout', function (event) {
        validateManualChange();
        watchedModel = scope.$watch('ngModel', handleModelChanged);
      });

      function validateManualChange() {
        if (ngModelCtrl.$viewValue.length === 0) { //    with empty value
          if (scope.validRegex === "integer" || scope.validRegex === "float") {
            ngModelCtrl.$setViewValue(scope.min);
            ngModelCtrl.$render();
          } else if (scope.validRegex === "time") {
            var splitted = scope.min.splitToInt(":");
            ngModelCtrl.$setViewValue(splitted[0] + ":" + splitted[1]);
            ngModelCtrl.$render();
          }
        } else {
          var cleanedValue = "";
          if (scope.validRegex === "integer" || scope.validRegex === "float") {
            if (scope.validRegex === "integer") {
              cleanedValue = parseInt(ngModelCtrl.$viewValue);
            } else if (scope.validRegex === "float") {
              cleanedValue = parseFloat(ngModelCtrl.$viewValue);

            }

            cleanedValue = Math.min(cleanedValue, (isNaN(scope.max) ? cleanedValue : scope.max));
            cleanedValue = Math.max(cleanedValue, (isNaN(scope.min) ? cleanedValue : scope.min));

            if (scope.validRegex === "float") {
              cleanedValue = cleanedValue.toFixed(2);
            }
          } else if (scope.validRegex === "time") {
            var splittedMin = scope.min.splitToInt(":");
            var splittedMax = scope.max.splitToInt(":");
            cleanedValue = stringToTime(ngModelCtrl.$viewValue + "", 0, splittedMax, splittedMin);
          }

          if (cleanedValue !== ngModelCtrl.$viewValue) {
            ngModelCtrl.$setViewValue(cleanedValue + "");
            ngModelCtrl.$render();
          }

        }
      }


      function replaceMoreThanOneOccurrence(string, toReplace) {
        var n = 0;
        return string.replace(toReplace,
          function (x) {
            return n++ > 0 ? '' : x;
          });
      }

    }
  };
});

commonControlsModule.directive("moneyField", [
  'configuration', function (configuration) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        value: '=',
        fieldName: '@',
        getValueFunc: '&',
        entity: '=',
        litteralValue: '='
      },
      link: function (scope) {
        scope.currency = configuration.get().CashRegisterSettings.Currency.Value === 'NIS' ? '₪' : '$'
        scope.getValue = angular.isDefined(scope.getValueFunc()) ?
          function () {
            return scope.getValueFunc()(scope.entity);
          } :
          function () {
            if (scope.litteralValue) {
              return scope.litteralValue;
            } else {
              return scope.entity[scope.fieldName];
            }
          };

        scope.wholePart = function (number) {
          return Math.trunc(parseFloat(number)) || 0;
        };

        scope.fractionPart = function (number) {
          return (angular.isString(number) ? number : (number || 0).toFixed(2)).split('.').pop();
        };
      },
      template: '<div class="centered-div-container money-field-component">\
                                <div class="centered-div-content left-text-aligned">\
                                    <div class="money-field-values-wrapper">\
                                        <div class="money-field-whole-part" bindonce="currency">\
                                            <span bo-bind="currency"></span><span ng-bind="wholePart(getValue())"></span>\
                                            <span>&nbsp;&nbsp;&nbsp;</span>\
                                        </div>\
                                        <div class="money-field-fraction-part" ng-bind="fractionPart(getValue())"></div>\
                                    </div>\
                                </div>\
                        <div>'
    };
  }]);

commonControlsModule.directive("radioGroup", [
  'localize', function (localize) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        values: '=?',
        selectedWrapper: '=selected',
        stringValues: '=?',
        fieldName: '@',
        disabled: '='
      },
      link: function (scope, element, attrs) {
        if (scope.stringValues) {
          scope.values = [];
          angular.forEach(scope.stringValues, function (val) {
            scope.values.push({
              name: localize.getLocalizedString(val.name, val.params ? val.params : []),
              value: val.value
            });
          });

          scope.$watchOnce('selectedWrapper', function (newVal) {

            scope.selectedWrapper.value = scope.values.filter((x) => x.value === newVal.Value)[0];
            delete scope.selectedWrapper.Value;
          });
        }

        scope.internalFieldName = angular.isDefined(scope.fieldName) ? scope.fieldName : "value";

        scope.select = function (val) {
          if (scope.disabled) {
            return;
          }

          scope.selectedWrapper[scope.internalFieldName] = val;
        };

        var defaultValueObserver = scope.$watch('selectedWrapper', function (newVal) {
          if (angular.isDefined(newVal)) {
            defaultValueObserver();
            if (!angular.isDefined(newVal[scope.internalFieldName])) {
              scope.selectedWrapper[scope.internalFieldName] = scope.values[0];
            }
          }
        });

        scope.isModelDefined = function () {
          return angular.isDefined(scope.model);
        };
      },
      template: `<div class="radio-group-wrapper" ng-class="disabled && 'disabled'">
                        <span data-ng-repeat="val in values">
                            <span ng-click="select(val)" disable-click-effect="true">
                                <div class="radio-group-sign-wrapper" >
                                    <div class="radio-group-sign"
                                        ng-class="{'zero-opacity': val != selectedWrapper[internalFieldName]}"></div>
                                </div>
                                <label class="radio-button-label" >{{val.name}}</label>
                            </span>
                       </span>
                   </div>`
    };
  }]);


commonControlsModule.directive("outsideClick", function ($timeout) {
  var k_OutsideEventsNamespace = "outside_event_";

  return {
    restrict: 'A',
    replace: true,
    scope: {
      outsideClick: '&',
      active: '=',
      ignorePseudoElements: '@'
    },
    link: function (scope, element, attrs) {
      scope.$watch('active', function (newVal) {
        if (angular.isDefined(newVal)) {
          addOrRemoveEvent(!newVal);
        }
      });

      scope.$on('$destroy', function () {
        addOrRemoveEvent(true);
      });


      function addOrRemoveEvent(isToRemove) {
        $timeout(function () {
          // first remove the event regardless what was asked.
          $(document).off('click.' + k_OutsideEventsNamespace + scope.$id, outsideClick);

          if (!isToRemove) {
            $(document).on('click.' + k_OutsideEventsNamespace + scope.$id, outsideClick);
          }
        });

      }

      function outsideClick(e) {
        if (!element.is(e.target) // if the target of the click isn't the container...
          && element.has(e.target).length === 0)
          // ... nor a descendant of the container
        {
          if (scope.ignorePseudoElements && $(e.target).closest('body').length === 0) {
            return;
          }

          scope.$evalAsync(scope.outsideClick);
        }
      }

    }
  };
});

commonControlsModule.directive("passElement", function () {
  return {
    restrict: 'A',
    replace: true,
    link: function (scope, element, attrs) {
      scope[attrs['passElement']].el = element;
    }
  };
});

commonControlsModule.directive("numbersOnly", function () {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      element.keydown(function (e) {
        // Allow: backspace, delete, tab, enter and .
        if ($.inArray(e.keyCode, [46, 8, 9, 13, 110]) !== -1 ||
          // Allow: Ctrl+A
          (e.keyCode == 65 && e.ctrlKey === true) ||
          // Allow: home, end, left, right
          (e.keyCode >= 35 && e.keyCode <= 39)) {
          // let it happen, don't do anything
          return;
        }
        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
          e.preventDefault();
        }
      });
    }
  };
});


commonControlsModule.directive("timeago", function () {
  return {
    restrict: 'A',
    replace: true,
    link: function (scope, element, attrs) {
      var observer = attrs.$observe('timeago', function (val) {
        if (angular.isDefined(val)) {
          element.text($.timeago(scope.$eval(val)));
          observer();
        }
      });
    }
  };
});

commonControlsModule.directive("selectAllOnFocus", function () {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      if (attrs.selectAllOnFocus == "true") {
        element.on('focus.selectAll', onFocus);

        scope.$on('$destroy', function () {
          element.off('focus.selectAll', onFocus);
        });
      }

      function onFocus() {
        element.select();
      }
    }
  };
});

commonControlsModule.directive('viewLink', [
  '$state', 'internalMessage', '$timeout', function ($state, internalMessage, $timeout) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        element.on('click.viewLink', function () {
          $timeout(function () {
            $state.go(attrs.viewLink);
          }, 250);
        });

        scope.$on('$destroy', function () {
          element.off('click.viewLink');
        });
      }
    };
  }]);


commonControlsModule.directive('daysOfWorkPicker', [
  'localize', function (localize) {
    return {
      restrict: 'E',
      scope: {
        daysOfWork: '='
      },
      link: function (scope, element, attrs) {
        scope.daysOfWorkNames = [
          localize.getLocalizedString("_Sun_"),
          localize.getLocalizedString("_Mon_"),
          localize.getLocalizedString("_Tue_"),
          localize.getLocalizedString("_Wed_"),
          localize.getLocalizedString("_Thu_"),
          localize.getLocalizedString("_Fri_"),
          localize.getLocalizedString("_Sat_")];

        scope.$watchOnce('daysOfWork', function () {
          angular.forEach(scope.daysOfWork, function (day) {
            day.name = scope.daysOfWorkNames[day.Index];
          });
        });

        scope.toggleDayHoursPopup = function (day) {
          day.isHoursPopupVisible = !day.isHoursPopupVisible;
          if (day.isHoursPopupVisible) {
            angular.forEach(scope.daysOfWork, function (otherDay) {
              if (day != otherDay) {
                otherDay.isHoursPopupVisible = false;
              }
            });
          }
        };

        scope.close = function (day) {
          day.isHoursPopupVisible = false;
        };
      },
      template: '<div class="configuration-days-of-work-day-wrapper" ng-repeat="day in daysOfWork" bindonce outside-click="close(day)" active="day.isHoursPopupVisible">\
                        <div class="configuration-days-of-work-day-name">{{::day.name}}</div>\
                            <div class="configuration-days-of-work-day-selector-wrapper">\
                                <checkbox string-text="" active="day.IsEnabled"></checkbox>\
                            </div>\
                        </div>\
                    </div>'
    };
  }]);

commonControlsModule.directive('ebClick', function ($timeout) {
  var k_timeDifferneceForDobuleClick = 500;
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      var lastClickTimeSpan = 0;
      var timeoutFunc = function () {
        lastClickTimeSpan = null;

        if (!angular.isDefined(attrs.raiseBothEvents) || attrs.raiseBothEvents !== "true") {
          scope.$evalAsync(attrs.ebClick);
        }
      };

      element.on('mousedown.ebClick', function () {
        element.addClass('active');
        $(document).on('touchend.ebClick mouseup.ebClick touchcancel.ebClick touchmove.ebCLick mousemove.ebClick', function (event) {
          let cancelTimeout;
          element.removeClass('active');
          $(this).off('touchend.ebClick mouseup.ebClick touchcancel.ebClick touchmove.ebCLick mousemove.ebClick');
          if (event.type === "touchend") {
            event.stopPropagation();
            event.preventDefault();
          }
          // no double click!
          if (!angular.isDefined(attrs.ebDblClick) || attrs.ebDblClick.length == 0) {
            scope.$evalAsync(attrs.ebClick);
          } else {
            if (lastClickTimeSpan) {
              if (event.timeStamp - lastClickTimeSpan < k_timeDifferneceForDobuleClick) {
                lastClickTimeSpan = null;
                if (cancelTimeout) {
                  $timeout.cancel(cancelTimeout);
                }

                event.stopPropagation();
                event.preventDefault();
                scope.$evalAsync(attrs.ebDblClick);
              }
            } else {
              lastClickTimeSpan = event.timeStamp;
              if (angular.isDefined(attrs.raiseBothEvents) && attrs.raiseBothEvents === "true") {
                scope.$evalAsync(attrs.ebClick);
              }

              cancelTimeout = $timeout(timeoutFunc, k_timeDifferneceForDobuleClick);
            }
          }
        });
      });


      scope.$on('$destroy', function () {
        $(this).off('mousedown.ebClick touchstart.ebClick touchend.ebClick mouseup.ebClick touchcancel.ebClick touchmove.ebCLick mousemove.ebClick');
      });
    }
  };
});

commonControlsModule.directive('customersSelector', [
  'localize', 'Repository', '$timeout', 'filterLogicFactory',
  function (localize, Repository, $timeout, filterLogicFactory) {
    return {
      restrict: 'E',
      scope: {
        selectedCustomerWrapper: '=',
        selectedCustomerField: '@',
        enableEmptyCustomer: "=",
        customersWrapper: "=",
        tilesTooltip: '&',
        includeGroups: '@',
        customerSearchTextWrapper: '='
      },
      link: function (scope) {
        scope.customers = [];
        scope.parsedSelectedCustomer = scope.customerSearchTextWrapper || {};
        scope.parsedSelectedCustomer.text = "";
        scope.tilesSelectedCustomerWrapper = {};
        scope.lastLoadedFilterText = "";
        scope.isLoadingWrapper = {};
        scope.isLoadingWrapper.isLoading = true;
        scope.customersTileApi = Repository.Entity("Customer").query()
          .select('CustomerId,FirstName,LastName,Gender,MobileFirst,DefaultImage,DefaultImagePath,Remarks,Debt,CreditToken');
        scope.customersTileApi.tileDecorator = function (entity) {
          entity.expandable = false;
        };

        // If the customers array is defined from outside, use it.
        var externalCustomersWatcher = scope.$watch('customersWrapper', function (newVal) {
          if (angular.isDefined(newVal)) {
            externalCustomersWatcher();
            if (scope.customers.length > 0) {
              newVal.customers.pushAll(scope.customers);
            }

            scope.customers = newVal.customers;
          }
        });

        scope.$watch('selectedCustomerWrapper[selectedCustomerField]', function (newVal, oldVal) {
          if (newVal && newVal != oldVal) {
            selectExternalCustomer();
          }
        });

        scope.customersTileApi.filters = [];
        if (scope.includeGroups != 'true') {
          scope.customersTileApi.filters.push("CustomerType eq 'Single'");
        }

        scope.customersTileApi.doneLoadingCallback = function (newlyLoadedCustomers) {
          selectExternalCustomer();

          if (scope.enableEmptyCustomer && scope.customers.indexOf(scope.emptyCustomer) < 0) {
            scope.customers.unshift(scope.emptyCustomer);
          }
        };


        if (scope.enableEmptyCustomer) {
          scope.emptyCustomer = {};
          scope.emptyCustomer.EntityType = "Customer";
          scope.emptyCustomer.FirstName = localize.getLocalizedString("_NoCustomer_");
          scope.emptyCustomer.LastName = "";
          scope.emptyCustomer.Images = [];
          scope.emptyCustomer.expandable = false;
        }

        scope.$watch('tilesSelectedCustomerWrapper.entity', function (newVal) {
          if (!!newVal) {
            scope.parsedSelectedCustomer.text = getNameFromCustomer(newVal);
            scope.selectedCustomerWrapper[scope.selectedCustomerField] = newVal;
          }
        });

        scope.$watch('parsedSelectedCustomer.text', function (newCustomerNameSearch, previousCustomerNameSearch) {
          // customer requested new name;
          if (newCustomerNameSearch === getNameFromCustomer(scope.tilesSelectedCustomerWrapper.entity) ||
            newCustomerNameSearch === previousCustomerNameSearch) {
            return;
          } else {
            if (angular.isFunction(scope.tilesSelectedCustomerWrapper)) {
              scope.tilesSelectedCustomerWrapper.manuallySelect(null);
            }

            delete scope.selectedCustomerWrapper.customer;
          }

          var loadCustomersIfNeededFunc = new function () {
            var nameSearched = newCustomerNameSearch;
            return function () {
              if (nameSearched === scope.parsedSelectedCustomer.text
                && nameSearched !== getNameFromCustomer(scope.tilesSelectedCustomerWrapper.entity)
                && nameSearched !== scope.lastLoadedFilterText) {
                scope.tilesSelectedCustomerWrapper.manuallySelect(null);
                scope.lastLoadedFilterText = nameSearched;
                scope.customersTileApi.filters.length = 0;
                if (scope.parsedSelectedCustomer.text.length > 0) {
                  var filter = generateAlphabetically(scope.parsedSelectedCustomer.text, 'FirstName,LastName,MembershipId');
                  if (scope.includeGroups != 'true') {
                    filter += "and (CustomerType eq 'Single')";
                  }

                  scope.customersTileApi.filters.push(filter);
                } else {
                  if (scope.includeGroups != 'true') {
                    scope.customersTileApi.filters.push("CustomerType eq 'Single'");
                  }
                }
              } else if (nameSearched === "" && !scope.selectedCustomerWrapper.customer) {
                scope.tilesSelectedCustomerWrapper.manuallySelect(null);
              }
            };
          }();

          $timeout(loadCustomersIfNeededFunc, 700);
        });

        scope.doubleClicked = function (e) {
          if (angular.isDefined(scope.selectedCustomerWrapper.invoke)) {
            scope.selectedCustomerWrapper.invoke();
          }
        };

        scope.searchText = "_SearchACustomer_";

        function selectExternalCustomer() {
          if (angular.isDefined(scope.selectedCustomerWrapper) &&
            scope.selectedCustomerWrapper.hasOwnProperty(scope.selectedCustomerField)) {
            var recievedSelectedCustomer = scope.selectedCustomerWrapper[scope.selectedCustomerField];
            if (recievedSelectedCustomer) {
              var indexOfSelected = scope.customers.indexOfById(recievedSelectedCustomer, 'CustomerId');
              if (indexOfSelected > -1) {
                scope.tilesSelectedCustomerWrapper.manuallySelect(scope.customers[indexOfSelected]);
              } else {
                scope.tilesSelectedCustomerWrapper.entity = recievedSelectedCustomer;
              }
            }
          }
        }

        function getNameFromCustomer(customer) {
          return customer && customer.hasOwnProperty("FirstName") ? customer.FirstName + (customer.LastName ? " " + customer.LastName : "") :
            customer && customer.hasOwnProperty("CustomerName") ? customer.CustomerName : null;
        }

        function generateAlphabetically(string, fields) {
          return filterLogicFactory.get('search', {
            fields: "FirstName, LastName, MembershipId",
            type: "Customer",
            numbersOnlyFields: 'MobileFirst, MobileSecond'
          })
            .convertValue(string, fields, 'Customer');
        }
      },
      template: '<div class="relative-container">\
                        <div class="client-search-wrapper">\
                            <textbox ng-model="parsedSelectedCustomer.text" \
                                select-on-focus="true" \
                                icon-class="icon icon-clients-ol" \
                                placeholder="{{searchText}}"></textbox> \
                        </div>\
                        <loading-animation class="" show="isLoadingWrapper.isLoading" num-of-balls="5"></loading-animation>\
                        <tiles-wrapper content="customers" class="tiles-content-wrapper" allow-drag="false" \
                            selectable="true" api="customersTileApi" is-loading-wrapper="isLoadingWrapper" \
                            double-click="doubleClicked" selected-tile="tilesSelectedCustomerWrapper" \
                            track-by="CustomerId" lazy-tooltip="tilesTooltip()">\
                        </tiles-wrapper>\
                    </div>'

    };
  }]);

/********************************* Animations *********************************/
commonControlsModule.directive("hideMe", function ($animate, $interpolate) {
  return {
    scope: {
      hideMe: '='
    },
    link: function (scope, element, attrs) {
      scope.$watch('hideMe', function (newVal) {
        var isAllExpressionsAreTrue = true;
        if (typeof newVal === "boolean") {
          isAllExpressionsAreTrue = newVal;
        } else if (typeof newVal === "object") {
          angular.forEach(newVal, function (key, value) {
            isAllExpressionsAreTrue = isAllExpressionsAreTrue && (key == value);
            if (!isAllExpressionsAreTrue) {
              return false;
            }
          });
        }

        if (isAllExpressionsAreTrue) {
          $animate.addClass(element, "zero-opacity");
        } else {
          $animate.removeClass(element, "zero-opacity");
        }
      });
    }
  };
});

commonControlsModule.directive("showMe", function ($animate, $interpolate) {
  return {
    scope: {
      showMe: '='
    },
    link: function (scope, element, attrs) {
      scope.$watch('showMe', function (newVal) {
        var isAllExpressionsAreTrue = true;
        if (typeof newVal === "boolean") {
          isAllExpressionsAreTrue = newVal;
        } else if (typeof newVal === "object") {
          angular.forEach(newVal, function (key, value) {
            isAllExpressionsAreTrue = isAllExpressionsAreTrue && (key == value);
            if (!isAllExpressionsAreTrue) {
              return false;
            }
          });
        }

        if (isAllExpressionsAreTrue) {
          $animate.addClass(element, "fadeIn");
        } else {
          $animate.addClass(element, "fadeOut");
          $animate.removeClass(element, "fadeIn");
        }
      });
    }
  };
});

commonControlsModule.animation(".fadeIt", function () {
  return {
    addClass: function (element, className) {
      element.addClass('zero-opacity');
    },
    removeClass: function (element, className) {
      element.removeClass('zero-opacity');
    }
  };
});
//
//commonControlsModule.animation(".fadeIn", function () {
//    return {
//        addClass: function (element, className) {
//            TweenMax.to(element, 0.3, { delay: 1, visibility: 'visible', height: 160, width: 160, opacity: 1 });
//        },
//        removeClass: function (element, className) {
//            TweenMax.to(element, 0.5, { opacity: 0, visibility: 'hidden', height: 0, width: 0 });
//        }
//    };
//});

commonControlsModule.directive("bouncingAnimation", function ($animate, $interpolate) {
  return {
    scope: {
      bouncingAnimation: '='
    },
    restrict: 'A',
    replace: true,
    link: function (scope, element, attrs) {
      scope.$watch('bouncingAnimation', function (newVal) {
        if (newVal) {
          element.removeClass('paused');
        } else {
          element.addClass('paused');
        }


      });
    },
    template: '<div class="ball-animation paused"></div>'

  };
});

commonControlsModule.directive("roundCircleLoader", function () {
  return {
    scope: {
      roundCircleLoader: '='
    },
    restrict: 'A',
    replace: true,
    link: function (scope, element, attrs) {
    },
    template: '<div class="round-circle-loader" ng-class="{\'paused-children\': !roundCircleLoader}">\
                <ul>\
	                <li></li>\
	                <li></li>\
	                <li></li>\
	                <li></li>\
	                <li></li>\
	                <li></li>\
	                <li></li>\
	                <li></li>\
	                <li></li>\
                </ul>\
            </div>'

  };
});

commonControlsModule.directive('backImg', function () {
  return {
    scope: {
      backImg: '='
    },
    link: function (scope, element, attrs) {
      var initialized = false;
      scope.$watch('backImg', function (newValue, oldValue) {
        if (newValue !== oldValue || (!initialized && newValue)) {
          initialized = true;
          element.css({
            'background-image': newValue ? 'url(' + newValue + ')' : '',
          });

          if (angular.isDefined(attrs.validateImage) && attrs.validateImage !== 'false') {
            element.removeClass('back-img-loaded back-img-error');
            const img = new Image();
            img.src = newValue;
            img.onload = () => {
              element.addClass('back-img-loaded')
            };

            img.onerror = () => {
              element.addClass('back-img-error')
            }
          }
        }
      });
    }
  }
});

commonControlsModule.directive('actionsCircle', function () {
  return {
    replace: true,
    scope: {
      parts: '=',
      backgroundImage: '=',
      innerText: '=',
      coreTooltip: '='
    },
    link: function (scope, element) {
    },
    template: '<div class="actions-circle custom-tooltip-wrapper">\
          <div class="resizeable-canvas"></div>\
          <div class="actions-circle-background" data-value="{{innerText}}" back-img="backgroundImage"></div>\
          <div class="actions-circle-parts">\
            <div class="hold" ng-repeat="part in ::parts">\
              <div class="pie">\
                <div class="slice-contents" ng-click="::part.action()">\
                  <div class="{{::part.icon}}"></div>\
                </div>\
              </div>\
            </div>\
          </div>\
          <div class="custom-tooltip">{{coreTooltip}}</div>\
       </div>'
  }
});


commonControlsModule.directive("customTextArea", function ($timeout, localize) {
  return {
    scope: {
      disabled: '@',
      invalidWrapper: '=',
      contentWrapper: '=',
      insertContentWrapper: '=',
      placeholder: '@'
      // maxSize: '@' FROM ATTRS
    },
    restrict: 'E',
    replace: true,
    link: function (scope, element, attrs) {
      var isDisabled = false;
      var actualContentEl = element.find('.custom-text-area-content');
      scope.isInvalidContent = false;

      if (attrs.maxSize) {
        var maxSize = parseInt(attrs.maxSize);
        if (maxSize > 0) {
          scope.$watch('contentWrapper.content', function (newVal) {
            scope.contentWrapper.text = $("<span>" + newVal + "</span>").text();
            scope.hasImagesAsContent = actualContentEl.find('.centered-background-image').length > 0;
            validateContentSize();
          });
        }
      }

      if (attrs.placeholder) {
        scope.localizedPlacedholder = localize.getLocalizedString(attrs.placeholder);
      }

      if (attrs.focusOnLoad == 'true') {
        $timeout(function () {
          scope.focusText();
        });
      }

      scope.$watchOnce('insertContentWrapper', function (newVal) {
        newVal.insertContent = function (html) {
          $timeout(function () {
            scope.contentWrapper.content += html;
            validateContentSize();
            $timeout(function () {
              scope.focusText();
            });
            //                       scope.focusText();
            //                       $timeout(function() {
            //                           actualContentEl.append(html);
            //                           scope.$digest();
            //                           $timeout(function() {
            //                               scope.focusText();
            //                               validateContentSize();
            //                           });
            //                       });
          });

        };
      });

      scope.$watch('disabled', function (newVal) {
        var newState = false;
        if (newVal == 'true') {
          newState = true;
        }

        if (newState != isDisabled) {
          isDisabled = newState;
          element.attr('contenteditable', isDisabled);
        }
      });

      scope.focusText = function () {
        //                actualContentEl.focus();
        actualContentEl.trigger('focus');
      };

      function validateContentSize() {
        var isCurrentlyValid = actualContentEl.html().length > maxSize;
        if (isCurrentlyValid != scope.isInvalidContent) {
          scope.isInvalidContent = isCurrentlyValid;
          if (scope.invalidWrapper) {
            scope.invalidWrapper.invalid = isCurrentlyValid;
          }
        }
      }

      function pasteHtmlAtCaret(html, selectPastedContent) {
        var sel, range;
        if (window.getSelection) {
          // IE9 and non-IE
          sel = window.getSelection();
          if (sel.getRangeAt && sel.rangeCount) {
            range = sel.getRangeAt(0);
            range.deleteContents();

            // Range.createContextualFragment() would be useful here but is
            // only relatively recently standardized and is not supported in
            // some browsers (IE9, for one)
            var el = document.createElement("div");
            el.innerHTML = html;
            var frag = document.createDocumentFragment(), node, lastNode;
            while ((node = el.firstChild)) {
              lastNode = frag.appendChild(node);
            }
            var firstNode = frag.firstChild;
            range.insertNode(frag);

            // Preserve the selection
            if (lastNode) {
              range = range.cloneRange();
              range.setStartAfter(lastNode);
              if (selectPastedContent) {
                range.setStartBefore(firstNode);
              } else {
                range.collapse(true);
              }
              sel.removeAllRanges();
              sel.addRange(range);
            }
          }
        } else if ((sel = document.selection) && sel.type != "Control") {
          // IE < 9
          var originalRange = sel.createRange();
          originalRange.collapse(true);
          sel.createRange().pasteHTML(html);
          if (selectPastedContent) {
            range = sel.createRange();
            range.setEndPoint("StartToStart", originalRange);
            range.select();
          }
        }
      }

    },
    template: '<div class="relative-container custom-text-area" ng-class="{\'invalid-content\': isInvalidContent, \
                                \'empty\': (contentWrapper.text.length == 0 && !hasImagesAsContent)}">\
                        <div class="custom-text-area-placeholder prevent-selection" ng-click="focusText()" disable-click-effect="true" \
                         bindonce="localizedPlacedholder" bo-bind="localizedPlacedholder"></div>\
                        <div class="custom-text-area-content relative-container" \
                         ng-model="contentWrapper.content" \
                        contenteditable strip-br="true"></div>\
                        <div class="custom-text-area-invalid-icon">\
                            <span class="glyphicon glyphicon-warning-sign"></span>\
                            <span data-i18n="_ContentTooLong_"></span>\
                        </div>\
                    </div>'
  };
});

commonControlsModule.directive('contenteditable', function () {
  return {
    restrict: 'A', // only activate on element attribute
    require: '?ngModel', // get a hold of NgModelController
    link: function (scope, element, attrs, ngModel) {
      if (!ngModel) {
        return;
      } // do nothing if no ng-model

      // Specify how UI should be updated
      ngModel.$render = function () {
        element.html(ngModel.$viewValue || '');
      };

      // Listen for change events to enable binding
      element.on('blur keyup change', function () {
        scope.$apply(read);
      });
      read(); // initialize

      // Write data to the model
      function read() {
        var html = element.html();
        // When we clear the content editable the browser leaves a <br> behind
        // If strip-br attribute is provided then we strip this out
        if (attrs.stripBr && html == '<br>') {
          html = '';
        }
        ngModel.$setViewValue(html);
      }
    }
  };
});

commonControlsModule.directive('lazyCreate', [
  'ngIfDirective', '$timeout', function (ngIfDirective, $timeout) {
    var ngIf = ngIfDirective[0];

    return {
      transclude: ngIf.transclude,
      priority: ngIf.priority,
      terminal: ngIf.terminal,
      restrict: ngIf.restrict,
      link: function ($scope, $element, $attr) {
        var timeout = $attr['lazyCreate'];
        var theCondition = false;
        if (timeout) {
          timeout = parseInt(timeout)
        } else {
          timeout = 100;
        }

        $timeout(function () {
          theCondition = true;
        }, timeout);

        $attr.ngIf = function () {
          return theCondition;
        };
        ngIf.link.apply(ngIf, arguments);
      }
    };
  }]);

commonControlsModule.directive('ngIfShow', [
  'ngIfDirective', '$animate', function (ngIfDirective, $animate) {
    var ngIf = ngIfDirective[0];
    var NG_HIDE_CLASS = 'ng-hide';
    var NG_HIDE_IN_PROGRESS_CLASS = 'ng-hide-animate';

    return {
      transclude: ngIf.transclude,
      priority: ngIf.priority,
      terminal: ngIf.terminal,
      restrict: ngIf.restrict,
      multiElement: true,
      link: function ($scope, $element, $attr) {
        var wasTrueAlready = false;
        var watchFunc = undefined;

        $attr.ngIf = function () {
          if (wasTrueAlready && watchFunc) {
            return true;
          }

          if (!wasTrueAlready) {
            return (wasTrueAlready = $scope.$eval($attr.ngIfShow));
          } else if (!watchFunc) {
            watchFunc = $scope.$watch($attr.ngIfShow, function (newVal, oldVal) {
              if (newVal !== oldVal) {
                $animate[newVal ? 'removeClass' : 'addClass']($element.next(), NG_HIDE_CLASS, {
                  tempClasses: NG_HIDE_IN_PROGRESS_CLASS
                });
              }
            })

            return true;
          }
        };

        ngIf.link.apply(ngIf, arguments);
      }
    };
  }]);

commonControlsModule.directive('localizedPlaceholder', [
  'localize', function (localize) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        if (attrs.localizedPlaceholder) {
          element.attr('placeholder', localize.getLocalizedString(attrs.localizedPlaceholder));
        }
      }
    };
  }]);

commonControlsModule.directive("pageLoadingMask", [
  '$rootScope', function ($rootScope) {
    return {
      restrict: 'E',
      replace: 'true',
      link: function (scope) {
        $rootScope.$on('page-in-progress', function (_, isLoading) {
          scope.loading = !angular.isDefined(isLoading) || isLoading == true;
        });
      },
      template: '<div class="page-loading-mask absolute-container" ng-class="{\'masking\': loading}">\
                        <div class="page-mask relative-container"></div>\
                        <div class="page-loading-mask-progress" ng-if="loading">\
                            <div class="loader"></div>\
                        </div>\
                    </div>'

    };
  }]);

commonControlsModule.directive("materialLoader", [
  '$rootScope', function () {
    return {
      restrict: 'E',
      replace: 'true',
      scope: {
        isLoading: '=',
        zIndex: '=',
        primaryColor: '='
      },
      link: function (scope, element) {
        if (angular.isDefined(scope.zIndex)) {
          scope.$watch('isLoading', function (newVal) {
            if (newVal) {
              element.parent().find('.material-loader').css('z-index', scope.zIndex);
            }
          });
        }

        scope.$watch('primaryColor', (newVal) => {
          if (newVal) {
            element.parent().find('.material-loader').addClass('primary-color')
          }
        })
      },
      template: `<div class="material-loader" ng-if="isLoading">
                        <div class="page-loading-mask-progress">
                            <div class="loader"></div>
                        </div>
                    </div>`

    };
  }]);


commonControlsModule.directive("buttonsPicker", function (analytics) {
  return {
    restrict: 'E',
    replace: 'true',
    scope: {
      selectedWrapper: '=',
      options: '=',
      disabled: '=',
      onSelect: '='
    },
    link: function (scope) {
      scope.onClick = function (option) {
        if (!scope.disabled) {
          scope.selectedWrapper.selected = option;
          if (scope.onSelect) {
            scope.onSelect(option);
          }

          analytics.trackEvent('buttons-picker', 'click', option.name);

        }
      };
    },
    template: '<div class="buttons-picker" ng-class="{\'disabled\': disabled}">\
                        <div ng-repeat="option in options" class="buttons-picker-item"\
                        ng-click="::onClick(option)"\
                        ng-class="option == selectedWrapper.selected && \'selected\'">{{::option.name}}</div>\
                    </div>'

  };
});

commonControlsModule.directive('onEscape', function () {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      var randomEventName = "keydown.Escape" + Math.floor((Math.random * 100));
      scope.$watch(attrs.onEscapeActive, function (newVal) {
        if (angular.isDefined(newVal)) {
          registerEscape(!newVal);
        }
      });

      scope.$on('$destroy', function () {
        registerEscape(true);
      });

      function registerEscape(unregister) {
        if (!unregister) {
          $(document).on(randomEventName, function (e) {
            if (e.which == 27) {
              scope.$apply(attrs.onEscape);
            }
          });
        } else {
          $(document).off(randomEventName);
        }
      }
    }
  };
});

commonControlsModule.directive('insertAtCaretDelegate', function ($timeout) {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      var delegate = scope.$eval(attrs.insertAtCaretDelegate);
      delegate.insert = function (text) {
        if (text) {
          // element[0].insertAtCaret(text);
          if (document.queryCommandSupported('insertText')) {
            document.execCommand('insertText', false, text);
          } else {
            const textarea = element[0]
            let selection = {'start': textarea.selectionStart, 'end': textarea.selectionEnd};
            element[0].val(textarea.val().substring(0, selection.start) + text + textarea.val().substring(selection.end, textarea.val().length));
          }
        }

        $timeout(function () {
          element.focus();
        });

        return element.val();
      }
    }
  }
});

commonControlsModule.directive("simpleStarRating", function () {
  return {
    restrict: "E",
    scope: {
      value: "=",
      max: "=?",
      min: "=?",
      stars: "=?",
      step: "=?",
      readOnly: "=?",
      size: "@"
    },
    link: function (scope, element) {
      element.rating({
        showCaption: false, showClear: false, hoverEnabled: false, size: (scope.size ? scope.size : 'sm'),
        readonly: scope.readOnly
      });

      element.on('rating:change', function (e, value/*, caption*/) {
        scope.$apply(function () {
          scope.value = value;
        })
      });

      scope.$watch('value', function (newVal) {
        element.rating('update', newVal);
      });
    },
    template: '<input type="number" class="simple-star-rating" min="{{::(min ? min : 0)}}" max="{{::(max ? max : 5)}}" \
                      value="3" \
                      step="{{::(step ? step : 1)}}" \
                      stars="{{::(stars ? stars : 5)}}">'
  };
});


commonControlsModule.directive('employeeDaysOfWorkPicker', [
  'localize', function (localize) {
    return {
      restrict: 'E',
      scope: {
        daysOfWork: '=',
        isValidDayOfWork: '='
      },
      link: function (scope, element, attrs) {
        scope.daysOfWorkNames = [
          localize.getLocalizedString("_Sun_"),
          localize.getLocalizedString("_Mon_"),
          localize.getLocalizedString("_Tue_"),
          localize.getLocalizedString("_Wed_"),
          localize.getLocalizedString("_Thu_"),
          localize.getLocalizedString("_Fri_"),
          localize.getLocalizedString("_Sat_")];

        scope.$watchOnce('daysOfWork', function () {
          angular.forEach(scope.daysOfWork, function (day) {
            day.name = scope.daysOfWorkNames[day.Index];
          });
        });

        scope.close = function (day) {
          day.isHoursPopupVisible = false;
        };
      },
      template: '<div class="employee-days-of-work-wrapper" ng-repeat="day in daysOfWork" bindonce>\
                            <div class="employee-days-of-work-day-selector-wrapper" ng-class="{\'invalid\': !isValidDayOfWork(day)}">\
                                <h4 class="employee-days-of-work-day-name"><span i18n="_DayOfWeek_"></span>&nbsp;<span>{{::day.name}}\'</span></h4>\
                                <checkbox class="employee-days-of-work-cb" string-text="" active="day.IsEnabled"></checkbox>\
                                <jtimepicker class="employee-days-of-work-time" ng-show="day.IsEnabled" time="day.StartTime" options="{hideButtons: true, hideCurrentTime: true}"></jtimepicker>\
                                <div class="until-label" i18n="_UntilTime_" ng-if="day.IsEnabled"></div>\
                                <jtimepicker class="employee-days-of-work-time" ng-show="day.IsEnabled" time="day.EndTime" options="{hideButtons: true, hideCurrentTime: true}"></jtimepicker>\
                            </div>\
                    </div>'
    };
  }]);

commonControlsModule.directive("delayAppearance", [
  '$timeout', function ($timeout) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        element.addClass('invisible');
        let delay = parseInt(attrs.delayAppearance);
        if (isNaN(delay)) {
          delay = 500;
        }

        $timeout(function () {
          element.removeClass('invisible');
        }, delay)
      }
    };
  }]);

commonControlsModule.directive("circleImage", function () {
  return {
    restrict: 'A',
    scope: {
      images: '=circleImage',
      defaultImage: '=',
      defaultIcon: "@"
    },
    link: function (scope, element, attrs) {
      var icon = scope.defaultIcon || 'icon icon-clients-ol';

      element.append('<div class="' + icon + '"></div>');
      element.addClass('circle-image');
      scope.$watch('images', function (newVal) {
        if (Array.isArray(newVal) && newVal.length > 0) {
          //TODO handle default image.
          var imageUrl = newVal[0].FilePath;
          element.append('<div class="circle-background-image" style="background-image: url(\'' + window.filesURI + imageUrl + '\')"></div>');

        } else if (angular.isString(newVal)) {
          element.append('<div class="circle-background-image" style="background-image: url(\'' + window.filesURI + newVal + '\')"></div>');

        }
      });
    }
  };
});

commonControlsModule.directive("appendClassOnChange", function () {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      var lastPickedCustomer = null;
      scope.$watch(attrs.appendClassOnChange, function (newVal) {
        if (newVal && newVal != lastPickedCustomer) {
          lastPickedCustomer = newVal;
          var classToAppend = attrs.classToAppend;
          element.removeClass(classToAppend);
          setTimeout(function () {
            element.addClass(classToAppend);
            if (attrs.removeAfter) {
              setTimeout(function () {
                element.removeClass(classToAppend);
              }, attrs.removeAfter)
            }
          })
        }
      })
    }
  };
});

commonControlsModule.directive("focusOnHover", function () {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      let eventDelegate;
      element.on('mouseover', () => {
        eventDelegate = setTimeout(() => {
          eventDelegate = null;
          element.focus();
          element.select();
        }, 100);
      }).on('mouseleave', () => {
        if (eventDelegate) {
          clearTimeout(eventDelegate);
          eventDelegate = null;
        }
      })

    }
  };
});


commonControlsModule.directive('tippy', function (localize) {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      let instance = null;
      let localized = null;
      attrs.$observe('tippy', (newVal) => {
        localized = newVal;

        if (newVal && newVal.indexOf('_') === 0) { // translate
          localized = localize.getLocalizedString(newVal);
        }

        if (instance) {
          instance.setContent(localized);
        } else {
          createTippy();
        }
      });


      let disabled = false;
      attrs.$observe('tippyDisabled', (newVal) => {
        if (newVal === 'true') {
          disabled = true;
          destroy();
        } else if (disabled) {
          disabled = false;
          createTippy();
        }
      });

      scope.$on('$destroy', destroy);

      function destroy() {
        if (instance) {
          instance.destroy();
          instance = null;
        }
      }

      function createTippy() {
        instance = Tippy(element[0], {
          content: localized,
          lazy: attrs.lazy === 'true',
          delay: attrs.delay ? parseInt(attrs.delay) : 0,
          placement: attrs.placement || 'bottom'
        });
      }

    }
  };
});

commonControlsModule.directive("iframeRouteChange", function () {
  return {
    restrict: 'A',
    scope: {
      iframeRouteChange: '='
    },
    link: function (scope, element) {
      const routeChangedEvent = (event) => {
        if (event.data.event_id === 'route-changed') {
          return scope.$evalAsync(() => scope.iframeRouteChange(event.data.data.newRoute));
        }
      };

      window.addEventListener('message', routeChangedEvent);
      scope.$on('$destroy', () => window.removeEventListener('message', routeChangedEvent))
    }
  };
});


commonControlsModule.directive("iframeSrcGetter", function () {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      const getter = scope.$eval(attrs.iframeSrcGetter);
      if (getter) {
        getter.get = () => {
          return element[0].getAttribute('src');
        }
      }
    }
  };
});
