import './cash.register.activities.modal.less';
import {DATE_DISPLAY_FORMAT, ODATA_DATE_ONLY_FORMAT, SERVER_DATA_DATE_FORMAT} from "../../constants";
import {isNonEmptyArray, valueOrDefault} from "@tomeravni/easybizy-js-common/common";

angular.module('easybizy.easybizyModalViewsModel').controller('CashRegisterActivitiesModalController',
  function ($scope, $modalInstance, $timeout, $http, localize, Repository, printerMediator,
            configuration, creditCardReaderFactory, $state, confirmService, cancelInvoiceService, $rootScope) {

    var configurations = configuration.get();
    var configuredCurrency = configurations.CashRegisterSettings.Currency.Value;
    $scope.isNewModeCashRegister = configurations.CashRegisterSettings.UseNewPosModel;
    $scope.enableChangingEmployeeSales = configurations.EmployeesSettings.EnableChangingEmployeeSales;
    var kInvoiceYetToBeGenerated = localize.getLocalizedString('_NotGeneratedYet_');
    $scope.invoiceByMail = true;


    var isRightToLeft = localize.isRightToLeft();

    var ifNoActionDoneFunc = function () { // after 1 minute go back to mode one.
      $scope.refundText = localize.getLocalizedString("_IssueRefund_");
      $scope.refundMode = 0;
    };

    $scope.mobileMode = isMobilePlatform();

    $scope.invoicesSummaryFields = [
      {
        name: "Total", width: "72%", type: 'IconWithValue', command: {
          get: function (entity) {
            var textToReturn = "(" + entity.TotalAmount.toFixed(2).toMoney(entity.Currency, configuredCurrency) + ")";
            return {
              text: isRightToLeft ?
                textToReturn + " " + entity.InvoiceNumber :
                entity.InvoiceNumber + " " + textToReturn,
              image: paymentTypeToIcon(entity.PaymentMajorType)
            };
          }

        }
      },
      {
        name: "Hour", width: "28%", fieldName: 'CreatedOn', orderedBy: true, isDecending: true, command: {
          get: function (entity) {
            var createdOn = moment(entity.CreatedOn, SERVER_DATA_DATE_FORMAT);
            var toReturn = "";
            if (createdOn.isSame(moment(), 'day')) {
              toReturn = createdOn.format("HH:mm");
            } else {
              toReturn = createdOn.format("DD-MM-YYYY");
            }

            return toReturn;
          },
          sort: function () {
            return "CreatedOn";
          }
        }
      }
    ];

    /**
     *
     * {
     "TaxInvoiceId": 1,
     "TaxInvoiceNumber": "000001",
     "DueDate": "05/10/2018 18:31",
     "Amount": 90.00
     },
     {
     "TaxInvoiceId": 2,
     "TaxInvoiceNumber": null,
     "DueDate": "06/10/2018 12:00",
     "Amount": 90.00
     },
     */
    const generateLocalized = localize.getLocalizedString("_GenerateInvoiceTitle_");
    $scope.taxInvoiceFields = [
      {
        name: localize.getLocalizedString("_Index_"),
        fieldName: 'TaxInvoiceId',
        width: "10%"
      },
      {
        name: localize.getLocalizedString("_InvoiceNumber_"),
        fieldName: 'TaxInvoiceNumber',
        width: "20%",
        command: {
          get: function (entity) {
            return entity.TaxInvoiceNumber || kInvoiceYetToBeGenerated;
          }
        }
      },
      {
        name: localize.getLocalizedString("_Amount_"),
        width: "10%",
        fieldName: 'Amount'
      },
      {
        name: localize.getLocalizedString("_DueDate_"),
        width: "15%",

        fieldName: 'DueDate',
        command: {
          get: function (entity) {
            return moment(entity.DueDate, SERVER_DATA_DATE_FORMAT).format('LL');
          }
        }
      },
      {
        name: localize.getLocalizedString("_Actions_"),
        width: '45%',
        type: "Custom",
        template: `<div class="activities-tax-invoice-actions flex-container align-flex-center flex-justify-end" ng-class="!content.model.entity.TaxInvoiceNumber && 'relative-container'">
                      <digital-invoice-short ng-if="content.model.entity.TaxInvoiceNumber"
                              invoice-id="content.model.entity.TaxInvoiceId"
                              entity-type="content.model.entity.EntityType"
                              expandable="true"/>
                      <button ng-if="!content.model.entity.TaxInvoiceNumber && content.model.entity.TaxInvoiceId"
                              class="digital-invoice-sender-action digital-invoice-short-action--pdf icon icon-stamp-streamline"
                              tippy="${generateLocalized}"
                              ng-click="generateTaxInvoice(content.model.entity, content.model.entity.TaxInvoiceId)"></button>
                   </div>`,
        extendScopeWith: {
          generateTaxInvoice(entity, taxInvoiceId) {
            return confirmService.confirm(localize.getLocalizedString('_GenerateInvoiceMessage_'),
              null,
              () => {
                $rootScope.$emit('page-in-progress', true);
                Repository.Custom('CashRegister').produceTaxInvoice(taxInvoiceId)
                  .then(() => {
                    $scope.selectedInventoryWrapper.selected.expanded = false;
                    loadFullInvoice($scope.selectedInventoryWrapper.selected);
                    $rootScope.$emit('page-in-progress', false);
                    toastr.success(localize.getLocalizedString('_InvoiceGenerated_'));
                  }).catch((err) => {
                  $rootScope.$emit('page-in-progress', false);
                  toastr.error(e.Message || e.data.Message);
                })
              }, null, localize.getLocalizedString('_Yes_'), localize.getLocalizedString('_No_'));
          }
        },
        command: {
          get: function (entity) {
            return function (scope, action) {
              if (action === 'print') {
                Repository.Custom('CashRegister').printTaxInvoice(entity.TaxInvoiceId).then(function (data) {
                  printerMediator.print(data.results[0].Receipt);
                }).catch(function (err) {
                  var errorMessage = (err && err.Message && localize.getLocalizedString('_GeneralError_', err && err.Message))
                    || localize.getLocalizedString('_UnknownError_');
                  toastr.error(errorMessage);
                });
              }
            };
          }
        }
      }
    ];

    $scope.invoiceSummaryDecorator = function (scope, element) {
      if (scope.item.IsToBeCancel) {
        element.addClass("invoice-summary-to-cancel");
      }
    };

    if ($scope.enableChangingEmployeeSales/*TODO: Is readonly? Is Single employee.*/) {
      $scope.employees = [];
      Repository.Entity("Employee").query().filter("IsDeactiveted eq false").get().then(function (employees) {
        $scope.employees.pushAll(employees.results.map(function (employee) {
          return {
            name: employee.FirstName + " " + employee.LastName,
            value: employee.EmployeeId
          };
        }));
      }).catch(function (err) {
        console.log('error loading employees' + err.Message);
      });
    }


    // Deal with refund!

    $scope.refundText = localize.getLocalizedString("_IssueRefund_");
    $scope.refundedCreditPaymentsIds = [];
    $scope.searchInvoicePlaceHolder = localize.getLocalizedString("_SearchInvoice_");


    $scope.refundClicked = function () {
      if (!$scope.selectedInventoryWrapper.selected.Payments.some((p) => p.PaymentType === 'Credit')) {
        return confirmService.confirm(localize.getLocalizedString('_AreYouSureYouWantToCancel_'),
          null,
          () => {
            cancelInvoice();
          }, null, localize.getLocalizedString('_Yes_'), localize.getLocalizedString('_No_'));
      }


      cancelInvoiceService.show($scope.selectedInventoryWrapper.selected.InvoiceId);
    };

    $scope.printInvoiceCopy = function () {
      var id = $scope.selectedInventoryWrapper.selected.InvoiceId;
      $scope.isPrintingCopy = true;
      Repository.Custom("CashRegister").invoiceReceipt(id)
        .then(function (data) {
          printerMediator.print(data.results[0].Receipt);
          $scope.isPrintingCopy = false;
          $scope.$digestIfNeeded();
        })
        .catch(function (errorMessage) {
          toastr.error(localize.getLocalizedString("_ErrorPrinting_", errorMessage));
          $scope.isPrintingCopy = false;
          $scope.$digestIfNeeded();
        });
    };

    if ($scope.invoiceByMail) {
      $scope.toggleMailInvoice = function () {
        $scope.sendByEmailCustomerAddress = $scope.selectedInventoryWrapper.selected.Customer.EmailAddress;

        $scope.sendByEmailMode = !$scope.sendByEmailMode;
      };


      $scope.sendInvoiceByMail = function (email) {
        var invoiceId = $scope.selectedInventoryWrapper.selected.InvoiceId;
        $scope.sendingInvoice = true;
        Repository.Custom("CashRegister").sendByMail(getMailObject(invoiceId, email))
          .then(function (data) {
            if (data.results[0].Result.Succeeded) {
              toastr.success(localize.getLocalizedString("_InvoiceSentByMail_"));
            } else {
              toastr.error(localize.getLocalizedString("_ErrorSendingInvoice_") + " " + data.results[0].Message);
            }
            $scope.sendingInvoice = false;
            $scope.sendByEmailMode = !$scope.sendByEmailMode;
            $scope.$digestIfNeeded();

          }).catch(function (e) {
          toastr.error(localize.getLocalizedString("_ErrorSendingInvoice_"));
          $scope.sendingInvoice = false;
          $scope.sendByEmailMode = !$scope.sendByEmailMode;
          $scope.$digestIfNeeded();
        });
      };
    }

    function getMailObject(id, emailAddress) {
      return {
        Id: id,
        To: [emailAddress],
        CC: null,
        Subject: localize.getLocalizedString("_MailInvoiceSubject_", configurations.BusinessDetails.BusinessName.Value),
        Message: localize.getLocalizedString("_MailInvoiceSubject_", configurations.BusinessDetails.BusinessName.Value)
      };
    }

    //When there are no credit payments make the main invoice refund
    let isCancellingInvoice = false;

    function cancelInvoice() {
      if (isCancellingInvoice) {
        return;
      }

      isCancellingInvoice = true;
      $scope.isCancellingInvoice = true;
      let refundCall = !$scope.selectedInventoryWrapper.selected.OriginFromTaxInvoices ?
        Repository.Custom("CashRegister").cancelInvoice($scope.selectedInventoryWrapper.selected.InvoiceId, $scope.refundedCreditPaymentsIds) :
        Repository.Custom("CashRegister").cancelReceipt($scope.selectedInventoryWrapper.selected.InvoiceId, $scope.refundedCreditPaymentsIds);
      refundCall
        .then(function (data) {
          //todo: this doesn't have to print better if we enable to send it
          printerMediator.print(data.results[0].Receipt);
          $modalInstance.dismiss('cancel');
          $scope.isCancellingInvoice = false;
          isCancellingInvoice = false;
          $scope.$digestIfNeeded();
        })
        .catch(function (result) {
          toastr.error(localize.getLocalizedString("_ErrorCancelling_", localize.getLocalizedString("_Invoice_")) + " - " + result.data.ExceptionMessage);
          $scope.isCancellingInvoice = false;
          isCancellingInvoice = false;
          $scope.$digestIfNeeded();
        });
    }


    // End of dealing with refund.
    $scope.cashIcon = Globalize.culture().language === 'he' ? 'icon-banknote-aligned-shekel' : 'icon-banknote-aligned-dollar';
    $scope.isLoadingWrapper = {};
    $scope.isLoadingWrapper.isLoading = false;
    $scope.invoicesSummary = [];
    $scope.api = Repository.Custom("CashRegisterActivities").query();
    $scope.api.pageSize = 20;

    $scope.selectedInventoryWrapper = {};

    $scope.filtersModel = {};
    $scope.filtersModel.filters = [
      {type: "employees"}
    ];

    $scope.searchInvoiceWrapper = {};
    $scope.searchInvoiceWrapper.searchText = "";
    var searchTimerId = null;
    $scope.$watch('searchInvoiceWrapper.searchText', function (newVal, oldVal) {
      if (newVal &&
        newVal.length > 2 || (oldVal && oldVal.length > 0)) {
        if (searchTimerId) {
          $timeout.cancel(searchTimerId);
          searchTimerId = null;
        }

        searchTimerId = $timeout(searchFunction, 700);
      }
    });

    function searchFunction() {
      var text = $scope.searchInvoiceWrapper.searchText;
      if (moment(text, "DD/MM/YYYY", true).isValid()) {
        text = moment(text, "DD/MM/YYYY", true).format(ODATA_DATE_ONLY_FORMAT);
      } else if (moment(text, "DD-MM-YYYY", true).isValid()) {
        text = moment(text, "DD-MM-YYYY", true).format(ODATA_DATE_ONLY_FORMAT);
      }
      $scope.api.search(text);
      $scope.api.forceUpdate();
    }

    $scope.getInvoiceTypeIcon = function () {
      return $scope.selectedInventoryWrapper.selected ? paymentTypeToIcon($scope.selectedInventoryWrapper.selected.PaymentMajorType) : "";
    };

    $scope.getValue = function (entity, field) {
      console.log(entity, field);
    };

    var beforeDiscountFieldName = localize.getLocalizedString("_BeforeDiscount_") + ": ";
    var taxInvoiceNumberFieldName = localize.getLocalizedString("_TaxInvoiceNumber_") + ": ";
    var invoiceNumberFieldName = localize.getLocalizedString("_InvoiceNumber_") + ": ";
    var receiptNumberFieldName = localize.getLocalizedString("_ReceiptNumber_") + ": ";
    var dealInvoiceNumberFieldName = localize.getLocalizedString("_DealInvoiceNumber_") + ": ";

    var discountFieldName = localize.getLocalizedString("_Discount_");

    $scope.invoiceDetailsFields = [
      {
        icon: function () {
          return 'icon icon-clients-ol';
        },
        value: function (entity) {
          if (!entity) {
            return null;
          }

          var toReturn = entity.InvoiceCustomerName;
          if (toReturn == null || toReturn.length == 0) {
            toReturn = localize.getLocalizedString("_NoCustomer_");
          }

          return toReturn;
        }
      },
      {
        icon: function () {
          return 'icon icon-employee';
        },
        value: function (entity) {
          if (!entity) {
            return null;
          }

          var toReturn = entity.EmployeeName + (entity.InternalNotes ? ' (' + entity.InternalNotes + ')' : '');
          if (toReturn == null || toReturn.length == 0) {
            toReturn = localize.getLocalizedString("_NoEmployee_");
          }

          return toReturn;
        }
      },
      {
        icon: function (scope) {
          return scope.getInvoiceTypeIcon();
        },
        value: function (entity) {
          if (!entity || !entity.Payments) {
            return null;
          }

          let paymentSentence = '';
          entity.Payments.forEach((payment, index) => {
            paymentSentence += localize.getLocalizedString("_" + payment.PaymentType + "_");
            if (payment.PaymentType === 'Cheque') {
              paymentSentence += paymentToChequeDetails(payment);
            } else if (payment.PaymentType === 'Credit' && payment.NumberOfCharges > 1) {
              paymentSentence += ' (' + payment.NumberOfCharges + ' ' + localize.getLocalizedString("_Payments_") + ')';
            } else if (payment.PaymentType === 'External') {
              paymentSentence += paymentToExternalPaymentDetails(payment);
            }

            if (index < entity.Payments.length - 1) {
              paymentSentence += ", ";
            }
          })

          return paymentSentence;

          function paymentToChequeDetails(payment) {
            var toReturn = "";
            if (payment.AuthNumber && payment.PaymentDueDate) {
              var checkNumString = localize.getLocalizedString("_ChequeNumber_");
              var checkDateString = localize.getLocalizedString("_ChequeDate_");
              toReturn += " (" + checkNumString + ": " + payment.AuthNumber
                + ", " + checkDateString + ": " + moment(payment.PaymentDueDate).format(DATE_DISPLAY_FORMAT)
                + ")";
              +")";
              +")";
            }

            return toReturn;

          }

          function paymentToExternalPaymentDetails(payment) {
            var toReturn = "";
            if (payment.AuthNumber && payment.ExternalName) {
              var externalPaymentNumber = localize.getLocalizedString("_ExternalPaymentNumber_");
              var paymentType = localize.getLocalizedString("_PaymentType_");
              toReturn += " (" + externalPaymentNumber + ": " + payment.AuthNumber
                + ", " + paymentType + ": " + payment.ExternalName + ")";
              +")";
              +")";
            }

            return toReturn;

          }
        }
      },
      {
        icon: function () {
          return 'icon icon-discount';
        },
        value: function (entity) {
          if (!entity) {
            return null;
          }


          return beforeDiscountFieldName + entity.TotalAmountBeforeDiscount.toFixed(2).toMoney(entity.Currency, configuredCurrency);
        }
      },
      {
        hideOnNull: true,
        icon: function () {
          return 'icon icon-tip_money tip-color';
        },
        value: function (entity) {
          if (!entity) {
            return null;
          }

          const tipsValue = valueOrDefault(entity.Tips, []).reduce((acc, tip) => acc + tip.Amount, 0).toFixed(2).toMoney(entity.Currency, configuredCurrency);
          return isNonEmptyArray(entity.Tips) ? `${localize.getLocalizedString('_Tip_')} ${tipsValue}` : 0;
        }
      },
      {
        icon: function () {
          return 'icon icon-cashregister-button';
        },
        value: function (entity) {
          if (!entity) {
            return null;
          }

          return (entity.DocumentType === "Receipt" ? receiptNumberFieldName : (entity.DocumentType === "TaxInvoice" ? taxInvoiceNumberFieldName : (entity.DocumentType === "DealInvoice" ? dealInvoiceNumberFieldName : invoiceNumberFieldName))) + entity.InvoiceNumber;
        }
      },
      {
        icon: function () {
          return 'icon icon-clock';
        },
        value: function (entity) {
          if (!entity) {
            return null;
          }


          return moment(entity.CreatedOn, SERVER_DATA_DATE_FORMAT).format("MMMM DD, YYYY  HH:mm");
        }
      },
      {
        icon: function () {
          return 'icon icon-price-quote-action';
        },
        value: function (entity) {
          if (!entity) {
            return null;
          }
          return entity.AllocationNumber ?? entity.AllocationErrorMessage;
        }
      }
    ].filter((field) => field.value);

    $scope.isHiddenDetailItem = (item, value) => {
      if (!item.hideOnNull) {
        return false;
      }

      return !value;
    }


    $scope.reallocateIsraelInvoiceNumber = function () {
      let invoiceId = $scope.selectedInventoryWrapper.selected.InvoiceId
      Repository.Custom("IsraelInvoice").reallocateIsraelInvoiceNumber(invoiceId).then(function (data) {
        $timeout(() => {
          $scope.isGeneratingAllTaxInvoicesWrapper.isLoading = false;
          $scope.selectedInventoryWrapper.selected.expanded = false;
          loadFullInvoice($scope.selectedInventoryWrapper.selected);
        }, 2000);
      }).catch(function (err) {
        $timeout(() => {
          $scope.isGeneratingAllTaxInvoicesWrapper.isLoading = false;
          $scope.selectedInventoryWrapper.selected.expanded = false;
          loadFullInvoice($scope.selectedInventoryWrapper.selected);
        }, 2000);
      });
    }

    $scope.itemsAndServices = [];
    $scope.invoiceLoadingWrapper = {loading: false};
    const loadFullInvoice = function (newVal) {
      if (newVal && !newVal.expanded) {
        $scope.invoiceLoadingWrapper.loading = true;
        var expandCall = !newVal.OriginFromTaxInvoices ?
          Repository.Custom("CashRegisterActivities").expandInvoice(newVal.InvoiceId)
          : Repository.Custom("CashRegisterActivities").expandReceipt(newVal.InvoiceId);
        expandCall
          .then(function (result) {
            var invoice = result;
            newVal.expanded = true;
            Object.assign(newVal, invoice);
            $scope.invoiceLoadingWrapper.loading = false;
            $scope.invoiceContainsAwaitingGenerationTaxInvoices = Array.isArray(result.TaxInvoices)
              && result.TaxInvoices.some((taxInvoice) => !taxInvoice.TaxInvoiceNumber && taxInvoice.TaxInvoiceId);
            assignInvoiceItems(newVal);
          }).catch(function (err) {
          $scope.invoiceLoadingWrapper.loading = false;
          toastr.error(localize.getLocalizedString("_FailedGettingInvoice_", err.Message));
        });
      } else {
        assignInvoiceItems(newVal);
      }

      ifNoActionDoneFunc();
    };


    $scope.$watch('selectedInventoryWrapper.selected', loadFullInvoice);

    $scope.$on('invoice-cancelled', (args, val) => {
      $scope.selectedInventoryWrapper.selected.expanded = false;
      $scope.api.forceUpdate(() => {
        $scope.selectedInventoryWrapper.selected = $scope.invoicesSummary[0];
      });
    });

    $scope.isGeneratingAllTaxInvoicesWrapper = {isLoading: false};
    // TODO: Daniel - Implement.
    $scope.generateAllTaxInvoices = () => {
      $scope.$evalAsync(() => {
        $scope.isGeneratingAllTaxInvoicesWrapper.isLoading = true;
      });

      /// TODO: Daniel - Remove timeout, just for demo.
      $timeout(() => {
        $scope.isGeneratingAllTaxInvoicesWrapper.isLoading = false;
        /// Upon Success...
        $scope.selectedInventoryWrapper.selected.expanded = false;
        loadFullInvoice($scope.selectedInventoryWrapper.selected);
      }, 2000);
    }

    function assignInvoiceItems(invoice) {
      $scope.itemsAndServices.length = 0;
      if (angular.isDefined(invoice)) {
        $scope.itemsAndServices.pushAll(invoice.Products);
        $scope.itemsAndServices.pushAll(invoice.Services);
        $scope.itemsAndServices.pushAll(invoice.ServiceSubscriptionUsages);

        if (invoice.Debt) {
          handleDebt(invoice);
        } else {
          delete $scope.deptText;
          delete $scope.deptAmount;
        }
      }
    }

    /***************************************** HANDLING DEBT *****************************************/

    function handleDebt(invoice) {
      var amount = Math.abs(invoice.Debt).toMoney();
      $scope.deptAmount = invoice.Debt > 0 ? localize.getLocalizedString("_AccumulatedDebt_") + amount :
        localize.getLocalizedString("_DebtPayment_") + amount;
    }

    //$scope.payDebt = function() {
    //  $scope.cancel();
    //  $state.go('CashRegister', {debt: true/*$scope.selectedInventoryWrapper.selected.InvoiceId*/});
    //};
    /***************************************** ENDOF - HANDLING DEBT *****************************************/


    $scope.itemsAndServicesFields = [
      {
        name: "Total", width: "55%", type: 'ImageWithValue',
        iconReplacer: 'icon icon-edit',
        command: {
          get: function (entity) {
            return {
              text: entity.hasOwnProperty('ProductMetadataId') ? entity.ProductLabel : entity.ServiceName,
              image: entity.Image ? window.filesURI + entity.Image : ""
            };
          },
          ignoreWatchingModel: true
        }
      },
      {
        name: "Price", width: "25%", fieldName: 'CreatedOn', orderedBy: true, isDecending: true, command: {
          get: function (entity) {
            var toReturn = "";
            if (entity.Quantity > 1) {
              toReturn += entity.Quantity + " x ";

              toReturn += entity.Price.toFixed(2).toMoney(entity.Currency, configuredCurrency);
              toReturn += " = ";
            }

            toReturn += (entity.Quantity * entity.Price.toFixed(2)).toMoney(entity.Currency, configuredCurrency);

            if (entity.Discount) {
              toReturn += " (" + (entity.Quantity * entity.Discount.toFixed(2)).toMoney(entity.Currency, configuredCurrency) + " " + discountFieldName + ") ";
            }

            return toReturn;
          },
          sort: function () {
            return entity.Price;
          },
          ignoreWatchingModel: true

        }
      }
    ];

    if ($scope.enableChangingEmployeeSales) {
      //enable employee changing
      $scope.itemsAndServicesFields.push({
        name: "EmployeeName",
        width: "20%",
        isDecending: true,
        type: 'Combobox',
        values: $scope.employees,
        command: {
          get: function (entity) {
            var filteredEmployees = null;
            if (entity.EmployeeId) {
              filteredEmployees =
                $scope.employees.filter(function (employeeOption) {
                  return employeeOption.value == entity.EmployeeId;
                });
            }

            return filteredEmployees && filteredEmployees.length > 0 ? filteredEmployees[0] : null;
          },
          manuallySet: (newValue, entity) => {
            entity.EmployeeId = newValue.value;
            changeSaleOfEmployee(entity, newValue.value);
          },
          set: function (newValue, entity) {
          }
        }
      });
    } else {
      //unable to change employees
      $scope.itemsAndServicesFields.push({
        name: "EmployeeName",
        width: "20%",
        isDecending: true,
        command: {
          get: function (entity) {
            return entity.EmployeeName;
          },
          sort: function () {
            return entity.EmployeeName;
          },
          ignoreWatchingModel: true
        }
      });
    }

    $scope.cancel = function () {
      if ($scope.isCancellingInvoice) {
        toastr.warning(localize.getLocalizedString("_CannotExitDuringCancellation_"));
        return;
      }

      $modalInstance.dismiss('cancel');
    };

    $(document).on('keydown.escape', function (e) {
      if (e.which == 27) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        $scope.cancel();
      }
    });

    $scope.manuallyCancellCardValues = {};

    $scope.$watch("isCancellingInvoice", function (newVal) {
      if (angular.isDefined(newVal)) {
        preventRefresh(!newVal);
      }
    });


    $scope.itemsRowDecorator = function (scope, element) {
      if (scope.item.Composite) {
        var comboEl = element.find('.combobox .combobox-default-combo-value-wrapper');
        comboEl.css('opacity', 0.2);
        angular.element(comboEl[0]).scope().disabled = true;
      }
    };

    $scope.$on('$destroy', function () {
      $(document).off('keydown.escape');
      preventRefresh(true);
    });

    $scope.trackByActivities = function (item) {
      var random = Math.floor(Math.random() * 100000);
      return random + (item.ServiceMetadataId ? ('S_' + item.ServiceMetadataId) : ('P_' + item.ProductMetadataId));
    };

    $scope.trackByInvoicesActivities = function (invoice) {
      return invoice.InvoiceId + "-" + invoice.DocumentType;
    };

    var composedEmployeeField = {
      name: localize.getLocalizedString("_Employee_"),
      width: '25%',
      type: 'Combobox',
      fieldName: 'EmployeeId',
      values: $scope.employees,
      command: {
        get: function (entity) {
          var byEmployee = entity.EmployeeId || entity.getParent().EmployeeId;
          var filteredEmployees = $scope.employees.filter(function (employeeOption) {
            return employeeOption.value == byEmployee;
          });


          return filteredEmployees[0];
        },
        set: function (newValue, entity) {
          entity.EmployeeId = newValue.value;
          changeSaleOfEmployee(entity, newValue.value);
        }
      }
    };

    $scope.composedFields = [
      {
        name: localize.getLocalizedString("_ProductOrServiceName_"),
        width: '30%',
        fieldName: 'Label'
      },
      composedEmployeeField,
      {
        name: localize.getLocalizedString("_Price_"),
        width: '40%',
        type: 'MoneyWithDiscount',
        command: {
          get: function (entity) {
            return entity.RelativePrice;
          },
          beforeDiscount: function (entity) {
            return entity.RelativePrice;
          }
        }
      }
    ];

    function preventRefresh(cancel) {
      $(window).off('beforeunload.cashRegisterActivities');
      if (!cancel) {
        $(window).on('beforeunload.cashRegisterActivities', function (e) {
          return localize.getLocalizedString("_CannotRefreshThisPage_");
        });
      }
    }

    function paymentTypeToIcon(paymentType) {
      var toReturn = "icon ";
      if (paymentType === "Cash") {
        toReturn += configuration.get().CashRegisterSettings.Currency.Value === "NIS" ? 'icon-banknote-aligned-shekel' : 'icon-banknote-aligned-dollar';
      } else if (paymentType === "Credit") {
        toReturn += "icon-credit";
      } else if (paymentType === "Cheque") {
        toReturn += "icon-edit-inventory";
      } else if (paymentType === "SubscriptionUsage") {
        toReturn += "icon-punchcard";
      } else if (paymentType === "AccumulatedDebt") {
        toReturn += "icon-debt";
      } else {
        // console.log("not acceptable payment type");
      }

      return toReturn;
    }

    function changeSaleOfEmployee(entity, employeeId) {
      Repository.Entity(entity.ConcreteType).patch(entity.ConcreteId, {EmployeeId: employeeId})
        .post()
        .then(function () {
          toastr.success(localize.getLocalizedString("_EmployeeSalesUpdatedSuccessfully_"));
        })
        .catch(function (e) {
          toastr.error(localize.getLocalizedString("_FailUpdatingEmployeeSales_"));
        });
    }
  });
