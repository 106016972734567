import {
  cleanObj,
  getSafely,
  isNonEmptyArray,
  isNonEmptyObject, isNonEmptyString,
  isNumber,
  setIfHasValue,
  valueOrDefault
} from "@tomeravni/easybizy-js-common/common";
import {InvoiceItemType, ServerInvoiceItemType} from '@tomeravni/easybizy-js-common/constants';
import {DelayedFunction} from '../../general/helpers';
import {DATE_DISPLAY_FORMAT, ODATA_DATE_ONLY_FORMAT, SESSION_RELATIVE_DATE_FORMAT} from "../../constants";
import {isValidDate} from "@tomeravni/easybizy-js-common/date";

(function () {
  const GLOBAL_SESSION_KEY = 'global-intermediate-cart';


  const REVERSED_MAP_TYPES = Object.freeze(Object.keys(ServerInvoiceItemType).reduce((acc, curr) => ({
    ...acc,
    [ServerInvoiceItemType[curr]]: curr
  }), {}));

  angular.module('easybizy.common.common-services').factory('intermediateManager',
    function ($q, $transitions, Repository, localize, $window, stateManager, contextManager) {
      const _loadingPromises = {};
      const _persistData = new DelayedFunction(persistData, 750);
      $window.onbeforeunload = () => {
        _persistData.trigger();
      }

      $transitions.onSuccess({}, (transition) => {
        const from = transition.$from();
        const to = transition.$to();

        /// Ignore in cash register changes.
        if (from.name !== 'CashRegister' || to.name === 'CashRegister') {
          return;
        }

        Object.keys(_loadingPromises).forEach((key) => {
          if (_loadingPromises[key] && _loadingPromises[key].then) {
            _loadingPromises[key].cancel();
          }

          delete _loadingPromises[key];
        });

        _persistData.trigger();
      });


      function loadData(meetingId, date, customerId) {
        const key = toPromiseKey(meetingId, date, customerId);
        if (_loadingPromises[key] && _loadingPromises[key].cancel) {
          _loadingPromises[key].cancel();
        }

        const deferred = $q.defer();
        loadSession(meetingId, date, customerId).then((result) => {
          setIfHasValue(result.customer, 'expanded', true);
          if (deferred.cancelled) {
            return;
          }

          _loadingPromises[key] = result;
          deferred.resolve(result);
          return result;
        })
          .catch((err) => {
            if (deferred.cancelled) {
              return;
            }

            _loadingPromises[key] = null;

            loadFallback(meetingId, date, customerId).then((res) => {
              deferred.resolve(res);
            }).catch((iErr) => {
              toastr.error(err.message);
              deferred.reject(err);
            })

          })

        deferred.cancel = () => {
          deferred.cancelled = true;
        }

        _loadingPromises[key] = deferred;
        return deferred.promise;
      }

      function loadFallback(meetingId, date, customerId) {
        const customerPromise = Repository.Entity('Customer').query()
          .expand('Subscriptions,MembershipDiscounts,MembershipDiscounts/MembershipDiscountMetadata,PriceLists')
          .filter('CustomerId eq ' + customerId)
          .get()

        const servicesLoading = Repository.Custom('CashRegister').customerServicesFromMeetings(customerId, meetingId || 'null', date);
        return Promise.all([customerPromise, servicesLoading]).then((result) => {
          const items = result[1]?.customerServices.map((item) => ({
            ...item.Service,
            EmployeeId: getSafely(['Employee', 'EmployeeId'], item),
            MeetingId: item.MeetingId,
            MeetingStartTime: item.MeetingStartTime
          }));

          return {customer: result[0]?.value[0], items, externalVoucherIds: result?.vouchers, isFallbackSession: true}
        })
      }

      function toPromiseKey(meetingId, date, customerId) {
        return [meetingId, date, customerId].filter((x) => !!x).join('_').replace(/[\W]/g, '');
      }

      function persistData(data) {
        const {customerId, meetingId, relativeDate, delegate} = data;

        let promiseKey = toPromiseKey(meetingId, relativeDate, customerId);
        const serviceModificationsMap = getSafely([promiseKey, 'serviceModificationsMap'], _loadingPromises);
        if (serviceModificationsMap) {
          decorateServiceModificationsMapPostChanges(serviceModificationsMap, data);
        }

        const queryParams = cleanObj({customerId, meetingId, relativeDate});
        const payload = cleanObj({
          ...data,
          customerId: undefined,
          meetingId: undefined,
          relativeDate: undefined,
          delegate: undefined,
          sessionId: undefined
        });

        /**
         * In case the session have yet to be set,
         * which happens when all the session items are remove, (which leads to session deletion) and then a new session is created.
         * This is important for decorating the sessionId on the current session.
         */
        const relatedSession = _loadingPromises[promiseKey];

        if (isNonEmptyObject(queryParams)) {
          return Repository.Custom('Sessions').setSession(payload, meetingId, relativeDate, customerId)
            .then(({sessionId, _id, paymentLink}) => {
              const id = valueOrDefault(sessionId, _id);
              setIfHasValue(relatedSession, 'sessionId', id);
              delegate(id, getSafely(['shortUrl'], paymentLink));
            })
            .catch((err) => {
              toastr.warning(err.message);
            });
        }
      }

      function decorateServiceModificationsMapPostChanges(serviceModificationsMap, payload) {
        serviceModificationsMap.after = [];
        payload.items.filter((item) => [InvoiceItemType.SERVICE, InvoiceItemType.PUNCHCARD, InvoiceItemType.PUNCHCARD_USAGE].includes(item.itemType))
          .forEach((item) => {
            serviceModificationsMap.after.push((`${REVERSED_MAP_TYPES[item.itemType]}_${item.metadataId}_${item.employeeId}`))
          });

        payload.serviceModificationsMap = serviceModificationsMap;
      }

      function persistGlobalSession(session) {
        $.sessionStorage.set(GLOBAL_SESSION_KEY, session);
      }

      function clearGlobalSession() {
        $.sessionStorage.remove(GLOBAL_SESSION_KEY);
      }

      function getGlobalSession() {
        if ($.sessionStorage.isSet(GLOBAL_SESSION_KEY)) {
          return $.sessionStorage.get(GLOBAL_SESSION_KEY);
        }

        return {};
      }

      function loadSession(meetingId, date, customerId) {
        return Repository.Custom('Sessions').getSession(meetingId, date, customerId)
      }

      function clearSession(sessionId) {
        _persistData.cancel();
        if (sessionId) {
          return Repository.Custom('Sessions').clearSession(sessionId);
        }

      }

      function closeSession(sessionId) {
        return Repository.Custom('Sessions').closeSession(sessionId);
      }

      function adaptInvoiceDetailsToSession(items, totals, customerName) {
        const duplicatedItems = JSON.parse(JSON.stringify(valueOrDefault(items, [])));
        let adaptedItems = duplicatedItems.map((item) => adaptCartItem(item));
        return cleanObj({
          ...adaptCartTotals({
            ...totals,
            calculatedSubTotal: adaptedItems.reduce((acc, curr) => (parseFloat(curr.actualPrice) * curr.quantity) + acc, 0)
          }),
          items: adaptedItems,
          customerName
        });

      }

      function updateSession({items, totals, customerName}, sessionId, delegate, iForce = false, defaultSessionParams) {
        const force = iForce || !sessionId;
        let validRawParams = stateManager.getValidRawParams();

        const {
          meetingId,
          customerId,
          relativeDate
        } = (validRawParams?.customerId || validRawParams?.meetingId) ? validRawParams : (defaultSessionParams || {});

        const validParams = cleanObj({meetingId, customerId, relativeDate});
        ['meetingId', 'customerId'].forEach((key) => {
          if (validParams[key]) {
            validParams[key] = parseInt(validParams[key]);
          }
        })

        const sessionToPersist = adaptInvoiceDetailsToSession(items, totals, customerName);
        Object.assign(sessionToPersist, validParams);

        if (!customerId) {
          persistGlobalSession({items, totals});
          return;
        }

        clearGlobalSession();
        if (force) {
          return _persistData.setForce({...sessionToPersist, sessionId, delegate});
        } else {
          _persistData.set({...sessionToPersist, sessionId, delegate});
        }
      }

      function getSession(params) {
        if (params.priceQuoteId) {
          return loadPriceQuote(params.priceQuoteId, params.duplicate === 'true');
        }

        const adaptedParams = JSON.parse(JSON.stringify(cleanObj(params)));
        const noUrlSession = !adaptedParams.customerId && !adaptedParams.session;
        if (noUrlSession) {
          const persistedCustomer = contextManager.getRawPersistedData('customer');
          const customerIdFromContext = getSafely(['CustomerId'], persistedCustomer) || parseInt(persistedCustomer);
          if (isNumber(customerIdFromContext)) {
            adaptedParams.customerId = customerIdFromContext;
            adaptedParams.relativeDate = moment().format(ODATA_DATE_ONLY_FORMAT);

            stateManager.setState('CashRegister', {
              meetingId: undefined,
              relativeDate: adaptedParams.relativeDate,
              sessionId: undefined,
              customerId: adaptedParams.customerId,
              debt: undefined,
              defaultEmployeeId: undefined
            }, false, false);
          } else {
            return new Promise((resolve) => resolve(getGlobalSession()));
          }

          return new Promise((resolve) => resolve({}));
        }

        const methodParams = [adaptedParams.meetingId, adaptedParams.relativeDate, adaptedParams.customerId];

        const key = toPromiseKey(...methodParams);
        const existingSessionRef = _loadingPromises[key];
        if (existingSessionRef && existingSessionRef.then) {
          return existingSessionRef;
        } else {
          return loadData(...methodParams);
        }
      }

      function loadPriceQuote(priceQuoteId, duplicate) {
        return Repository.Custom('Sessions').getPriceQuote(priceQuoteId).then((priceQuote) => {
          priceQuote.customer.expanded = true;
          if (duplicate) {
            contextManager.clear('customer')
            delete priceQuote.customer;
            delete priceQuote.IntermediateCartId;
            delete priceQuote.PriceQuoteId;
          }

          return priceQuote;
        });
      }

      function decorateRelatedMeetingsIfNeed(items, sessionId) {
        const relatedMeetings = (Object.values(_loadingPromises).find((session) => session?.sessionId === sessionId)?.serviceModificationsMap?.relatedMeetings || []).slice();
        if (!isNonEmptyArray(relatedMeetings)) {
          return [];
        }

        const servicesWithoutMeetings = [];
        items.forEach((cashRegisterItem) => {
          if (cashRegisterItem.MeetingId) {
            let indexOfMeeting = relatedMeetings.findIndex((meeting) => meeting.MeetingId === cashRegisterItem.MeetingId);
            if (indexOfMeeting > -1) {
              relatedMeetings.splice(indexOfMeeting, 1);
            }
          } else if (cashRegisterItem.ConcreteType === REVERSED_MAP_TYPES[InvoiceItemType.SERVICE] ||
            [REVERSED_MAP_TYPES[InvoiceItemType.PUNCHCARD], REVERSED_MAP_TYPES[InvoiceItemType.PUNCHCARD_USAGE]].includes(cashRegisterItem.itemCustomType)) {
            servicesWithoutMeetings.push(cashRegisterItem);
          }
        });

        let serviceIdx = 0;
        while (servicesWithoutMeetings.length >= relatedMeetings.length && isNonEmptyArray(relatedMeetings)) {
          Object.assign(servicesWithoutMeetings[serviceIdx], relatedMeetings.pop());
          serviceIdx++;
        }

        return relatedMeetings;
      }


      function adaptCartItem(item, preventRecursion) {
        let subscriptionItem;
        let composedItems;
        if (item.Item && !preventRecursion) {
          subscriptionItem = adaptCartItem(item.Item, true);
        }

        if (isNonEmptyArray(item.ComposedItems)) {
          composedItems = item.ComposedItems.map((composedItem) => adaptCartItem({
            Quantity: 1,
            OriginalRetailPrice: composedItem.RetailPrice,
            ConcreteType: REVERSED_MAP_TYPES.service,
            ...composedItem,
          }));
        }

        const membershipDiscount = item.membershipDiscount?.Level > 0 ? item.membershipDiscount : undefined;

        let ExpirationDate;
        if (isValidDate(item.ExpirationDate)) {
          ExpirationDate = item.ExpirationDate;
        } else if (isNonEmptyString(item.ExpirationDate)) {
          const validFormats = [ODATA_DATE_ONLY_FORMAT, DATE_DISPLAY_FORMAT];
          while (isNonEmptyArray(validFormats) && !ExpirationDate?.isValid()) {
            ExpirationDate = moment(item.ExpirationDate, validFormats.pop());
          }

          if (!ExpirationDate?.isValid()) {
            ExpirationDate = undefined;
          } else {
            ExpirationDate = ExpirationDate.format(ODATA_DATE_ONLY_FORMAT)
          }
        }


        const customProps = cleanObj({
          membershipDiscount: membershipDiscount,
          PriceListItemOriginalPrice: item.PriceListItemOriginalPrice ? parseFloat(item.PriceListItemOriginalPrice) : undefined,
          buy: item.buy,
          get: item.get,
          TaxInvoiceId: item.TaxInvoiceId,
          preventRegisteringSubscription: item.preventRegisteringSubscription,
          Template: item.Template,
          Giver: item.Giver,
          Greating: item.Greating,
          ReferenceNumber: item.ReferenceNumber,
          SmsSendTime: item.SmsSendTime,
          AllowOnlineScheduling: item.AllowOnlineScheduling,
          PhoneNumber: item.PhoneNumber,
          Remarks: item.Remarks,
          ShowPrice: item.ShowPrice,
          SmsMessageContent: item.SmsMessageContent,
          relatedItemConcreteType: item.itemCustomType === 'PunchCardUsage' ? ServerInvoiceItemType[item.ConcreteType] : undefined,
          ignorePunchCard: item.ignorePunchCard,
          /// Punch card related.
          relativeId: item.Item && item.Id,
          SubscriptionId: item.SubscriptionId,
          GiftCardId: item.GiftCardId,
          PriceListId: item.PriceListId,
          ExternalVoucherMetadataId: item.ExternalVoucherMetadataId,
          preventMembershipDiscount: item.preventMembershipDiscount,
          ExpirationDate,
          calculatedRelativePart: item.calculatedRelativePart,
          subscriptionItem: subscriptionItem ? {
            itemType: subscriptionItem.itemType,
            metadataId: subscriptionItem.metadataId
          } : null,
          ...getSafely(['customProps'], subscriptionItem, {})
        });

        const relatedMeeting = item.MeetingId ? {
          meetingId: item.MeetingId,
          startTime: item.MeetingStartTime,
        } : null;

        const toReturn = cleanObj({
          metadataId: getSafely(['Item', 'Id'], item, item.Id),
          title: item.Label.trim(),
          retailPrice: item.OriginalRetailPrice,
          actualPrice: parseFloat(valueOrDefault(item.calPrice, item.RetailPrice)),
          quantity: parseFloat(item.Quantity),
          employeeId: item.EmployeeId,
          itemType: ServerInvoiceItemType[item.itemCustomType || item.ConcreteType],
          composedItems,
          customProps
        });

        setIfHasValue(toReturn, 'relatedMeeting', relatedMeeting);

        return toReturn;
      }


      function adaptCartTotals(totals) {
        return {
          totalAmountBeforeDiscount: totals.calculatedSubTotal,
          totalAmount: totals.calculatedAfterDiscount
        }
      }

      return {
        clearGlobalSession,
        getSession,
        clearSession,
        updateSession,
        closeSession,
        decorateRelatedMeetingsIfNeed,
        adaptInvoiceDetailsToSession
      }

    })
}());
