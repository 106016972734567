import './add.lead.less';
import {digitsOnly, isNonEmptyString} from "@tomeravni/easybizy-js-common/common";

(function () {
  angular.module('easybizy.leads').controller('AddLeadModalController',
    function ($scope, $modalInstance, saveAction, $timeout, $http, localize, printerMediator, inlineForms, Repository, $q, colorsService, parameters) {
      $scope.lead = { Tags: [] };
      $scope.lead.FirstName = "";
      $scope.lead.LastName = "";
      $scope.lead.EnablePromotionSending = true;
      $scope.lead.EnableSmsSending = true;
      $scope.lead.EnableMailSending = true;

      if (isNonEmptyString(parameters.fullName)) {
        const split = parameters.fullName.split(' ');
        $scope.lead.FirstName = split[0];
        $scope.lead.LastName = split.slice(1).join(' ');
      }

      if (isNonEmptyString(parameters.mobileFirst)) {
        $scope.lead.MobileFirst = digitsOnly(parameters.mobileFirst);
      }



      $scope.lastSearchedName = "";
      $scope.tagsPlaceholder = localize.getLocalizedString('_AddCustomerTag_');

      $scope.dateTime = new Date();
      $scope.isSubmittingLead = false;
      //$scope.lead.Gender = 'Female';
      // New customer validation fields
      $scope.newCustomerFieldsToValidate = {}; // just initialize fields to validate.
      $scope.validateActionWrapper = {}; // this is an object to wrap an action, so the form validator can
      // add a function pointer to be called before saving customer.

      $scope.fields = [
        {
          fieldName: 'FirstName', icon: "icon icon-clients-ol", placeholder: localize.getLocalizedString("_FirstName_"),
          validation: "{'required': true, 'max-length': 20}"
        },
        {
          fieldName: 'LastName', icon: "icon icon-client", placeholder: localize.getLocalizedString("_LastName_"),
          validation: "{'required': true, 'max-length': 20}"
        },
        {
          fieldName: 'MobileFirst', icon: "icon icon-sms", placeholder: localize.getLocalizedString("_Phone_"),
          validation: "{'max-length': 10, 'min-length': 9, 'digits': true}"
        },
        {
          fieldName: 'EmailAddress',
          icon: "icon icon-mail-outline",
          placeholder: localize.getLocalizedString("_Email_"),
          validation: "{'email': true}"
        },
        {
          fieldName: 'Address', icon: "icon icon-home-ol", placeholder: localize.getLocalizedString("_Address_"),
          validation: "{'max-length': 50}"
        },
        {
          fieldName: 'Gender', icon: "glyphicon glyphicon-glass", placeholder: localize.getLocalizedString("_Gender_"),
          validation: "{'required': true}", type: 'radio', values: [
            { name: localize.getLocalizedString("_Female_"), value: 'Female' },
            { name: localize.getLocalizedString("_Male_"), value: 'Male' }
          ]
        }
      ];

      $scope.printNewCustomerForm = function () {
        inlineForms.newCustomerForm().then(function (template) {
          printerMediator.print(template);
        });
      };

      // saveAction is resolved on the toolbar so the modal can have a result when the dialog changed.
      $scope.save = function () {
        var validationFields = $scope.validateActionWrapper.validate();
        if (validationFields.length !== 0) { // is valid form?
          var wrongFieldName =  $scope.fields.filter(x => validationFields[0].hasOwnProperty(x.fieldName))[0];
          var localizedError = localize.getLocalizedString("_FieldIsIncorrect_", wrongFieldName.placeholder, validationFields[0][wrongFieldName.fieldName]);
          toastr.error(localizedError);
          return;
        }

        var leadToPost = Object.assign({}, $scope.lead);
        leadToPost.Gender = leadToPost.Gender.value;
        leadToPost.LeadStatus = 'New';
        leadToPost.Rank = "1.5";
        leadToPost.ArrivalSourceId = leadToPost.ArrivalSource ? leadToPost.ArrivalSource.ArrivalSourceId : null;
        if (leadToPost.ArrivalSource && leadToPost.ArrivalSourceId < 1) {
          leadToPost.ArrivalSource.State = "Added";
        }


        $scope.isSubmittingLead = true;
        var deferred = $q.defer();
        deferred.promise.catch(function (err) {
          toastr.error(localize.getLocalizedString("_ErrorSavingLead_") + " - " + err);
        });

        deferred.promise.then(function () {
          toastr.success(localize.getLocalizedString("_LeadCreatedSuccessfully_"));
        });

        deferred.promise.finally(function () {
          $scope.isSubmittingLead = false;

          $modalInstance.close(leadToPost);
        });

        saveAction(leadToPost, deferred);
      };

      $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
      };

      $scope.$on('$locationChangeStart', function (scope, next, current) {
        $modalInstance.dismiss('cancel');
      });

      $scope.today = function () {
        $scope.dt = new Date();
      };
      $scope.today();

      $scope.showWeeks = true;
      $scope.toggleWeeks = function () {
        $scope.showWeeks = !$scope.showWeeks;
      };

      $scope.clear = function () {
        $scope.dt = null;
      };

      // Disable weekend selection
      $scope.disabled = function (date, mode) {
        return (mode === 'day' && (date.getDay() === 0 || date.getDay() === 6));
      };

      $scope.toggleMin = function () {
        $scope.minDate = ($scope.minDate) ? null : new Date();
      };


      $scope.open = function () {
        $timeout(function () {
          $scope.opened = true;
          $scope.toggleMin();
        });
      };

      $scope.dateOptions = {
        'year-format': "'yy'",
        'starting-day': 1
      };

      /********************************** Lead TAGS **********************************/
      $scope.tagsPlaceholder = localize.getLocalizedString('_AddCustomerTag_');
      var tagsAutoCompleteOptions = [];
      Repository.Entity('Tag').query().filter('EntityType eq \'Lead\'').get()
        .then(function (data) {
          if (angular.isDefined(data)) {
            data.value.forEach(function (tag) {
              tag.EntityType = "Lead";
              tagsAutoCompleteOptions.push(tag);
            });
          } else {
            queryFailed();
          }
        })
        .catch(queryFailed);

      $scope.onTagAdded = function (tag) {
        var newTagsAdded = $scope.lead.Tags.filter(function (tagItem) {
          return tagItem.TagId < 0;
        }).length;

        if (!tag.TagId) {
          tag.TagId = -1;
          tag.EntityType = "Lead";
          tag.Color = colorsService.getColorByIndex(tagsAutoCompleteOptions.length + newTagsAdded);
        }
      };

      $scope.loadItems = function (query) {
        var regex = new RegExp('.*' + query + '.*', 'i');
        return tagsAutoCompleteOptions.filter(function (item) {
          return item.Name.match(regex);
        });
      };

      function queryFailed(/*error*/) {
        toastr.error(localize.getLocalizedString('_ErrorLoadingTags_'));
      }

      /********************************** ENDOF _ LEAD TAGS!!! **********************************/
    });
}());
