import {extractError} from "@tomeravni/easybizy-js-common/errors";
import {
  cleanObj, duplicate, getSafely,
  isConcreteValue, isNonEmptyArray,
  isNonEmptyObject,
  isNonEmptyString,
  valueOrDefault
} from "@tomeravni/easybizy-js-common/common";
import {isNonZeroNumber} from "@tomeravni/easybizy-js-common/numbers";
import {capitalizeEveryFirstLetter} from "@tomeravni/easybizy-js-common/string-manipulation";

const GENERAL_FAQ_KEY = 'general';

(function () {
  angular.module('easybizy.configurations').directive('whatsappLeadsProps',
    function (Repository, localize, configuration, confirmService) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          leadsProps: '=',
          defaults: '='
        },
        link: function (scope) {
          scope.validateGeneralActionWrapper = {}; // this is an object to wrap an action, so the form validator can
          scope.validateGeneralAggregator = {}; // this is an object to wrap an action, so the form validator can

          const defaultWaFormFieldOptions = [
            {name: localize.getLocalizedString('_Optional_'), value: 'optional'},
            {name: localize.getLocalizedString('_Mandatory_'), value: 'mandatory'},
            {name: localize.getLocalizedString('_Hidden_'), value: 'hidden'}
          ]

          const enableRemarksField = {
            fieldName: 'remarks',
            type: 'combo',
            icon: "icon icon-notes",
            placeholder: localize.getLocalizedString('_Remarks_'),
            options: duplicate(defaultWaFormFieldOptions)
          };

          const remarksLabel = {
            fieldName: 'remarksLabel',
            icon: "icon icon-notes",
            placeholder: localize.getLocalizedString('_RemarksFillingExplanation_'),
            validation: "{'required': true, 'max-length': 50}"
          }

          const descriptionToggleField = {
            fieldName: 'isDescriptionVisible',
            type: 'checkbox',
            icon: "icon icon-details",
            placeholder: localize.getLocalizedString('_PresentDescription_'),
            options: duplicate(defaultWaFormFieldOptions)
          };

          const descriptionContentField = {
            fieldName: 'descriptionContent',
            type: 'textarea',
            icon: "icon icon-details",
            placeholder: localize.getLocalizedString('_Description_'),
            validation: "{'required': true, 'max-length': 200}",
            description: localize.getLocalizedString('_DescriptionRichText_')
          };

          scope.fields = [
            {
              fieldName: 'title',
              icon: "icon icon-edit",
              placeholder: localize.getLocalizedString('_Header_'),
              validation: "{'required': true, 'max-length': 30}"
            },
            {
              fieldName: 'leadSuccessfulCreationResponseMessage',
              type: 'textarea',
              icon: "icon icon-lead",
              placeholder: localize.getLocalizedString('_LeadsCreatedMessage_'),
              validation: "{'required': true, 'max-length': 200}"
            },
            descriptionToggleField,
            descriptionContentField,
            {
              fieldName: 'email',
              type: 'combo',
              icon: "icon icon-email-mail-streamline",
              placeholder: localize.getLocalizedString('_Email_'),
              options: duplicate(defaultWaFormFieldOptions)
            },
            {
              fieldName: 'dateOfBirth',
              type: 'combo',
              icon: "icon icon-calendar",
              placeholder: localize.getLocalizedString('_DateOfBirth_'),
              options: duplicate(defaultWaFormFieldOptions)
            },
            {
              fieldName: 'optInPromotions',
              type: 'combo',
              icon: "icon icon-promote",
              placeholder: localize.getLocalizedString('_OptInForMarketing_'),
              options: duplicate(defaultWaFormFieldOptions),
              description: localize.getLocalizedString('_OptInForMarketingMandatoryExplain_')
            },
            enableRemarksField,
            remarksLabel
          ]

          scope.$watch('genericLeadValues.remarks', (newVal) => {
            remarksLabel.hidden = newVal?.value === 'hidden';
          });

          scope.$watch('genericLeadValues.isDescriptionVisible', (newVal) => {
            descriptionContentField.hidden = !newVal;
          });

          scope.genericLeadValues = {};

          const adaptIncomingFields = (iServerModel, defaults) => {
            const serverModel = valueOrDefault(iServerModel, {});
            ['email', 'remarks', 'dateOfBirth', 'optInPromotions'].forEach((fieldName) => {
              scope.genericLeadValues[fieldName] = adaptComboValue(fieldName, serverModel, defaults);
            });

            scope.genericLeadValues.title = valueOrDefault(serverModel?.title, defaults?.title, '');
            scope.genericLeadValues.isDescriptionVisible = valueOrDefault(serverModel?.isDescriptionVisible, defaults?.isDescriptionVisible, '');
            scope.genericLeadValues.descriptionContent = valueOrDefault(serverModel?.descriptionContent, defaults?.descriptionContent, '');
            scope.genericLeadValues.remarksLabel = valueOrDefault(serverModel?.remarksLabel, defaults?.remarksLabel, '');
            scope.genericLeadValues.leadSuccessfulCreationResponseMessage = valueOrDefault(serverModel?.leadSuccessfulCreationResponseMessage,
              defaults?.leadSuccessfulCreationResponseMessage, '');
          }

          const adaptComboValue = (baseFieldName, serverValues, defaults) => {
            if (!isNonEmptyObject(serverValues)) {
              return scope.fields.find((field) => field.fieldName === baseFieldName).options[0];
            }

            let adaptedValue = 'optional';
            const visibilityFieldName = `is${capitalizeEveryFirstLetter(baseFieldName, true)}Visible`;
            const mandatoryFieldName = `is${capitalizeEveryFirstLetter(baseFieldName, true)}Required`;
            const isVisibleValue = isConcreteValue(serverValues[visibilityFieldName]) ? serverValues[visibilityFieldName] : defaults[visibilityFieldName];
            const isMandatoryValue = isConcreteValue(serverValues[mandatoryFieldName]) ? serverValues[mandatoryFieldName] : defaults[mandatoryFieldName];

            if (isVisibleValue === false) {
              adaptedValue = 'hidden';
            } else if (isMandatoryValue) {
              adaptedValue = 'mandatory';
            }

            return scope.fields.find((field) => field.fieldName === baseFieldName).options.find((option) => option.value === adaptedValue);
          }

          const adaptOutgoingFields = () => {
            const adaptedValues = {};

            ['email', 'remarks', 'dateOfBirth', 'optInPromotions'].forEach((fieldName) => {
              const field = scope.genericLeadValues[fieldName];
              const visibilityFieldName = `is${capitalizeEveryFirstLetter(fieldName, true)}Visible`;
              const requiredFieldName = `is${capitalizeEveryFirstLetter(fieldName, true)}Required`;
              adaptedValues[visibilityFieldName] = field.value !== 'hidden';
              adaptedValues[requiredFieldName] = adaptedValues[visibilityFieldName] && field.value === 'mandatory';
            });

            adaptedValues.title = scope.genericLeadValues.title;
            adaptedValues.descriptionContent = scope.genericLeadValues.descriptionContent;
            adaptedValues.isDescriptionVisible = scope.genericLeadValues.isDescriptionVisible;
            adaptedValues.remarksLabel = scope.genericLeadValues.remarksLabel;
            adaptedValues.leadSuccessfulCreationResponseMessage = scope.genericLeadValues.leadSuccessfulCreationResponseMessage;

            return adaptedValues;
          }

          adaptIncomingFields(scope.leadsProps, scope.defaults);
          /// Keep pre-modification values for comparison
          const preModificationValues = adaptOutgoingFields();


          scope.patchGenericLeadFields = () => {
            const currentValues = adaptOutgoingFields();
            const modified = Object.keys(currentValues).reduce((acc, key) => {
              if (currentValues[key] !== scope.defaults[key]) {
                return {...acc, [key]: currentValues[key]};
              } else {
                return acc;
              }
            }, {});

            if (!isNonEmptyObject(modified)) {
              return toastr.info(localize.getLocalizedString('_NoChangesDetected_'));
            }

            Repository.Custom('Configurations').patchBotConfigurations({genericLeadCustomModifiers: cleanObj(modified)}).then((res) => {
              scope.isSaving = false;
              toastr.success(localize.getLocalizedString('_EntitySavedSuccessfully_'))
            })
              .catch((err) => {
                scope.isSaving = false;
                toastr.error(extractError(err.data))
              })
          }


          scope.validateGeneralActionWrapper = {}; // this is an object to wrap an action, so the form validator can
          scope.validateGeneralAggregator = {}; // this is an object to wrap an action, so the form validator can


        },
        template: require('./whatsapp.leads.props.directive.tpl.html')
      };
    });
}());
